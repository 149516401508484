import React, { useMemo } from 'react';
import DetailsView from '../DetailsView';
import { ProductTraceI } from '../../interfaces/productTrace';
import { DateTimeFormat } from '../../utils/global';
import { Divider } from 'antd';
import Text from '../Text';

type Props = {
  data?: ProductTraceI;
};

const ProductTraceInformation: React.FC<Props> = ({ data }) => {
  const emptyEcoToteNo = useMemo(() => {
    return data?.partners?.reduce((acc, partner) => acc + (partner?.emptyEcoTotes ?? 0), 0);
  }, [data]);

  return (
    <>
      <DetailsView title="Product Trace Details">
        <div className="flex-row">
          <DetailsView.Field label="Date Created" value={DateTimeFormat(data?.createdAt)} />
        </div>
        <div className="flex-row">
          <DetailsView.Field label="Product Name" value={data?.product.name} />
          <DetailsView.Field label="SKU" value={data?.product.sku} />
        </div>
        <DetailsView.Field
          label="Lot Number"
          value={data?.lotNumbers?.map((lotNumber) => (
            <Text variant="bodyLgB" component="p" className="!tw-block" key={lotNumber}>
              {lotNumber}
            </Text>
          ))}
        />
        <Divider className="tw-m-0" />
        <DetailsView.Field label="Total Number of Affected ecoTOTEs" value={data?.ecoToteIds.length} />
        <div className="flex-row">
          <DetailsView.Field label="Number of Full ecoTOTEs" value={data?.totalFullEcoTotes} />
          <DetailsView.Field label="Number of Partial ecoTOTEs" value={data?.totalPartialEcoTotes} />
        </div>
        <div className="flex-row">
          <DetailsView.Field label="Number of Empty ecoTOTEs" value={emptyEcoToteNo ?? 0} />
        </div>
        <Divider className="tw-m-0" />
        <DetailsView.Field
          label="Total Volume Introduced (L)"
          value={data?.totalVolumeIntroduced ? (data?.totalVolumeIntroduced / 1000).toFixed(2) : 0}
        />
        <div className="flex-row">
          <DetailsView.Field
            label="Volume in Circulation (L)"
            value={data?.volumeCirculation ? (data?.volumeCirculation / 1000).toFixed(2) : 0}
          />
          <DetailsView.Field
            label="Volume Depleted (L)"
            value={data?.volumeDepleted ? (data?.volumeDepleted / 1000).toFixed(2) : 0}
          />
        </div>
        <Divider className="tw-m-0" />
        <DetailsView.Field label="Number of Affected Partners " value={data?.numberOfAffectedPartners} />
        {data?.status !== 'trace' && (
          <div className="flex-row">
            <DetailsView.Field label="Number of Partners to notify" value={data?.numberOfPartnersToNotify} />
            <DetailsView.Field label="Number of Recipients to notify" value={data?.numberOfRecipientsToNotify} />
          </div>
        )}
        <Divider className="tw-m-0" />
        <DetailsView.Field label="Remarks" value={data?.remarks} />
      </DetailsView>
    </>
  );
};

export default ProductTraceInformation;
