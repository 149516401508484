import React, { useEffect, useState } from 'react';
import { HardwareProgressStepListI } from '../../interfaces';
import { useNavigate } from 'react-router-dom';
import useNavigateAway from '../../hooks/useNavigateAway';
import EAnalytics from '../../analytics';
import SharedHardwareProgressBar from '../../components/shared/Hardware/ProgressBar';
import ProductTraceLotNumberScanStep from '../../components/ProductTrace/ScanLotNumber';
import { ProductTraceI, ProductTraceReportTypeT, ProductTraceVerifyReqT } from '../../interfaces/productTrace';
import ProductTraceChooseReportType from '../../components/ProductTrace/ChooseReportType';
import ProductTraceAffectedPartners from '../../components/ProductTrace/AffectedPartners';
import { doVerifyProductTrace } from '../../apis/productTraces';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import { message } from 'antd';
import ProductTraceReview from '../../components/ProductTrace/Review';
import { ProductI } from '../../interfaces/product';
import ProductTraceScanProductStep from '../../components/ProductTrace/ScanProduct';

const progressStepList: HardwareProgressStepListI[] = [
  {
    step: 1,
    label: 'Scan Product',
  },
  {
    step: 2,
    label: 'Scan lot Number',
  },
  {
    step: 3,
    label: 'Select Report Type',
  },
  {
    step: 4,
    label: 'Affected Partners',
  },
  {
    step: 5,
    label: 'Review',
  },
];

const ProductTraceNew: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);
  const [productScanned, setProductScanned] = useState<ProductI | undefined>();
  const [reportType, setReportType] = useState<ProductTraceReportTypeT>('trace');
  const [verifiedData, setVerifiedData] = useState<ProductTraceI | undefined>();
  const [selectedPartners, setSelectedPartners] = useState<{
    partners: number[];
    ecoToteNo: number;
    recipients: number;
  }>({
    partners: [],
    ecoToteNo: 0,
    recipients: 0,
  });
  const [selectedLotNumbers, setSelectedLotNumbers] = useState<string[]>([]);

  useNavigateAway({
    when: Object.keys(productScanned ?? {}).length > 0,
    handleProceed() {
      setSelectedPartners({ partners: [], ecoToteNo: 0, recipients: 0 });
      setCurrentStep(1);
      setVerifiedData(undefined);
      setProductScanned(undefined);
    },
  });

  const handleVerify = async () => {
    try {
      const params: ProductTraceVerifyReqT = {
        lotNumbers: selectedLotNumbers,
        productSku: productScanned?.sku ?? '',
        status: reportType,
      };
      const response = await doVerifyProductTrace(params);
      setVerifiedData(response);
      setCurrentStep(4);
    } catch (error: any) {
      message.error(error ?? 'Error verifying product trace');
    }
  };

  const backFunction = () => {
    if (currentStep !== 1) setCurrentStep(currentStep - 1);
    if (currentStep === 1) navigate(-1);
  };

  const resetState = () => {
    setReportType('trace');
    setVerifiedData(undefined);
    setSelectedPartners({ partners: [], ecoToteNo: 0, recipients: 0 });
    setProductScanned(undefined);
  };

  useEffect(() => {
    const step = progressStepList.find((item) => item.step === currentStep) || { label: '' };
    EAnalytics.trackScanWorkflow(step?.label);
  }, [currentStep]);

  return (
    <div id="pages-hardware-depletion-new-container" className="tw-p-4">
      <SharedHardwareProgressBar
        currentStep={currentStep}
        title="Product Trace"
        stepList={progressStepList}
        backFunction={backFunction}
      />
      {currentStep === 4 && (
        <div className="tw-bg-white tw-w-full tw-mt-[-2px] tw-pb-4">
          <div className="tw-w-[672px] tw-my-0 tw-mx-auto tw-flex tw-justify-between">
            <Text variant="labelMd" className="tw-flex tw-items-center tw-gap-1">
              <Icon name="icon-supervised_user_circle" />
              {verifiedData?.numberOfAffectedPartners} Partners Affected
            </Text>
            <Text variant="labelMd" className="tw-flex tw-items-center tw-gap-1">
              <Icon name="icon-eco-tote" />
              {verifiedData?.ecoToteIds?.length} ecoTOTE(s) Affected
            </Text>
          </div>
        </div>
      )}
      {currentStep === 1 && (
        <ProductTraceScanProductStep
          setCurrentStep={setCurrentStep}
          setProductScanned={setProductScanned}
          productScanned={productScanned}
          setStopDecodingScanner={setStopDecodingScanner}
          stopDecodingScanner={stopDecodingScanner}
          pageName="Scan Product Code"
        />
      )}
      {currentStep === 2 && (
        <ProductTraceLotNumberScanStep
          setCurrentStep={setCurrentStep}
          productScanned={productScanned}
          selectedLotNumbers={selectedLotNumbers}
          setSelectedLotNumbers={setSelectedLotNumbers}
          setProductScanned={setProductScanned}
        />
      )}
      {currentStep === 3 && (
        <ProductTraceChooseReportType
          reportType={reportType}
          setCurrentStep={setCurrentStep}
          setReportType={setReportType}
          handleVerify={handleVerify}
        />
      )}
      {currentStep === 4 && (
        <ProductTraceAffectedPartners
          verifiedData={verifiedData}
          reportType={reportType}
          setCurrentStep={setCurrentStep}
          setSelectedPartners={setSelectedPartners}
          selectedPartners={selectedPartners}
        />
      )}
      {currentStep === 5 && (
        <ProductTraceReview
          setCurrentStep={setCurrentStep}
          verifiedData={verifiedData}
          reportType={reportType}
          selectedPartners={selectedPartners}
          resetState={resetState}
          selectedLotNumbers={selectedLotNumbers}
          productScanned={productScanned}
        />
      )}
    </div>
  );
};

export default ProductTraceNew;
