import React from 'react';
import Text from '../../components/Text';
import { Link } from 'react-router-dom';

const ReportsPage: React.FC = () => {
  const renderCard = (title: string, url: string, description: string) => {
    return (
      <Link to={url} className="tw-w-1/5 tw-text-black tw-no-underline">
        <div className="tw-p-4 tw-flex tw-flex-col tw-bg-white tw-rounded-[10px] tw-shadow-simple">
          <Text variant="inputLabelSm" className="tw-text-grey-700 tw-mb-1">
            Report
          </Text>
          <Text variant="bodyLgB" className="tw-text-primary-800 tw-mb-3">
            {title}
          </Text>
          <Text variant="bodyMd" className="tw-text-grey-700">
            {description}
          </Text>
        </div>
      </Link>
    );
  };
  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <Text variant="h2" component="h2" className="tw-mb-4">
        Reports
      </Text>
      <Text variant="h3" component="h3" className="tw-mb-6">
        Fleet Protection Reports
      </Text>
      <div className="tw-flex tw-gap-6">
        {renderCard(
          'Assets',
          '/reports/inventories',
          'Report detailing the current assets of hardware held under your partnership.',
        )}
        {renderCard(
          'Deliveries',
          '/reports/deliveries',
          'Report detailing the flow of hardware within sites under your partnership.',
        )}
        {renderCard(
          'Fills',
          '/reports/fills',
          'Report detailing the list of filling events carried out under the partnership.',
        )}
        {renderCard(
          'Depletions',
          '/reports/depletions',
          'Report detailing the list of depletion events carried out under the partnership',
        )}
        {renderCard(
          'Dispenses',
          '/reports/dispenses',
          'Report detailing the list of dispense events carried out under the partnership',
        )}
      </div>
    </div>
  );
};

export default ReportsPage;
