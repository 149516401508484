import React, { useState } from 'react';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import Text from '../Text';
import SuccessSubmittedItem from '../shared/SuccessSubmittedItem';
import { HardwareTypeI } from '../../interfaces/hardware';
import HardwareChooseType from '../HardwareFilling/HardwareChooseType';

type Props = {
  data?: any;
  allocationType: string;
  setChosenType?: (type: HardwareTypeI) => void;
  setCurrentStep: (step: number) => void;
};

const HardwareAllocationSuccessModal: React.FC<Props> = ({ data, allocationType, setCurrentStep, setChosenType }) => {
  const [isSelectType, setIsSelectType] = useState<boolean>(false);

  const onChooseTypeChange = (event: any) => {
    const { value } = event.target;
    setChosenType?.(value);
  };

  const handleChooseTypeConfirm = () => {
    setIsSelectType(false);
    setCurrentStep(5);
  };

  const handleChooseTypeBack = () => {
    setIsSelectType(false);
  };

  return !isSelectType ? (
    <div className="tw-text-center tw-py-4 tw-px-4">
      <CheckCircleIcon width={48} height={48} color="#0A7A08" />
      <Text variant="h3" className="tw-mt-2 tw-mb-3">
        You have successfully submitted!
      </Text>
      <div className="notice tw-mb-8 tw-text-grey-700">
        <Text variant="bodyMd" className="!tw-block">
          Few actions are available for this entry.
        </Text>
        <Text variant="bodyMd" className="!tw-block">
          You can choose the options below
        </Text>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-3">
        <SuccessSubmittedItem
          url={`/hardware-allocations/${data?.uuid}`}
          title={`View the allocation ${allocationType === 'send' ? '(Send)' : '(Receive)'} report`}
          description={['View the report of your allocated hardware']}
        />
        <SuccessSubmittedItem
          url={`/tracking`}
          title="Proceed to Hardware Tracking"
          description={['View a list of available hardware(s)']}
        />
        {allocationType === 'receive' && (
          <>
            <SuccessSubmittedItem
              onClick={() => setCurrentStep(6)}
              title="Mark as Depleted"
              description={['Mark the currently scanned ecoTOTEs as depleted']}
            />
            <SuccessSubmittedItem
              onClick={() => setIsSelectType(true)}
              title="Hardware Grouping"
              description={[
                'Create a group of currently scanned Hardware.',
                'Only similar hardware can be grouped together.',
              ]}
            />
          </>
        )}
      </div>
    </div>
  ) : (
    <HardwareChooseType
      type="all"
      onChange={onChooseTypeChange}
      onConfirmClick={handleChooseTypeConfirm}
      onBackClick={handleChooseTypeBack}
    />
  );
};

export default HardwareAllocationSuccessModal;
