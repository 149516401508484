import { BaseResponse } from '../interfaces';
import { UserHistoryCollectionI, UserHistorySearchParams, UserI, UserSearchParamsI } from '../interfaces/user';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetUser = (id: number) => {
  const { data: response, error, isFetching } = useFetch<any>(`users/${id}`);
  const data = response?.data;
  return { data, error, isFetching };
};

export const getUsersList = async (params?: UserSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response }: any = await api.get(`users?${newParams}`);
  return response;
};

export const getUserDetails = async (id?: number) => {
  const { data: response } = await api.get<BaseResponse<any>>(`users/${id}`);
  return response?.data;
};

export const getUserAvatar = async (id: number) => {
  const { data }: any = await api.getImage<any>(`users/${id}/logo`);
  return { data };
};

export const createUser = async (values: UserI) => {
  const { data: response }: any = await api.post('users', values);
  return response.data;
};

export const updateUser = async ({ id, ...values }: { id: string; values: UserI }) => {
  const { data: response }: any = await api.patch(`users/${id}`, values);
  return response.data;
};

export const deleteUser = async (id: number) => {
  const { data: response }: any = await api.delete(`users/${id}`);
  return response.data;
};

export const updateUserStatus = async (params: { id: number; status: string }) => {
  const { data: response }: any = await api.patch(`users/${params.id}/status`, { status: params.status });
  return response.data;
};

export const getUserHistory = async (params: { id: number; params: UserHistorySearchParams }) => {
  const { data: response } = await api.get<BaseResponse<UserHistoryCollectionI>>(
    `users/${params?.id}/histories?${handleFilterParams(params?.params)}`,
  );
  return response;
};
