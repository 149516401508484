import React from 'react';
import { Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import './HardwareCardItem.scss';
import { DateFormat } from '../../utils/global';
import { AllocationTypeComponent } from '../AllocationType';
import { AllocationHistoryI } from '../../interfaces/allocationHistory';
import Text from '../Text';
import { IconName } from '../Icon/iconName';
import Icon from '../Icon';
import TooltipIcon from '../buttons/TooltipIcon';

type Props = {
  item: AllocationHistoryI;
};
const HardwareAllocationCardItem: React.FC<Props> = ({ item }) => {
  const navitate = useNavigate();

  const handleCardClick = (item: AllocationHistoryI) => {
    navitate(`/hardware-allocations/${item.uuid}`);
  };

  const renderField = (props: { icon: IconName; value: string }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
        <Text variant="inputLabelSm" className="tw-flex-1">
          {props.value}
        </Text>
      </div>
    );
  };
  console.log('item', item);
  return (
    <button className="hardware-card-item card-item" onClick={() => handleCardClick(item)}>
      {item?.remarks && (
        <TooltipIcon
          icon="icon-text_snippet"
          text="Remarks"
          className="btn-more-action"
          iconClassName="tw-text-grey-800"
        />
      )}
      <Text variant="inputLabelSm" className="tw-text-grey-700 tw-mb-1.5">
        Allocation Report
      </Text>
      <div className="tw-flex tw-flex-col tw-gap-3 tw-w-full">
        <Text variant="bodyLgB" className="tw-text-primary-800">
          {item.uuid}
        </Text>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <AllocationTypeComponent item={item} />
          {renderField({ icon: 'icon-all_inbox', value: `${item?.hardwareIds?.length} Hardware` })}
          {renderField({
            icon: 'icon-location_on',
            value: item?.site !== null ? item?.site?.displayName : 'External Site',
          })}
        </div>
        <Divider className="tw-m-0" />
        {renderField({ icon: 'icon-calendar_today', value: `${DateFormat(item?.createdAt)}` })}
      </div>
    </button>
  );
};

export default HardwareAllocationCardItem;
