import React, { useMemo, useState } from 'react';
import { removeEmailListUser, useGetEmailList } from '../../apis/emailLists';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Form, Spin } from 'antd';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';
import Icon from '../../components/Icon';
import EmailListEmails from '../../components/tables/EmailListEmails';
import { MenuProps } from 'antd/lib';
import Text from '../../components/Text';
import DropdownApp from '../../components/Dropdown';
import AddNewEmailModal from './AddNewEmailModal';
import { useMutation, useQueryClient } from 'react-query';
import { EmailListDetailsSearchParamsI } from '../../interfaces/emailList';
import { PAGE_SIZE } from '../../constants';
import EmailListsFilterForm from '../../components/forms/email-list/Filter';
import { handleFilterParams } from '../../utils/global';
import AddExistingUserModal from './AddExistingUserModal';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import DangerButton from '../../components/buttons/DangerButton';

const EmailListUpdate = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const name = urlSearch.get('search[name]');

    return {
      page,
      limit,
      search: name,
    } as EmailListDetailsSearchParamsI;
  }, [urlSearch]);
  const { data, isFetching } = useGetEmailList(Number(id), queryParams);
  const queryClient = useQueryClient();
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'email-lists');
  const [form] = Form.useForm();
  const [addEmailModalOpen, setAddEmailModalOpen] = useState(false);
  const [addExistingUserModalOpen, setAddExistingUserModalOpen] = useState(false);
  const { mutate: removeUser } = useMutation((formData: { email: string }) => removeEmailListUser(+id!, formData), {
    mutationKey: [`email-list/${id}`],
    onSuccess: () => {
      queryClient.invalidateQueries([`email-list/${id}?${handleFilterParams(queryParams)}`]);
    },
  });
  const setQueryParams = (params: Partial<EmailListDetailsSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`, { replace: true });
  };
  const handleFilterChange = (values: EmailListDetailsSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };
  if (!data?.emailList) return null;

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'add-existing-user') {
      setAddExistingUserModalOpen(true);
    }
    if (e.key === 'add-new-email') {
      setAddEmailModalOpen(true);
    }
  };

  const items: MenuProps['items'] = [
    access.isCanUpdate
      ? {
          key: 'add-existing-user',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-person" size={16} />
              <span>Add existing user</span>
            </Text>
          ),
        }
      : null,
    access.isCanUpdate
      ? {
          key: 'add-new-email',
          label: (
            <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-mail" size={16} />
              <span>Add new email</span>
            </Text>
          ),
        }
      : null,
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  const confirmRemove = async (values: { email: string }) => {
    removeUser(values);
    queryClient.invalidateQueries([`email-list/${id}`]);
  };

  const withAction = (record: { username: string; email: string }) => {
    return (
      <DangerButton
        icon={<Icon name="icon-remove_circle_outline" size={20} />}
        onOk={() => confirmRemove({ email: record.email })}
        content={
          <span>
            Are you sure you want to remove <span className="tw-font-bold">{record?.username}</span> from this email
            list?
          </span>
        }
        label="Remove"
        className="tw-bg-white !tw-text-error-500 tw-border-none tw-shadow-none hover:!tw-border-none tw-p-0 tw-gap-0 focus:!tw-bg-white focus:!tw-border-none focus:!tw-border-0"
      />
    );
  };

  return (
    <Spin spinning={isFetching}>
      <div className="tw-p-4">
        <CreateUpdateTopSection
          label="Edit Email List"
          labelButton="Update"
          override={
            <DropdownApp
              leftIcon={<Icon name="icon-add" />}
              buttonLabel={`Add Email`}
              menu={menuDropdownProps}
              size="large"
              type="primary"
              icon={false}
              style={{ width: 120 }}
            />
          }
          form={form}
        />
        <div className="details-container">
          <div className="content-wrap" style={{ marginTop: 16 }}>
            <div className="tw-flex tw-flex-row tw-gap-2 tw-mb-4 ">
              <EmailListsFilterForm queryParams={queryParams} onChange={handleFilterChange} />
            </div>
            <EmailListEmails dataSource={data.emailListUsers} withAction={withAction} />
          </div>
        </div>
        <AddExistingUserModal
          addExistingUserModalOpen={addExistingUserModalOpen}
          setAddExistingUserModalOpen={setAddExistingUserModalOpen}
          emailListUsers={data.emailListUsers.map((user) => {
            return { email: user.email, id: user.id };
          })}
        />
        <AddNewEmailModal addNewEmailModalOpen={addEmailModalOpen} setAddNewEmailModalOpen={setAddEmailModalOpen} />
      </div>
    </Spin>
  );
};

export default EmailListUpdate;
