import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import Text from '../Text';
import { doScanProductTrace } from '../../apis/productTraces';
import { ProductTraceScanProductSearchParamsI, ProductTraceScanResT } from '../../interfaces/productTrace';
import LotNumberIcon from '../../svgs/InvertColor';
import { ProductI } from '../../interfaces/product';
import LotNumbersFilterForm from './ LotNumbersFilter';

type Props = {
  productScanned?: ProductI;
  selectedLotNumbers: string[];
  setCurrentStep: (_: number) => void;
  setSelectedLotNumbers: (_: string[]) => void;
  setProductScanned: (_: ProductI | undefined) => void;
};

const ProductTraceLotNumberScanStep: React.FC<Props> = ({
  productScanned,
  selectedLotNumbers,
  setSelectedLotNumbers,
  setCurrentStep,
  setProductScanned,
}) => {
  const [dataChecked, setDataChecked] = useState<ProductTraceScanResT>();
  const [lotNumbersList, setLotNumbersList] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useState<ProductTraceScanProductSearchParamsI>({
    orderBy: 'createdAt',
    orderType: 'desc',
  });

  const handleGetLotNumbers = async ({
    productSku,
    queryParams,
  }: {
    productSku: string;
    queryParams: ProductTraceScanProductSearchParamsI;
  }) => {
    try {
      const response = await doScanProductTrace({ productSku, params: queryParams });
      setDataChecked(response);
      const list = response.lotNumbers.map((item) => item.lotNumber);
      setLotNumbersList(list);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (productScanned) {
      handleGetLotNumbers({ productSku: productScanned.sku, queryParams });
    }
  }, [productScanned]);

  const handleFilter = (values: { lotNumber: string }) => {
    const lotNumber = values.lotNumber;
    if (lotNumber !== '') {
      const filteredList = lotNumbersList.filter((item) => item.includes(values.lotNumber));
      setLotNumbersList(filteredList);
    } else setLotNumbersList(dataChecked?.lotNumbers.map((item) => item.lotNumber) || []);
  };

  const handleSelectLotNumbers = (lotNumber: string) => {
    const lotNumberIndex = selectedLotNumbers.indexOf(lotNumber);
    if (lotNumberIndex === -1) {
      setSelectedLotNumbers([...selectedLotNumbers, lotNumber]);
    } else {
      setSelectedLotNumbers(selectedLotNumbers.filter((item) => item !== lotNumber));
    }
  };

  const handleDropdownFilter = (status: string) => {
    if (status) {
      const filteredList = lotNumbersList.filter((item) => selectedLotNumbers.includes(item));
      setLotNumbersList(filteredList);
    } else {
      setLotNumbersList(dataChecked?.lotNumbers.map((item) => item.lotNumber) || []);
    }
  };

  const handleFilterChange = (queryParams: ProductTraceScanProductSearchParamsI) => {
    setQueryParams(queryParams);
    handleGetLotNumbers({ productSku: productScanned?.sku ?? '', queryParams });
  };

  const onDiscard = () => {
    setCurrentStep(1);
    setDataChecked(undefined);
    setProductScanned(undefined);
    setSelectedLotNumbers([]);
    setLotNumbersList([]);
  };

  const onContinue = () => {
    setCurrentStep(3);
  };

  return (
    <>
      <div className="tw-w-full tw-p-8 tw-mb-[70px] tw-my-0 tw-mx-auto">
        <Text variant="h3" component="h3" className="tw-mb-6 tw-w-full tw-text-center">
          Select Lot Number(s)
        </Text>
        <LotNumbersFilterForm
          queryParams={queryParams}
          handleFilter={handleFilter}
          handleDropdownFilter={handleDropdownFilter}
          handleFilterChange={handleFilterChange}
        />
        <Text variant="labelMd" component="p" className="tw-mb-6 tw-w-full tw-text-center">
          {selectedLotNumbers?.length} Selected - {dataChecked?.totalLotNumbers ?? 0} Total
        </Text>
        <div className="tw-flex tw-gap-4 tw-flex-wrap">
          {lotNumbersList.map((item) => (
            <Button
              onClick={() => handleSelectLotNumbers(item)}
              key={item}
              className={`tw-w-[166px] tw-flex-col tw-items-start tw-py-4 tw-px-3 tw-rounded-lg tw-shadow-simple tw-border-none hover:!tw-bg-primary-50 ${selectedLotNumbers.includes(item) ? 'tw-bg-primary-50' : 'tw-bg-white'}`}
            >
              <Text variant="capt1" className="tw-text-grey-700 !tw-flex tw-items-center tw-gap-1">
                <LotNumberIcon /> <span>Lot Number</span>
              </Text>
              <Text
                variant="bodyLgB"
                className="tw-mt-1 tw-text-primary-900 tw-w-[144px] tw-overflow-auto tw-text-left"
              >
                {item}
              </Text>
            </Button>
          ))}
        </div>
      </div>
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton
            label={'Previous Step'}
            onClick={onDiscard}
            className="!tw-text-grey-900 hover:!tw-text-grey-900 !tw-border-primary hover:!tw-border-primary"
          />
          <PrimaryButton label={'Continue'} onClick={onContinue} disabled={!dataChecked} />
        </div>
      </div>
    </>
  );
};

export default ProductTraceLotNumberScanStep;
