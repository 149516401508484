import { MenuProps, message, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import Text from '../../components/Text';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { doExportReport, getReportDeliveries } from '../../apis/fleetReports';
import { PAGE_SIZE } from '../../constants';
import { findDateRangeOptionFromISOString, handleFilterParams } from '../../utils/global';
import ReportTopSection from '../../components/Reports/TopSection';
import { FleetReportSearchParamsI } from '../../interfaces/fleetReports';
import DeliveryReportTable from '../../components/tables/DeliveryReport';
import { dateRangeOptions } from '../../constants/dashboard';
import { DateRangeT } from '../../interfaces';
import useBrowserBackward from '../../hooks/useBrowserBackward';
import EAnalytics from '../../analytics';

const AllocationDeliveriesPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useBrowserBackward('/hardware-allocations');
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'asc';
    const orderBy = urlSearch.get('orderBy') ?? 'Serial Code';
    const sites =
      urlSearch
        .get('filter[sites]')
        ?.split(',')
        .filter((item) => item !== '') ?? [];
    const products =
      urlSearch
        .get('filter[products]')
        ?.split(',')
        .filter((item) => item !== '') ?? [];
    const fromDate = urlSearch.get('filter[fromDate]');
    const toDate = urlSearch.get('filter[toDate]');
    const view = urlSearch.get('view') as 'csv' | 'xlsx';

    return {
      page,
      limit,
      orderType,
      orderBy,
      filter: { sites, products, fromDate, toDate },
      view,
    } as FleetReportSearchParamsI;
  }, [urlSearch]);

  const dateRangeQuery = findDateRangeOptionFromISOString(
    queryParams?.filter?.fromDate ?? '',
    queryParams?.filter?.toDate ?? '',
    dateRangeOptions,
  );

  const [activeDateRange, setActiveDateRange] = useState<DateRangeT>(dateRangeQuery ?? dateRangeOptions[0]);

  const {
    data: { delivery = [], total = 0 } = {},
    isFetching,
    refetch: refetchData,
  } = useQuery(['deliveries', 'list', queryParams], () => getReportDeliveries(queryParams), {
    select({ data: { delivery, total } }) {
      return { delivery, total };
    },
  });

  const currentPage = queryParams.page || 1;

  const pagination: any = {
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    total: total ?? 0,
    showTotal: (total: string) => `${total} Items`,
  };

  const setQueryParams = (params: any) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const handleFilterChange = (values: FleetReportSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const { mutate: exportReport, isLoading: isExportLoading } = useMutation(
    (params: FleetReportSearchParamsI) => doExportReport(params, 'Delivery'),
    {
      onError: (error: any) => {
        message.error(error ?? 'Failed to export deliveries');
      },
    },
  );

  const handleDropdownItemClick: MenuProps['onClick'] = (e) => {
    let fileType;
    if (e.key === 'csv') fileType = 'csv';
    if (e.key === 'xlsx') fileType = 'xlsx';
    const params = { ...queryParams, view: fileType as 'csv' | 'xlsx' };
    exportReport(params);
    EAnalytics.trackDownloadReport({ type: 'deliveries', fileType: fileType as 'csv' | 'xlsx' });
  };

  const items: MenuProps['items'] = [
    {
      key: 'csv',
      label: (
        <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
          <span>CSV</span>
        </Text>
      ),
    },
    {
      key: 'xlsx',
      label: (
        <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
          <span>Excel</span>
        </Text>
      ),
    },
  ];

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  const backFunction = () => {
    navigate('/hardware-allocations');
  };

  return (
    <div>
      <ReportTopSection
        queryParams={queryParams}
        title="Deliveries Report"
        description="Report detailing the flow of hardware within sites under your partnership."
        pageName="Delivery"
        totalItems={total}
        menuDropdownProps={menuDropdownProps}
        refetchData={refetchData}
        onChange={handleFilterChange}
        activeDateRange={activeDateRange}
        setActiveDateRange={setActiveDateRange}
        setQueryParams={setQueryParams}
        isExportLoading={isExportLoading}
        backFunction={backFunction}
      />
      <Spin spinning={isFetching}>
        <div className="tw-p-4">
          <DeliveryReportTable
            rowKey="Timestamp"
            pagination={pagination}
            dataSource={delivery}
            onChange2={handleFilter}
          />
        </div>
      </Spin>
    </div>
  );
};

export default AllocationDeliveriesPage;
