import React, { useMemo, useState } from 'react';
import DetailsView from '../DetailsView';
import Divider from '../Divider';
import { Form, Input, message, Spin } from 'antd';
import UploadAttachmentButton from '../buttons/UploadAttachmentButton';
import { AttachmentCategories } from '../../constants';
import SecondaryButton from '../buttons/SecondaryButton';
import ConfirmButton from '../buttons/ConfirmButton';
import { ProductTraceI, ProductTraceReportTypeT, ProductTraceVerifyReqT } from '../../interfaces/productTrace';
import dayjs from 'dayjs';
import { convertToApiFile, DateTimeFormat } from '../../utils/global';
import EAnalytics from '../../analytics';
import { useMutation, useQueryClient } from 'react-query';
import { createProductTrace } from '../../apis/productTraces';
import NonClosableModal from '../NonClosableModal';
import ProductTraceSuccessModal from './SuccessModal';
import SizeBox from '../SizeBox';
import Text from '../Text';
import ProductTraceStatus from './Status';
import { ProductI } from '../../interfaces/product';

const TraceContent = () => {
  return (
    <div>
      <h2>Proceed with Product Trace Report?</h2>
      <p>Once confirm, a Product Trace Report will be created</p>
    </div>
  );
};

const NotifyContent = (type: string, partnerNo: number, recipientsNo: number) => {
  return (
    <div>
      <h2>Proceed with Product Trace Report and send emails to notify involved Partners?</h2>
      <Text variant="bodyLg">
        Once confirm, a Product Trace Report will be created and an email of{' '}
        <span className="tw-font-bold">
          {type === 'onHole' ? '“Product On Hold Notice”' : '“Urgent Product Recall Notice”'}
        </span>{' '}
        will be sent to selected partners and recipients involved to notify them about their affected hardware:
      </Text>
      <ul className="tw-m-0 tw-pl-6">
        <li>
          {' '}
          <Text variant="bodyLg" className="tw-flex tw-items-center">
            <span className="tw-font-bold">{partnerNo}</span> &nbsp; partners
          </Text>
        </li>
        <li>
          {' '}
          <Text variant="bodyLg" className="tw-flex tw-items-center">
            <span className="tw-font-bold">{recipientsNo}</span> &nbsp; recipients
          </Text>
        </li>
      </ul>
    </div>
  );
};

type FormValuesT = {
  remarks: string;
  lotNumbers: string[];
  productSku: string;
  status: ProductTraceReportTypeT;
  selectedPartnerIds: number[];
  attachments: any[];
};

type Props = {
  verifiedData?: ProductTraceI;
  scannedData?: ProductTraceVerifyReqT;
  reportType: ProductTraceReportTypeT;
  selectedPartners: { partners: number[]; ecoToteNo: number; recipients: number };
  selectedLotNumbers: string[];
  productScanned?: ProductI;
  setCurrentStep: (step: number) => void;
  resetState: () => void;
};

const ProductTraceReview: React.FC<Props> = ({
  selectedPartners,
  verifiedData,
  reportType,
  selectedLotNumbers,
  productScanned,
  setCurrentStep,
  resetState,
}) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any[]>([]);
  const queryClient = useQueryClient();
  const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false);
  const [createdData, setCreatedData] = useState<ProductTraceI>();

  const { mutate: doCreateMutate, isLoading: isCreateLoading } = useMutation(createProductTrace, {
    onSuccess: async (data) => {
      setCreatedData(data);
      queryClient.invalidateQueries();
      setIsSuccessModal(true);
      resetState();
      setFiles([]);
    },
    onError: () => {
      message.error('Your action was failed! Please try again');
    },
  });

  const handleSubmit = (values: FormValuesT) => {
    const newValues = { ...values };
    newValues.lotNumbers = selectedLotNumbers ?? '';
    newValues.productSku = productScanned?.sku ?? '';
    newValues.status = reportType ?? '';
    newValues.selectedPartnerIds = selectedPartners.partners ?? '';
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    doCreateMutate(newValues);
    EAnalytics.trackScanComplete('product_trace');
  };

  const handleModalCancel = () => {
    setIsSuccessModal(false);
  };
  const emptyEcoToteNo = useMemo(() => {
    const emptyEcoTotes = verifiedData?.partners?.reduce((acc, partner) => acc + (partner?.emptyEcoTotes ?? 0), 0);
    return emptyEcoTotes;
  }, [verifiedData]);

  return (
    <Spin spinning={isCreateLoading}>
      <div className="tw-max-w-[712px] tw-my-4 tw-mx-auto tw-mb-[100px]">
        <DetailsView title="Product Trace Details">
          <div className="flex-row">
            <DetailsView.Field label="Date Created" value={DateTimeFormat(dayjs())} />
            <DetailsView.Field label="Product Name" value={productScanned?.name} />
            <DetailsView.Field label="SKU" value={productScanned?.sku} />
          </div>
          <div className="flex-row">
            <DetailsView.Field
              label="Lot Number"
              value={selectedLotNumbers?.map((lotNumber) => (
                <Text variant="bodyLgB" component="p" className="!tw-block" key={lotNumber}>
                  {lotNumber}
                </Text>
              ))}
            />
          </div>
          <Divider />
          <DetailsView.Field label="Total Number of Affected ecoTOTEs" value={verifiedData?.ecoToteIds?.length ?? 0} />
          <div className="flex-row">
            <DetailsView.Field label="Number of Full ecoTOTEs" value={verifiedData?.totalFullEcoTotes ?? 0} />
            <DetailsView.Field label="Number of Partial ecoTOTEs" value={verifiedData?.totalPartialEcoTotes ?? 0} />
          </div>
          <div className="flex-row">
            <DetailsView.Field label="Number of Empty ecoTOTEs (L)" value={emptyEcoToteNo} />
          </div>
          <Divider />
          <DetailsView.Field
            label="Total Volume Introduced (L)"
            value={verifiedData?.totalVolumeIntroduced ? (verifiedData?.totalVolumeIntroduced / 1000).toFixed(2) : 0}
          />
          <div className="flex-row">
            <DetailsView.Field
              label="Volume in Circulation (L)"
              value={verifiedData?.volumeCirculation ? (verifiedData?.volumeCirculation / 1000).toFixed(2) : 0}
            />
            <DetailsView.Field
              label="Volume Depleted (L)"
              value={verifiedData?.volumeDepleted ? (verifiedData?.volumeDepleted / 1000).toFixed(2) : 0}
            />
          </div>
          <Divider />
          <DetailsView.Field label="Number of Affected Partners" value={verifiedData?.numberOfAffectedPartners ?? 0} />
          {reportType !== 'trace' && (
            <div className="tw-bg-grey-300 tw-rounded-lg tw-border-primary tw-p-4">
              <div className="flex-row">
                <DetailsView.Field label="Number of Partners to notify" value={selectedPartners?.partners.length} />
                <DetailsView.Field label="Number of Recipients to notify" value={selectedPartners?.recipients} />
              </div>
              <SizeBox height={16} />
              <DetailsView.Field label="Trace Status" value={<ProductTraceStatus status={reportType} />} />
            </div>
          )}
          <Form
            form={form}
            requiredMark={false}
            colon={false}
            onFinish={handleSubmit}
            scrollToFirstError
            layout="vertical"
          >
            <div className="form-container">
              <div className="form-content !tw-mt-0">
                <Form.Item label="Remarks" name="remarks">
                  <Input.TextArea placeholder="Type remarks" rows={5} />
                </Form.Item>
                <p className="label">Media Attachments</p>
                <UploadAttachmentButton
                  categories={AttachmentCategories}
                  form={form}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
            </div>
          </Form>
        </DetailsView>
      </div>
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label="Previous Step" onClick={() => setCurrentStep(4)} />
          <ConfirmButton
            type="info"
            label="Finish"
            onOk={() => form.submit()}
            content={
              reportType === 'trace'
                ? TraceContent()
                : NotifyContent(reportType, selectedPartners?.partners.length, selectedPartners?.recipients)
            }
            closable={false}
            width={553}
          />
        </div>
      </div>
      <NonClosableModal isVisible={isSuccessModal} handleCancel={handleModalCancel} width={493}>
        <ProductTraceSuccessModal reportType={reportType} createdData={createdData} />
      </NonClosableModal>
    </Spin>
  );
};

export default ProductTraceReview;
