import { Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetConfigReport } from '../../apis/configurations';
import ConfigReportTopSection from '../../components/ConfigReport/TopSection';
import ConfigReportInformation from '../../components/ConfigReport/Information';
import ConfigReportResources from '../../components/ConfigReport/Resources';

const ConfigReportDetailsPage: React.FC = () => {
  const { code } = useParams<{ code?: string }>();
  const { data: report, isFetching } = useGetConfigReport(code);

  return (
    <Spin spinning={isFetching}>
      <div className="tw-p-5">
        <ConfigReportTopSection data={report} />
        <ConfigReportInformation data={report} />
        <ConfigReportResources data={report} />
      </div>
    </Spin>
  );
};

export default ConfigReportDetailsPage;
