import { Form, message, Spin } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useOutletContext, useParams } from 'react-router-dom';
import { CurrentUserI } from '../../interfaces/auth';
import { updateProfile } from '../../apis/profile';
import { useGetProfile } from '../../apis/auth';
import ProfileForm from '../../components/forms/ProfileForm';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';

const ProfileUpdatePage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetProfile();

  const { mutateAsync: updateMutate, isLoading: isPageLoading } = useMutation(updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`profile`, undefined],
      });
      queryClient.invalidateQueries();
      message.info('Update profile successfully!');
      setIsUpdated(true);
    },
    onError: () => {
      setIsUpdated(false);
      message.error('Update profile failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isLoading || isPageLoading}>
      <div className="users-container tw-p-4">
        <CreateUpdateTopSection label="Edit My Profile" labelButton="Update" form={form} />
        <ProfileForm
          setIsUpdated={setIsUpdated}
          isUpdated={isUpdated}
          form={form}
          handleSubmit={handleSubmit}
          profile={data?.data}
          currentUser={currentUser}
        />
      </div>
    </Spin>
  );
};

export default ProfileUpdatePage;
