import { Spin } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetHardware } from '../../apis/hardwares';
import TrackingInformation from '../../components/Tracking/Information';
import TrackingTopInfo from '../../components/Tracking/TopInfo';
import TrackingResources from '../../components/Tracking/Resources';

const TrackingDetailsPage: React.FC = () => {
  const { serialCode } = useParams<{ serialCode: string }>();
  const { data: hardware, isFetching, refetch } = useGetHardware(serialCode);
  const [currentTab, setCurrentTab] = useState<string>('1');

  return (
    <Spin spinning={isFetching}>
      <div className="tw-p-4">
        <TrackingTopInfo data={hardware} setCurrentTab={setCurrentTab} />
        <TrackingInformation data={hardware} setCurrentTab={setCurrentTab} />
        <TrackingResources
          data={hardware}
          id={serialCode}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          refetchData={refetch}
        />
      </div>
    </Spin>
  );
};

export default TrackingDetailsPage;
