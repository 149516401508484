import React, { useState } from 'react';
import {
  HardwareRegisterParamsI,
  HardwareScannedStateI,
  HardwareTypeI,
  HardwareVerifyCollectionI,
} from '../../interfaces/hardware';
import SecondaryButton from '../buttons/SecondaryButton';
import Divider from '../Divider';
import { Form, Input, Spin, message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { convertToApiFile, getCountryFromCode } from '../../utils/global';
import UploadAttachmentButton from '../buttons/UploadAttachmentButton';
import ConfirmButton from '../buttons/ConfirmButton';
import './AllocationReviewStep.scss';
import HardwareAllocationSuccessModal from './AllocationSuccessModal';
import HardwareAllocationReviewInformation from './AllocationReviewInformation';
import { doAllocationReceive, doAllocationSend } from '../../apis/hardwareAllocations';
import { AttachmentCategories } from '../../constants';
import NonClosableModal from '../NonClosableModal';
import { SiteI } from '../../interfaces/site';
import DetailsView from '../DetailsView';
import { handleGetUniqType } from '../../utils/hardware';
import Text from '../Text';
import Icon from '../Icon';
import SizeBox from '../SizeBox';
import EAnalytics from '../../analytics';
import { SiteReceiveScannedT } from '../../interfaces/hardwareAllocation';

const ConfirmContent = () => {
  return (
    <div>
      <h2>Proceed with Allocation (Receive) Report?</h2>
      <p>The Allocation (Receive) Report will be created.</p>
    </div>
  );
};

const SendConfirmContent = () => {
  return (
    <div>
      <h2>Proceed with Allocation (Send) Report?</h2>
      <p>The Allocation (Send) Report will be created.</p>
      <p>Additionally, a matching system generated Allocation (Receive) Report will be created.</p>
    </div>
  );
};

type Props = {
  registerVerifiedData?: HardwareVerifyCollectionI;
  allocationType: string;
  successData: any;
  site?: SiteI;
  siteReceiveData?: SiteReceiveScannedT;
  setCurrentStep: (step: number) => void;
  setScannedData: (_: any) => void;
  setChosenType?: (type: HardwareTypeI) => void;
  setSuccessData: (_: any) => void;
  setFinalScannedData: (_: HardwareScannedStateI[]) => void;
};

const HardwareAllocationReviewStep: React.FC<Props> = ({
  registerVerifiedData,
  allocationType,
  successData,
  site,
  siteReceiveData,
  setCurrentStep,
  setScannedData,
  setChosenType,
  setSuccessData,
  setFinalScannedData,
}) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any[]>([]);
  const [isSuccessModal, setIsSuccessModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleModalCancel = () => {
    setIsSuccessModal(false);
    form.resetFields();
  };

  const { mutate: doAllocationSendMutate, isLoading: isSendLoading } = useMutation(doAllocationSend, {
    onSuccess: async (data) => {
      setSuccessData(data);
      queryClient.invalidateQueries({
        queryKey: ['hardware-allocations', 'list'],
      });
      setIsSuccessModal(true);
    },
    onError: () => {
      message.error('Your action was failed! Please try again');
    },
  });

  const { mutate: doAllocationReceiveMutate, isLoading: isReceiveLoading } = useMutation(doAllocationReceive, {
    onSuccess: async (data) => {
      setSuccessData(data);
      queryClient.invalidateQueries({
        queryKey: ['hardware-allocations', 'list'],
      });
      setIsSuccessModal(true);
    },
    onError: () => {
      message.error('Your action was failed! Please try again');
    },
  });

  const handleSubmit = (values: HardwareRegisterParamsI) => {
    const newValues: any = values;
    const hardwareList = registerVerifiedData?.hardwareList?.map((item: any) => {
      delete item.isInDB;
      delete item.error;
      delete item.currentData;
      delete item.type;
      return item;
    });

    newValues.hardwareList = hardwareList;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    if (allocationType === 'send') {
      newValues.fromSiteId = site?.id;
      newValues.siteReferenceCode = siteReceiveData?.referenceCode;
      doAllocationSendMutate(newValues);
    } else {
      newValues.siteId = site?.id;
      doAllocationReceiveMutate(newValues);
    }
    setScannedData([]);
    setFinalScannedData([]);
    EAnalytics.trackScanComplete('allocate');
  };

  return (
    <Spin spinning={!registerVerifiedData || isSendLoading || isReceiveLoading}>
      <div className="component-hardware-allocation-review-step">
        <Form form={form} onFinish={handleSubmit} scrollToFirstError layout="vertical">
          {allocationType !== 'send' && (
            <HardwareAllocationReviewInformation site={site} allocationType={allocationType} />
          )}
          <div className="content-wrap details-container" style={{ marginTop: 16 }}>
            <h2 className="details-title">Allocation Details</h2>
            <div className="details-content-wrap">
              {allocationType === 'send' && (
                <>
                  <div className="item">
                    <div className="label">Site (Send)</div>
                    <div className="value">
                      {
                        <>
                          <Text variant="bodyMdB" className="!tw-block">
                            {site?.displayName}
                          </Text>
                          <Text variant="bodyMdB">
                            {[site?.addressLine1, site?.addressLine2, site?.postalCode]
                              .filter((item) => item !== null)
                              .join(', ')}{' '}
                            {getCountryFromCode(site?.country ?? 'SG')}, {site?.postalCode}
                          </Text>
                        </>
                      }
                    </div>
                  </div>
                  <div className="item">
                    <div className="label">Site (Receive)</div>
                    <div className="value">
                      {
                        <>
                          <Text variant="bodyMdB" className="!tw-block">
                            {siteReceiveData?.displayName}
                          </Text>
                          <Text variant="bodyMdB" className="!tw-block">
                            {siteReceiveData?.referenceCode}
                          </Text>
                          <Text variant="bodyMdB">
                            {[siteReceiveData?.addressLine1, siteReceiveData?.addressLine2, siteReceiveData?.postalCode]
                              .filter((item) => item !== null)
                              .join(', ')}{' '}
                            {getCountryFromCode(siteReceiveData?.country ?? 'SG')}, {siteReceiveData?.postalCode}
                          </Text>
                        </>
                      }
                    </div>
                  </div>
                </>
              )}
              <Divider />
              <DetailsView.Field label="Number of Hardware" value={registerVerifiedData?.numberOfValid} />
              <SizeBox height={12} />
              <DetailsView.Field
                label="Hardware Type"
                value={handleGetUniqType(registerVerifiedData?.hardwareList ?? []).map(
                  (item: { type: string; count: number }) => (
                    <>
                      {item.type.includes('ecoTOTE') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="bodyLgB" className="tw-min-w-[24px]">
                            {item.count}
                          </Text>
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-eco-tote" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                      {item.type.includes('ecoPLANT') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="bodyLgB" className="tw-min-w-[24px]">
                            {item.count}
                          </Text>
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-eco-plant" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                      {item.type.includes('SmartPour') && (
                        <span className="tw-flex tw-gap-2 tw-items-center">
                          <Text variant="bodyLgB" className="tw-min-w-[24px]">
                            {item.count}
                          </Text>
                          <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                            <Icon name="icon-smart-pour" size={20} /> <span>{item.type}</span>
                          </Text>
                        </span>
                      )}
                    </>
                  ),
                )}
              />
              <div className="form-container">
                <div className="form-content">
                  <Form.Item label="Remarks" name="remarks">
                    <Input.TextArea placeholder="Type remarks" rows={5} />
                  </Form.Item>
                  <p className="label">Media Attachments</p>
                  <UploadAttachmentButton
                    categories={AttachmentCategories}
                    form={form}
                    setFiles={setFiles}
                    files={files}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
        <div className="hardware-footer-section">
          <div>
            <SecondaryButton label="Previous Step" onClick={() => setCurrentStep(3)} />
            <ConfirmButton
              type="info"
              label="Finish Scanning"
              onOk={() => form.submit()}
              content={allocationType === 'send' ? <SendConfirmContent /> : <ConfirmContent />}
              closable={false}
              width={577}
            />
          </div>
        </div>
      </div>
      <NonClosableModal isVisible={isSuccessModal} handleCancel={handleModalCancel}>
        <HardwareAllocationSuccessModal
          data={successData}
          setChosenType={setChosenType}
          setCurrentStep={setCurrentStep}
          allocationType={allocationType}
        />
      </NonClosableModal>
    </Spin>
  );
};

export default HardwareAllocationReviewStep;
