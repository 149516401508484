import React, { useState } from 'react';
import {
  HardwareGroupTypeI,
  HardwareItemI,
  HardwareScannedStateI,
  HardwareTypeI,
  HardwareVerifyCollectionI,
} from '../../interfaces/hardware';
import HardwareRegisterChooseTypeStep from '../../components/Hardware/RegisterChooseTypeStep';
import HardwareRegisterScanStep from '../../components/Hardware/ScanStep';
import { doVerifyHardwareGroup } from '../../apis/hardwares';
import HardwareVerifyFilterForm from '../../components/forms/hardware/VerifyFilter';
import { HardwareProgressStepListI } from '../../interfaces';
import SharedHardwareProgressBar from '../../components/shared/Hardware/ProgressBar';
import useNavigateAway from '../../hooks/useNavigateAway';
import HardwareGroupingReviewStep from '../../components/Hardware/GroupingReviewStep';
import HardwareGroupVerifyStep from '../../components/shared/Hardware/GroupVerifyStep';
import { getEcototeVolumn, handleRemoveVerifiedData } from '../../utils/hardware';
import HardwareChooseSiteStep from '../../components/shared/Hardware/ChooseSiteStep';
import { useNavigate } from 'react-router-dom';
import { SiteI } from '../../interfaces/site';
import { useAppNotification } from '../../hooks/useAppNotification';
import Text from '../../components/Text';

const progressStepList: HardwareProgressStepListI[] = [
  {
    step: 1,
    label: 'Type',
  },
  {
    step: 2,
    label: 'Site',
  },
  {
    step: 3,
    label: 'Scan',
  },
  {
    step: 4,
    label: 'Verify',
  },
  {
    step: 5,
    label: 'Review',
  },
];

const HardwareGroupNewPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [registerType, setRegisterType] = useState<HardwareTypeI>();
  const [registerData, setRegisterData] = useState<HardwareScannedStateI[]>([]);
  const [verifiedData, setVerifiedData] = useState<HardwareVerifyCollectionI>();
  const [filtered, setFiltered] = useState<HardwareVerifyCollectionI>();
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);
  const [finalScannedData, setFinalScannedData] = useState<HardwareScannedStateI[]>([]);
  const [siteId, setSiteId] = useState<number | undefined>();
  const [site, setSite] = useState<SiteI | undefined>(undefined);
  const { openNotification } = useAppNotification();

  useNavigateAway({
    when: finalScannedData?.length > 0 || registerData?.length > 0,
    handleProceed() {
      setRegisterData([]);
      setFinalScannedData([]);
      setStopDecodingScanner(true);
    },
  });

  const handleVerify = async () => {
    try {
      const finalData = [...finalScannedData, ...registerData];
      const params = {
        hardwareList: finalData,
        hardwareType: registerType === 'ecotote1.75l' || registerType === 'ecotote4.5l' ? 'ecotote' : registerType,
        toteVolume:
          registerType === 'ecotote1.75l' || registerType === 'ecotote4.5l'
            ? getEcototeVolumn(registerType)
            : undefined,
        siteId: siteId,
      } as {
        hardwareList: HardwareScannedStateI[];
        hardwareType: HardwareGroupTypeI;
        toteVolume: string;
        siteId: number;
      };

      const data = await doVerifyHardwareGroup(params);
      setVerifiedData(data);
      if (data) {
        const newList = data.hardwareList?.map((item: HardwareItemI) => {
          return { serialCode: item.serialCode };
        });
        setFinalScannedData(newList);
        openNotification({
          type: data?.numberOfInvalid ? 'warning' : 'success',
          title: (
            <div>
              <span>You have scanned 2 Code(s) that includes:</span>
              <ul className="tw-my-1 tw-px-6 tw-pl-[25px]">
                <li>{data.numberOfHardwareScanned} hardware</li>
                {data?.numberOfInvalid ? <li>{data.numberOfInvalid} invalid code(s)</li> : null}
              </ul>
            </div>
          ),
          content: data?.numberOfInvalid ? (
            <Text variant="bodyMd">Please remove invalid code(s) before proceeding to next step!</Text>
          ) : null,
        });
      }
      setRegisterData([]);
      setCurrentStep(4);
    } catch (error) {
      console.log('Could not do verify. Please try again');
    }
  };

  const handleVerifyFilter = (values: { serialCode: HardwareScannedStateI['serialCode'] }) => {
    if (values?.serialCode) {
      const hardwareList = verifiedData?.hardwareList?.filter(
        (item: HardwareScannedStateI) => item.serialCode === values.serialCode,
      );
      const newData: any = { ...verifiedData };
      newData.hardwareList = hardwareList;
      setFiltered(newData);
    } else {
      setFiltered(undefined);
    }
  };

  const handleDeleteVerifiedData = (item: HardwareItemI) => {
    const result = handleRemoveVerifiedData(verifiedData, item.serialCode);
    setVerifiedData(result);
    setFinalScannedData((prevItems) => {
      const index = finalScannedData.findIndex((data) => data.serialCode === item.serialCode);
      if (index !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      }
      return prevItems;
    });
  };

  const handleScanmoreButton = () => {
    setCurrentStep(3);
    setStopDecodingScanner(false);
  };

  const backFunction = () => {
    if (currentStep !== 1) setCurrentStep(currentStep - 1);
    if (currentStep === 1) navigate(-1);
  };

  return (
    <div>
      <SharedHardwareProgressBar
        currentStep={currentStep}
        title="Hardware Grouping"
        stepList={progressStepList}
        backFunction={backFunction}
        isShowWorkflowRequirements={true}
        doNotShowKey="doNotShowHartwareGrouping"
        pageName="hardwareGroup"
      />
      {currentStep === 4 && (
        <HardwareVerifyFilterForm
          handleVerifyFilter={handleVerifyFilter}
          numberOfHardwareScanned={verifiedData?.numberOfHardwareScanned}
          numberOfInvalid={verifiedData?.numberOfInvalid}
        />
      )}
      {currentStep === 1 && (
        <HardwareRegisterChooseTypeStep
          setCurrentStep={setCurrentStep}
          setRegisterType={setRegisterType}
          registerType={registerType}
          actionType="group"
        />
      )}
      {currentStep === 2 && (
        <HardwareChooseSiteStep
          setSite={setSite}
          siteId={siteId}
          setCurrentStep={setCurrentStep}
          setSiteId={setSiteId}
        />
      )}
      {currentStep === 3 && (
        <HardwareRegisterScanStep
          setCurrentStep={setCurrentStep}
          setRegisterData={setRegisterData}
          registerData={registerData}
          finalScannedData={finalScannedData}
          setFinalScannedData={setFinalScannedData}
          registerType={registerType}
          handleVerify={handleVerify}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          setVerifiedData={setVerifiedData}
          pageName={'Hardware Group'}
        />
      )}
      {currentStep === 4 && (
        <HardwareGroupVerifyStep
          setCurrentStep={setCurrentStep}
          registerVerifiedData={filtered ?? verifiedData}
          handleDeleteVerifiedData={handleDeleteVerifiedData}
          handleScanmoreButton={handleScanmoreButton}
        />
      )}
      {currentStep === 5 && (
        <HardwareGroupingReviewStep
          setCurrentStep={setCurrentStep}
          registerType={registerType}
          registerVerifiedData={verifiedData}
          setRegisterData={setRegisterData}
          action="create"
          setFinalScannedData={setFinalScannedData}
          siteId={siteId}
          site={site}
          previousStep={4}
        />
      )}
    </div>
  );
};

export default HardwareGroupNewPage;
