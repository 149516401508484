import Icon from '../components/Icon';
import Text from '../components/Text';
import React from 'react';

export const renderType = (record: any, field: string) => {
  switch (field) {
    case 'ecoplant':
      return (
        <Text variant="labelMd" className="tw-flex tw-items-center tw-gap-0.5">
          <Icon name="icon-eco-plant" size={20} /> ecoPLANT
        </Text>
      );
    case 'smartpour':
      return (
        <Text variant="labelMd" className="tw-flex tw-items-center tw-gap-0.5">
          <Icon name="icon-smart-pour" size={20} /> SmartPour
        </Text>
      );
    case 'ecotote':
      if (record['Volume Format'] === '1.75L')
        return (
          <Text variant="labelMd" className="tw-flex tw-items-center tw-gap-0.5">
            <Icon name="icon-eco-plant" size={20} /> ecoTOTE 1.75L
          </Text>
        );
      if (record['Volume Format'] === '4.5L')
        return (
          <Text variant="labelMd" className="tw-flex tw-items-center tw-gap-0.5">
            <Icon name="icon-eco-plant" size={20} /> ecoTOTE 4.5L
          </Text>
        );
      return 'ecoTOTE';
    default:
      return '';
  }
};
