import React, { Fragment, useMemo, useState } from 'react';
import { Form, Input, Radio, RadioChangeEvent, Select } from 'antd';
import { humanizeText } from '../../utils/global';
import countryList from 'react-select-country-list';
import { useQuery } from 'react-query';
import { getHardwareSitesList } from '../../apis/hardwares';
import { SiteI } from '../../interfaces/site';

type Props = {
  allocationType: string;
  site?: SiteI;
};

const HardwareAllocationReviewInformation: React.FC<Props> = ({ allocationType, site }) => {
  const [allocationInfoType, setAllocationInfoType] = useState<string>('internal');
  const countryOptions = useMemo(() => countryList().getData(), []);

  const { data: { sites: userSites = [] } = {}, isLoading: isUserSiteLoading } = useQuery(
    ['sites', 'list', {}],
    () => getHardwareSitesList(),
    {
      select({ data: { sites } }) {
        return { sites };
      },
    },
  );

  const { data: { sites: allSites = [] } = {}, isLoading: isAllSiteLoading } = useQuery(
    ['sites', 'list', {}],
    () => getHardwareSitesList({ limit: 500, page: 1 }),
    {
      select({ data: { sites } }) {
        return { sites };
      },
      enabled: allocationType === 'send',
    },
  );

  const isSiteLoading = isAllSiteLoading || isUserSiteLoading;
  const sites = allocationType === 'send' ? allSites : userSites;

  const onRadioChange = (event: RadioChangeEvent) => {
    setAllocationInfoType(event.target.value);
  };

  return (
    <div className="content-wrap">
      <div className="form-container">
        <div className="form-content">
          <h2 className="form-title">Allocatee - Receive to</h2>
          {allocationType === 'send' && (
            <Fragment>
              <p style={{ marginBottom: 8 }}>Is it internal or external allocation?</p>
              <Radio.Group onChange={onRadioChange} value={allocationInfoType} style={{ marginBottom: 16 }}>
                <Radio value="internal">Internal</Radio>
                <Radio value="external">External</Radio>
              </Radio.Group>
              {allocationInfoType === 'internal' ? (
                <Form.Item label="Site" name="siteId" rules={[{ required: true, message: 'This field is required' }]}>
                  <Select placeholder="Choose a partner" style={{ width: '100%' }} loading={isSiteLoading}>
                    {sites.map((site) => (
                      <Select.Option key={site?.id} value={site.id}>
                        {humanizeText(site?.legalName)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Fragment>
                  <Form.Item
                    label="Address Line 1"
                    name="addressLine1"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Input placeholder="Type here" />
                  </Form.Item>
                  <Form.Item label="Address Line 2" name="addressLine2">
                    <Input placeholder="Type here" />
                  </Form.Item>
                  <Form.Item label="Address Line 3" name="addressLine3">
                    <Input placeholder="Type here" />
                  </Form.Item>
                  <Form.Item
                    label="Country"
                    name="country"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Select
                      placeholder="Select a country"
                      showSearch
                      optionFilterProp="label"
                      options={countryOptions}
                    />
                  </Form.Item>
                  <Form.Item label="Region" name="region">
                    <Input placeholder="Type here" />
                  </Form.Item>
                  <Form.Item label="State/Province" name="state">
                    <Input placeholder="Type here" />
                  </Form.Item>
                  <Form.Item
                    label="Postal/Zip Code"
                    name="postalCode"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Input placeholder="Type here" />
                  </Form.Item>
                </Fragment>
              )}
            </Fragment>
          )}
          {allocationType === 'receive' && (
            <div className="details-container">
              <div className="details-content-wrap">
                <div className="item">
                  <div className="label">Site (Receive)</div>
                  <div className="value">{site?.displayName}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HardwareAllocationReviewInformation;
