import React, { Fragment, useState } from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import { Modal, Tabs, TabsProps } from 'antd';
import { HardwareGroupI, HardwareScannedStateI, HardwareTypeI } from '../../interfaces/hardware';
import './RegisterScanStep.scss';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import { convertToHardwareType, convertToHardwareTypeAPI } from '../../utils/hardware';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import SharedScanQRCode from '../shared/Hardware/ScanQRCodeScreen';
import SharedManualScreen from '../shared/Hardware/ManualScreen';
import ScanTabsContent from '../shared/Hardware/ScanTabsContent';
import EcoToteIllustration from '../../svgs/illustrations/EcoToteIllustration';
import Text from '../Text';
import { Notice } from '../Notice';
import InformationIcon from '../../svgs/InformationIcon';
import SmartPourIllustration from '../../svgs/illustrations/SmartPourIllustration';
import EcoPlantIllustration from '../../svgs/illustrations/EcoPlantIllustration';
import SecondaryButton from '../buttons/SecondaryButton';

type Props = {
  registerData: HardwareScannedStateI[];
  finalScannedData?: HardwareScannedStateI[];
  registerType?: HardwareTypeI;
  stopDecodingScanner: boolean;
  action?: string;
  group?: HardwareGroupI;
  pageName?: string;
  setCurrentStep: (step: number) => void;
  setRegisterData: (_: any) => void;
  setFinalScannedData?: (_: any) => void;
  handleVerify: () => void;
  setStopDecodingScanner: (_: any) => void;
  setVerifiedData: (_: any) => void;
};

const HardwareRegisterScanStep: React.FC<Props> = ({
  registerData,
  finalScannedData = [],
  registerType,
  stopDecodingScanner,
  action = 'create',
  group,
  pageName,
  setCurrentStep,
  setRegisterData,
  setFinalScannedData,
  handleVerify,
  setStopDecodingScanner,
  setVerifiedData,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [screen, setScreen] = useState<'start' | 'scan'>('start');

  const handleDiscard = () => {
    if (action === 'update') {
      setCurrentStep(2);
      setRegisterData([]);
      const hardware = group?.hardwares?.map((item) => {
        return {
          serialCode: item.serialCode,
          type: item.type,
          isManualInput: false,
        };
      });
      setFinalScannedData?.(hardware);
    } else {
      setRegisterData([]);
      setFinalScannedData?.([]);
      setCurrentStep(2);
      setScreen('start');
    }
    setVerifiedData({});
  };

  const validateScanResult = ({ text, isManualInput = false }: { text: string; isManualInput: boolean }) => {
    let status = 'success';
    const validateData = [...finalScannedData, ...registerData];
    if (validateData.length > 0) {
      if (validateData.find((data: HardwareScannedStateI) => data.serialCode === text)) {
        status = 'error';
      }
    }

    setTimeout(() => {
      if (status === 'success') {
        const successModal = modal.success({
          type: 'success',
          title: 'Successfully Scanned!',
          content: 'Code detected and added to scanned list!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <CheckCircleIcon width={16} height={16} color="#0A7A08" />,
          width: 345,
        });
        const newType = convertToHardwareTypeAPI(registerType);

        const newData = {
          ...newType,
          serialCode: text,
          isManualInput,
        } as HardwareScannedStateI;
        setRegisterData([...registerData, newData]);

        setTimeout(() => {
          successModal.destroy();
        }, 1000);
      }

      if (status === 'error') {
        const errorModal = modal.error({
          type: 'error',
          title: 'Failed Scanning!',
          content: 'We detected a duplicated scan. Please check again!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <ErrorModalIcon color="#B13939" />,
          width: 345,
        });

        setTimeout(() => {
          errorModal.destroy();
        }, 1 * 1000);
      }
    }, 300);
  };

  const handleScanOrManualSubmit = (result: { text: string; isManualInput: boolean }) => {
    validateScanResult(result);
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <SharedScanQRCode
          title={convertToHardwareType(
            registerType === 'ecotote1.75l' || registerType === 'ecotote4.5l' ? 'ecotote' : registerType ?? '',
          )}
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          scanTabsContent={<ScanTabsContent registerData={registerData} registerType={registerType} />}
          pageName={pageName}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <SharedManualScreen
          title={convertToHardwareType(
            registerType === 'ecotote1.75l' || registerType === 'ecotote4.5l' ? 'ecotote' : registerType ?? '',
          )}
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanTabsContent={<ScanTabsContent registerData={registerData} registerType={registerType} />}
        />
      ),
    },
  ];

  const handleFinishScanning = () => {
    handleVerify();
    setStopDecodingScanner(true);
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  return (
    <div className="component-hardware-register-scan-step">
      <div className="wrapper">
        {screen === 'start' && (
          <div className="tw-w-[450px] tw-h-[310px] tw-mx-auto tw-my-0">
            {(registerType === 'ecotote1.75l' || registerType === 'ecotote4.5l') && (
              <>
                <div
                  className={`tw-w-[450px] tw-h-[310px] tw-border-primary tw-bg-white tw-rounded-2xl tw-flex tw-items-end tw-justify-center`}
                >
                  <EcoToteIllustration />
                </div>
                <Text variant="bodyLg" className="tw-text-left tw-mt-3 tw-min-h-24">
                  <b>ecoTOTE</b> - QR/Barcode/NFC chip can be found on the outer casing
                </Text>
              </>
            )}
            {registerType === 'smartpour' && (
              <>
                <div
                  className={`tw-w-[450px] tw-h-[310px] tw-border-primary tw-bg-white tw-rounded-2xl tw-flex tw-items-center tw-justify-center`}
                >
                  <SmartPourIllustration />
                </div>
                <Text variant="bodyMd" className="tw-text-left tw-mt-3 tw-min-h-24">
                  QR/Barcode/NFC chip can be found:
                  <ul className="tw-my-0 tw-pl-5">
                    <li>
                      <b>SmartPour 1.1S:</b> on the underside
                    </li>
                    <li>
                      <b>SmartPour 2.0S:</b> on the inside of the rear door
                    </li>
                  </ul>
                </Text>
              </>
            )}
            {registerType === 'ecoplant' && (
              <>
                <div
                  className={`tw-w-[450px] tw-h-[310px] tw-border-primary tw-bg-white tw-rounded-2xl tw-flex tw-items-end tw-justify-center`}
                >
                  <EcoPlantIllustration />
                </div>
                <Text variant="bodyLg" className="tw-text-left tw-mt-3 tw-min-h-24">
                  <b>ecoPLANT</b> - QR/Barcode/NFC chip can be found on the Filling Machine
                </Text>
              </>
            )}
            <Notice
              description="Before proceed scanning, please be reminded to check for any signs of tampering on: Screws, Seal, Cap"
              type="info"
              icon={<InformationIcon />}
              closable={false}
            />
          </div>
        )}
        {screen === 'scan' && <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} />}
      </div>
      {contextHolder}
      <div className="hardware-footer-section">
        <div>
          {screen === 'start' && (
            <>
              <SecondaryButton label="Previous" onClick={handleDiscard} />
              <PrimaryButton label="Start Scanning" onClick={() => setScreen('scan')} />
            </>
          )}
          {screen === 'scan' && <PrimaryButton label="Finish Scanning" onClick={handleFinishScanning} />}
        </div>
      </div>
    </div>
  );
};

export default HardwareRegisterScanStep;
