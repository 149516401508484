import { Button, Dropdown, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { CurrentUserI } from '../../../interfaces/auth';
import { handleAccessControl } from '../../../utils/role';
import { PAGE_SIZE } from '../../../constants';
import { PartnerSearchParams } from '../../../interfaces/partner';
import { getPartnersList } from '../../../apis/partners';
import { handleFilterParams } from '../../../utils/global';
import Text from '../../../components/Text';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import PlusIcon from '../../../svgs/PlusIcon';
import PartnersFilterForm from '../../../components/forms/partner/Filter';
import PartnersTable from '../../../components/tables/Partners';
import PlatformPartnersCardList from '../../../components/card-list/platforms/Partners';
import DisplayLastUpdateApp from '../../../components/DisplayLastUpdate';
import SizeBox from '../../../components/SizeBox';
import EmptyData from '../../../components/EmptyData';

const PlatformPartnersPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'platform/partners');
  const impersonateAccess = handleAccessControl(currentUser, 'platform/impersonation');
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');
    const status = urlSearch.get('filter[status]')?.split(',') ?? [];
    const headquater = urlSearch.get('filter[headquater]')?.split(',') ?? [];
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search,
      filter: { status, headquater },
    } as PartnerSearchParams & { viewMode?: string };
  }, [urlSearch]);
  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);

  const {
    data: { partners = [], total = 0 } = {},
    isFetching,
    refetch: refetchData,
  } = useQuery(['platform-partners', 'list', queryParams], () => getPartnersList(queryParams), {
    select({ data: { partners, total } }) {
      return { partners, total };
    },
  });
  const currentPage = queryParams?.page ?? 1;

  const pagination: any = {
    total,
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    showTotal: (total: string) => `${total} Items`,
  };

  const setQueryParams = (params: Partial<PartnerSearchParams>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const handleFilterChange = (values: PartnerSearchParams) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/platform/partners/${record?.id}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
              access.isCanUpdate
                ? {
                    key: '2',
                    label: (
                      <Link to={`/platform/partners/${record?.id}/edit`}>
                        <Button className="btn-table-action-primary" type="link">
                          Edit Profile
                        </Button>
                      </Link>
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <Spin spinning={isFetching ?? isRefetchLoading}>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">
        <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
          <Text component="h3" variant="h3">
            Partner List
          </Text>
          {access.isCanCreate && (
            <Link to="/platform/partners/new">
              <PrimaryButton label="Add Partner" icon={<PlusIcon />} />
            </Link>
          )}
        </div>
        <PartnersFilterForm
          queryParams={queryParams}
          onChange={handleFilterChange}
          refetchData={refetchData}
          setIsRefetchLoading={setIsRefetchLoading}
        />
        {!isFetching && partners?.length === 0 && (
          <EmptyData title="No Partner" message="All Partner Profiles across platform will be displayed here." />
        )}
        {!isFetching && partners.length !== 0 && (
          <>
            <DisplayLastUpdateApp />
            <SizeBox height={8} />
            {!queryParams.viewMode ? (
              <PlatformPartnersCardList
                impersonateAccess={impersonateAccess}
                data={partners}
                pagination={pagination}
                onPaginationChange={handleFilter}
                access={access}
              />
            ) : (
              <PartnersTable
                rowKey="id"
                loading={isFetching}
                pagination={pagination}
                dataSource={partners}
                withAction={withAction}
                onChange2={handleFilter}
              />
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default PlatformPartnersPage;
