import { Form, message, Spin } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { updateUser, useGetUser } from '../../apis/users';
import UserForm from '../../components/forms/user/Form';
import { CurrentUserI } from '../../interfaces/auth';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';

const UserUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const currentUser: CurrentUserI = useOutletContext();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { id } = useParams<{ id: string }>();
  const { data, isFetching } = useGetUser(Number(id));

  const { mutateAsync: updateMutate, isLoading: isPageLoading } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`users/${id}`, undefined],
      });
      queryClient.invalidateQueries();
      message.info('Update user successfully!');
      navigate(-1);
    },
    onError: () => {
      message.error('Update user failed! Please try again');
    },
  });

  const handleSubmit = async (values: any) => {
    updateMutate({ ...values, id });
  };

  return (
    <Spin spinning={isFetching ?? isPageLoading}>
      <div className="users-container tw-p-4">
        <CreateUpdateTopSection label="Edit User" labelButton="Update" form={form} />
        <UserForm form={form} handleSubmit={handleSubmit} user={data} userId={Number(id)} currentUser={currentUser} />
      </div>
    </Spin>
  );
};

export default UserUpdatePage;
