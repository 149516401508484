import React from 'react';
import { Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import './HardwareCardItem.scss';
import { DateFormat } from '../../utils/global';
import { RegistrationHistoryI } from '../../interfaces/registrationHistory';
import { getTypeFromApi } from '../../utils/hardware';
import Text from '../Text';
import { IconName } from '../Icon/iconName';
import Icon from '../Icon';
import TooltipIcon from '../buttons/TooltipIcon';

type Props = {
  item: RegistrationHistoryI;
};
const HardwareCardItem: React.FC<Props> = ({ item }) => {
  const navitate = useNavigate();

  const handleCardClick = (item: RegistrationHistoryI) => {
    navitate(`/hardware-registrations/${item.uuid}`);
  };

  const renderField = (props: { icon: IconName; value: string }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <button
      className="hardware-card-item card-item"
      onClick={() => handleCardClick(item)}
      onKeyDown={() => handleCardClick(item)}
    >
      {item?.remarks ? (
        <TooltipIcon
          icon="icon-text_snippet"
          text="Remarks"
          className="btn-more-action"
          iconClassName="tw-text-grey-800"
        />
      ) : null}
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
        <Text variant="inputLabelSm" className="tw-text-grey-700">
          Registration Report
        </Text>
        <Text variant="bodyLgB" className="tw-text-primary-800">
          {item.uuid}
        </Text>
        <div className="tw-flex tw-flex-col tw-gap-2">
          {renderField({ icon: 'icon-all_inbox', value: `${item?.hardwareIds?.length} Hardware` })}
          {renderField({ icon: 'icon-local_offer', value: getTypeFromApi(item?.hardwareList?.[0]) })}
          {renderField({ icon: 'icon-location_on', value: item?.site?.displayName })}
        </div>
        <Divider className="tw-m-0" />
        {renderField({ icon: 'icon-calendar_today', value: `${DateFormat(item?.createdAt)}` })}
      </div>
    </button>
  );
};

export default HardwareCardItem;
