import React, { useEffect, useState } from 'react';
import {
  HardwareItemI,
  HardwareScannedStateI,
  HardwareVerifyCollectionI,
  HardwareVerifyTypeI,
} from '../../interfaces/hardware';
import AllocationScanStep from '../../components/HardwareAllocation/ScanStep';
import HardwareAllocationVerifyStep from '../../components/HardwareAllocation/AllocationVerifyStep';
import HardwareAllocationReviewStep from '../../components/HardwareAllocation/AllocationReviewStep';
import { doVerifyHardware } from '../../apis/hardwares';
import HardwareVerifyFilterForm from '../../components/forms/hardware/VerifyFilter';
import { HardwareProgressStepListI } from '../../interfaces';
import SharedHardwareProgressBar from '../../components/shared/Hardware/ProgressBar';
import useNavigateAway from '../../hooks/useNavigateAway';
import { handleRemoveVerifiedData } from '../../utils/hardware';
import { useNavigate } from 'react-router-dom';
import { SiteI } from '../../interfaces/site';
import HardwareChooseSiteStep from '../../components/shared/Hardware/ChooseSiteStep';
import { useAppNotification } from '../../hooks/useAppNotification';
import Text from '../../components/Text';
import EAnalytics from '../../analytics';
import AllocationScanSiteStep from '../../components/HardwareAllocation/ScanSiteStep';
import { SiteReceiveScannedT } from '../../interfaces/hardwareAllocation';
import { allocationGallery } from '../../constants/hardware';

const progressStepList: HardwareProgressStepListI[] = [
  {
    step: 1,
    label: 'Site',
  },
  {
    step: 2,
    label: 'Scan',
  },
  {
    step: 3,
    label: 'Verify',
  },
  {
    step: 4,
    label: 'Site (Receive)',
  },
  {
    step: 5,
    label: 'Review',
  },
];

const AllocationSendPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [scannedData, setScannedData] = useState<HardwareScannedStateI[]>([]);
  const [registerVerifiedData, setRegisterVerifiedData] = useState<HardwareVerifyCollectionI>();
  const [filtered, setFiltered] = useState<HardwareVerifyCollectionI>();
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);
  const [successData, setSuccessData] = useState();
  const [finalScannedData, setFinalScannedData] = useState<HardwareScannedStateI[]>([]);
  const [site, setSite] = useState<SiteI | undefined>();
  const { openNotification } = useAppNotification();
  const [siteReceiveData, setSiteReceiveData] = useState<SiteReceiveScannedT>();

  useNavigateAway({
    when: finalScannedData.length > 0 || scannedData.length > 0,
    handleProceed() {
      setScannedData([]);
      setFinalScannedData([]);
      setStopDecodingScanner(false);
      setSite(undefined);
    },
  });

  const handleVerify = async () => {
    try {
      const finalData = [...finalScannedData, ...scannedData];
      const params = {
        hardwareList: finalData,
        verifyType: 'allocationSend',
        siteId: site?.id ?? undefined,
      } as {
        hardwareList: HardwareScannedStateI[];
        verifyType: HardwareVerifyTypeI;
        siteId: number;
      };
      setFinalScannedData(finalData);
      setScannedData([]);
      const data = await doVerifyHardware(params);
      if (data) {
        openNotification({
          type: data?.numberOfInvalid ? 'warning' : 'success',
          title: (
            <div>
              <span>You have scanned {} Code(s) that includes:</span>
              <ul className="tw-my-1 tw-px-6 tw-pl-[25px]">
                <li>{data.numberOfHardwareScanned} hardware</li>
                {data?.numberOfInvalid ? <li>{data.numberOfInvalid} invalid code(s)</li> : ''}
              </ul>
            </div>
          ),
          content: data?.numberOfInvalid ? (
            <Text variant="bodyMd">Please remove invalid code(s) before proceeding to next step!</Text>
          ) : (
            ''
          ),
        });
      }
      setRegisterVerifiedData(data);
      setCurrentStep(3);
    } catch (error) {
      console.log('Could not do verify. Please try again');
    }
  };

  const handleVerifyFilter = (values: { serialCode: HardwareScannedStateI['serialCode'] }) => {
    if (values?.serialCode) {
      const hardwareList = registerVerifiedData?.hardwareList?.filter(
        (item: HardwareScannedStateI) => item.serialCode === values.serialCode,
      );
      const newData: any = { ...registerVerifiedData };
      newData.hardwareList = hardwareList;
      setFiltered(newData);
    } else {
      setFiltered(undefined);
    }
  };

  const handleDeleteVerifiedData = (item: HardwareItemI) => {
    const result = handleRemoveVerifiedData(registerVerifiedData, item.serialCode);
    setRegisterVerifiedData(result);
    setFinalScannedData((prevItems) => {
      const index = finalScannedData.findIndex((data) => data.serialCode === item.serialCode);
      if (index !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      }
      return prevItems;
    });
  };

  const handleScanmoreButton = () => {
    setCurrentStep(2);
    setStopDecodingScanner(false);
  };

  const handleDiscard = () => {
    setScannedData([]);
    setFinalScannedData?.([]);
    setSite(undefined);
    setCurrentStep(1);
  };

  const backFunction = () => {
    if (currentStep !== 1) setCurrentStep(currentStep - 1);
    if (currentStep === 1) navigate(-1);
  };

  useEffect(() => {
    const step = progressStepList.find((item) => item.step === currentStep) || { label: '' };
    EAnalytics.trackScanWorkflow(step?.label);
  }, [currentStep]);

  return (
    <div id="pages-hardware-allocations-new-container" className="tw-p-4">
      <SharedHardwareProgressBar
        currentStep={currentStep}
        title="Allocation (Send)"
        stepList={progressStepList}
        backFunction={backFunction}
        pageName="allocationSend"
        isShowWorkflowRequirements={true}
        doNotShowKey="doNotShowSend"
      />
      {currentStep === 3 && (
        <HardwareVerifyFilterForm
          handleVerifyFilter={handleVerifyFilter}
          numberOfHardwareScanned={registerVerifiedData?.numberOfHardwareScanned}
          numberOfInvalid={registerVerifiedData?.numberOfInvalid}
        />
      )}
      {currentStep === 1 && (
        <HardwareChooseSiteStep
          setCurrentStep={setCurrentStep}
          nextStep={2}
          setSite={setSite}
          siteId={site?.id}
          allocationType="send"
        />
      )}
      {currentStep === 2 && (
        <AllocationScanStep
          setCurrentStep={setCurrentStep}
          setScannedData={setScannedData}
          scannedData={scannedData}
          handleVerify={handleVerify}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          handleDiscard={handleDiscard}
          pageName="allocationSend"
          gallery={allocationGallery()}
        />
      )}
      {currentStep === 3 && (
        <HardwareAllocationVerifyStep
          setCurrentStep={setCurrentStep}
          registerVerifiedData={filtered ?? registerVerifiedData}
          handleDeleteVerifiedData={handleDeleteVerifiedData}
          handleScanmoreButton={handleScanmoreButton}
        />
      )}
      {currentStep === 4 && (
        <AllocationScanSiteStep
          setCurrentStep={setCurrentStep}
          setSiteReceiveData={setSiteReceiveData}
          siteReceiveData={siteReceiveData}
        />
      )}
      {currentStep === 5 && (
        <HardwareAllocationReviewStep
          setCurrentStep={setCurrentStep}
          registerVerifiedData={registerVerifiedData}
          allocationType="send"
          setScannedData={setScannedData}
          setSuccessData={setSuccessData}
          successData={successData}
          setFinalScannedData={setFinalScannedData}
          site={site}
          siteReceiveData={siteReceiveData}
        />
      )}
    </div>
  );
};

export default AllocationSendPage;
