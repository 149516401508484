import { Button, Dropdown, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import PlusIcon from '../../svgs/PlusIcon';
import { handleFilterParams } from '../../utils/global';
import { HardwareSearchParamsI } from '../../interfaces/hardware';
import HardwaresFilterForm from '../../components/forms/hardware/Filter';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import { getFillingHistoriesList } from '../../apis/hardwardFilling';
import HardwareFillingCardList from '../../components/card-list/HardwareFilling';
import HardwareFillingTable from '../../components/tables/HardwareFilling';
import Text from '../../components/Text';
import DisplayLastUpdateApp from '../../components/DisplayLastUpdate';
import SizeBox from '../../components/SizeBox';
import EmptyData from '../../components/EmptyData';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import Icon from '../../components/Icon';

const HardwareFillingListPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'hardware-filling');
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');
    const type = urlSearch.get('filter[type]')?.split(',') ?? [];
    const status = urlSearch.get('filter[status]')?.split(',') ?? [];
    const condition = urlSearch.get('filter[condition]')?.split(',') ?? [];
    const siteIds = urlSearch.get('filter[siteIds]')?.split(',') ?? [];
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search,
      filter: { status, type, condition, siteIds },
    } as HardwareSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);
  const {
    data: { fillingHistories = [], total = 0 } = {},
    isFetching,
    refetch: refetchData,
  } = useQuery(['hardware-filling', 'list', queryParams], () => getFillingHistoriesList(queryParams), {
    select({ data: { fillingHistories, total } }) {
      return { fillingHistories, total };
    },
  });

  const currentPage = queryParams?.page ?? 1;
  const pagination: any = {
    total,
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    showTotal: (total: string) => `${total} Items`,
  };

  const setQueryParams = (params: Partial<HardwareSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const handleFilterChange = (values: HardwareSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/hardware-filling/${record?.uuid}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  const onViewReportClick = () => {
    navigate('/fills/report');
  };

  return (
    <Spin spinning={isFetching ?? isRefetchLoading}>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">
        <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
          <Text component="h3" variant="h3">
            Fill
          </Text>
          <div className="tw-flex tw-items-center tw-gap-3">
            <SecondaryButton
              onClick={onViewReportClick}
              label="View Report"
              icon={<Icon name="icon-library_books" size={20} />}
            />
            {access.isCanCreate && currentUser.type !== 'ecoSpiritsAdmin' && (
              <Link to="/hardware-filling/new">
                <PrimaryButton label="Create" icon={<PlusIcon />} />
              </Link>
            )}
          </div>
        </div>
        <HardwaresFilterForm
          queryParams={queryParams}
          onChange={handleFilterChange}
          pageName="fill"
          refetchData={refetchData}
          setIsRefetchLoading={setIsRefetchLoading}
        />
        {!isFetching && fillingHistories.length === 0 && (
          <EmptyData title="No Report" message="All reports within your business will be displayed here." />
        )}
        {!isFetching && fillingHistories.length !== 0 && (
          <>
            <DisplayLastUpdateApp />
            <SizeBox height={8} />
            {!queryParams.viewMode ? (
              <HardwareFillingCardList
                data={fillingHistories}
                pagination={pagination}
                onPaginationChange={handleFilter}
              />
            ) : (
              <HardwareFillingTable
                rowKey="id"
                loading={isFetching}
                pagination={pagination}
                dataSource={fillingHistories}
                withAction={withAction}
                onChange2={handleFilter}
              />
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default HardwareFillingListPage;
