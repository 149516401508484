import { Checkbox, Form } from 'antd';
import React, { useEffect } from 'react';
import PrimaryButton from '../../buttons/PrimaryButton';
// import './FilterContent.scss';
import { UserSearchParamsI } from '../../../interfaces/user';
import { CommonStatus } from '../../CommonStatus';
import SecondaryButton from '../../buttons/SecondaryButton';
import Text from '../../Text';
import SelectAllCheckbox from '../../SelectAllCheckbox';

type FormParams = {
  status: string[];
};

type Props = {
  queryParams: UserSearchParamsI;
  onChange(newParams: Partial<UserSearchParamsI>): void;
};

const statusOptions = ['active', 'nonActive', 'pending'];

const FilterContent: React.FC<Props> = ({ queryParams, onChange }) => {
  const [form] = Form.useForm();

  const handleOnFinish = (values: FormParams) => {
    onChange({ filter: { status: values?.status } });
  };

  const handleResetFilter = () => {
    form.resetFields();
    onChange({ filter: {} });
  };

  useEffect(() => {
    form.setFieldsValue({ status: queryParams.filter?.status });
  }, [queryParams]);

  return (
    <div className="partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content">
            <Form.Item name="status">
              <SelectAllCheckbox form={form} plainOptions={statusOptions} name="status" sectionTitle="Status">
                {statusOptions.map((status) => (
                  <Checkbox key={status} value={status}>
                    <CommonStatus status={status === 'nonActive' ? 'inactive' : status} />
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FilterContent;
