import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const CloseIcon: React.FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 12 12"
      height={`${height ?? 12}px`}
      viewBox="0 0 12 12"
      width={`${width ?? 12}px`}
      fill={color ?? 'currentColor'}
    >
      <g id="close" clipPath="url(#clip0_13424_153523)">
        <path
          id="Vector"
          d="M9.5 3.205L8.795 2.5L6 5.295L3.205 2.5L2.5 3.205L5.295 6L2.5 8.795L3.205 9.5L6 6.705L8.795 9.5L9.5 8.795L6.705 6L9.5 3.205Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13424_153523">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIcon;
