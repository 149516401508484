import { Form, MenuProps, message, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { updateProductTrace, updateProductTraceStatus, useGetProductTrace } from '../../apis/productTraces';
import DetailsTopSection from '../../components/shared/DetailTopSection';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import ProductTraceInformation from '../../components/ProductTrace/Information';
import ProductTraceSituationReport from '../../components/ProductTrace/SituationReport';
import SizeBox from '../../components/SizeBox';
import ProductTraceResources from '../../components/ProductTrace/Resource';
import ProductTraceStatus from '../../components/ProductTrace/Status';
import { ProductTraceI, ProductTraceReportTypeT } from '../../interfaces/productTrace';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import AddAttachmentForm from '../../components/forms/AddAttachmentForm';
import { AttachmentCategories } from '../../constants';
import { useMutation, useQueryClient } from 'react-query';
import ProductTraceUpdateStatus from '../../components/ProductTrace/UpdateStatus';

const SubElement = (status?: ProductTraceReportTypeT) => {
  return (
    <span className="tw-mt-2">
      <ProductTraceStatus status={status ?? 'trace'} />
    </span>
  );
};

const RenderConfirmContent = ({
  data,
  status,
}: {
  data: ProductTraceI | undefined;
  status: ProductTraceReportTypeT;
}) => {
  let title = '';
  if (status === 'onHold') {
    title = '“On Hold"';
  } else if (status === 'productRecall') {
    title = '“Product Trace"';
  } else if (status === 'resolved') {
    title = '“Resolved"';
  }

  let newStatusText = '';
  if (status === 'onHold') {
    newStatusText = '“Product On Hold Notice"';
  } else if (status === 'productRecall') {
    newStatusText = '“Urgent Product Recall Notice"';
  } else if (status === 'resolved') {
    newStatusText = '“Product Check Resolved"';
  }

  const recipients = data?.partners?.reduce((acc, partner) => acc + partner.recipients, 0);

  return (
    <div>
      <Text variant="h3" component="h3">
        Change Status to {title} and send emails to notify the involved Partners?
      </Text>
      <Text variant="bodyLg">
        Once confirm, Product Trace Report’s status will be changed to {title}, and an email of{' '}
        <span className="tw-font-bold">{newStatusText}</span> will be sent to selected partners and recipients involved
        to notify them about their affected hardware:
      </Text>
      <ul className="tw-my-0 tw-pl-6">
        <li>
          <Text variant="bodyLg" className="tw-inline">
            {data?.partners.length} partners
          </Text>
        </li>
        <li>
          <Text variant="bodyLg" className="tw-inline">
            {recipients} recipients
          </Text>
        </li>
      </ul>
    </div>
  );
};

const ProductTraceDetails: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'product-traces');
  const { data, isFetching, refetch } = useGetProductTrace(code);
  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);

  const [form] = Form.useForm();
  const { confirm } = Modal;
  const queryClient = useQueryClient();
  const [isVisibleModalUpload, setIsVisibleModalUpload] = useState<boolean>(false);
  const [isVisibleModalUpdateStatus, setIsVisibleUpdateStatus] = useState<boolean>(false);

  const { mutate: uploadAttachmentMutate } = useMutation(updateProductTrace, {
    onSuccess: async () => {
      message.info('Uploaded attachment!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Adding attachment failed! Please try again');
    },
  });

  const handleCancelUpdateStatus = () => {
    setIsVisibleUpdateStatus(false);
  };

  const handleCancelUploadAttachment = () => {
    setIsVisibleModalUpload(false);
    form.resetFields(['categoryName', 'file']);
  };

  const handleActionsClick = (key: string) => {
    if (key === 'update-status') {
      setIsVisibleUpdateStatus(true);
    }
  };

  const onUpdateStatusCancel = () => {
    setIsVisibleUpdateStatus(false);
  };

  const { mutate: updateStatus } = useMutation(updateProductTraceStatus, {
    onSuccess: async () => {
      message.success('Report’s status changed and an email has been sent to all partners involved to notify.');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Update failed! Please try again');
    },
  });

  const handleUpdateStatus = async (values: { status: ProductTraceReportTypeT }) => {
    setIsVisibleUpdateStatus(false);
    confirm({
      content: <RenderConfirmContent data={data} status={values.status} />,
      onOk() {
        updateStatus({ code, values });
      },
      onCancel() {
        onUpdateStatusCancel();
      },
      okText: 'Confirm',
      cancelText: 'Back',
      wrapClassName: `confirm-content-wrapper`,
      width: 553,
      closable: true,
    });
  };

  const withAction: MenuProps['items'] = [
    access?.isCanUpdateStatus && data?.status !== 'resolved' && data?.status !== 'trace'
      ? {
          key: 'update-status',
          label: (
            <>
              <Text variant="bodyMd" className="tw-flex tw-items-center tw-gap-2">
                <Icon name="icon-radio_button_checked" size={16} />
                <span>Update Status - Send Email</span>
              </Text>
            </>
          ),
        }
      : null,
  ];

  return (
    <Spin spinning={isFetching ?? isRefetchLoading}>
      <div className="tw-p-4">
        <DetailsTopSection
          label={`Product Trace Report  - ${data?.code}`}
          setIsVisibleModalUpload={setIsVisibleModalUpload}
          access={access}
          subElement={SubElement(data?.status)}
          withActions={withAction}
          handleActionsClick={handleActionsClick}
        />
        <ProductTraceInformation data={data} />
        <SizeBox height={24} />
        <ProductTraceSituationReport
          data={data?.situationData}
          emailsSent={data?.emailInfo}
          reportType={data?.status}
          refetch={refetch}
          setIsRefetchLoading={setIsRefetchLoading}
          resolvedUpdatedAt={data?.latestStatusChange ?? data?.updatedAt}
        />
        <ProductTraceResources data={data} />
      </div>
      <Modal
        open={isVisibleModalUpdateStatus}
        onOk={handleCancelUpdateStatus}
        onCancel={handleCancelUpdateStatus}
        footer={null}
        width={517}
      >
        <ProductTraceUpdateStatus data={data} handleSubmit={handleUpdateStatus} onCancel={onUpdateStatusCancel} />
      </Modal>
      <Modal
        open={isVisibleModalUpload}
        onOk={handleCancelUploadAttachment}
        onCancel={handleCancelUploadAttachment}
        footer={null}
        width={560}
      >
        <AddAttachmentForm
          form={form}
          handleCancel={handleCancelUploadAttachment}
          multiple
          categories={AttachmentCategories}
          update={uploadAttachmentMutate}
        />
      </Modal>
    </Spin>
  );
};

export default ProductTraceDetails;
