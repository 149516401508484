import React from 'react';
import { HardwareI } from '../../interfaces/hardware';
import EcoPlantIcon from '../../svgs/hardwareTypes/EcoPlantIcon';
import { CommonStatus } from '../CommonStatus';
import Text from '../Text';
import Icon from '../Icon';
import { DateFormat } from '../../utils/global';
import TooltipIcon from '../buttons/TooltipIcon';

type Props = {
  item?: HardwareI;
};

const EcoPlantCardItem: React.FC<Props> = ({ item }) => {
  return (
    <div className="scan-screen-data">
      <p className="label tw-mb-6">1 ecoPLANT scanned:</p>
      <div className="card-item">
        <div className="tw-flex tw-justify-between tw-items-center">
          <Text variant="inputLabelSm" className="tw-text-grey-700 tw-flex tw-items-center tw-gap-1">
            <EcoPlantIcon width={16} height={16} /> <span>ecoPLANT</span>
          </Text>
          {item?.isManualInput && (
            <TooltipIcon icon="icon-keyboard" text="Manual Input" placement="top" iconClassName={'tw-text-grey-900'} />
          )}
        </div>
        <Text variant="bodyMdB" className="tw-text-primary-900 tw-mb-3 tw-mt-1">{`${item?.serialCode}`}</Text>
        <Text variant="capt1" className="tw-flex tw-items-center tw-gap-[5px] tw-mb-3">
          <Icon name="icon-location_on" size={12} className=" tw-text-grey-700" />
          <span>{item?.site?.legalName}</span>
        </Text>
        <Text variant="capt1" className="tw-flex tw-items-center tw-gap-[5px] tw-mb-3">
          <Icon name="icon-date_range" size={12} className=" tw-text-grey-700" />
          <span>{item?.ecoPlant?.lastFilledDate ? DateFormat(item?.ecoPlant?.lastFilledDate) : ''}</span>
        </Text>
        <p className="status-section tw-gap-1 tw-flex">
          <CommonStatus status={item?.status ?? ''} />
          <CommonStatus status={item?.condition ?? ''} />
        </p>
      </div>
    </div>
  );
};

export default EcoPlantCardItem;
