import React from 'react';
import { AllocationHistoryI } from '../../interfaces/allocationHistory';
import { DateFormat, getCountryFromCode } from '../../utils/global';
import Divider from '../Divider';
import { AllocationTypeComponent } from '../AllocationType';
import DetailsView from '../DetailsView';
import Text from '../Text';
import { handleGetUniqType } from '../../utils/hardware';
import Icon from '../Icon';

type Props = {
  allocationHistory?: AllocationHistoryI;
};

const AllocateeInformation: React.FC<Props> = ({ allocationHistory }) => {
  return (
    <div className="allocation-history-allocatee-information-container details-container" style={{ marginBottom: 16 }}>
      <DetailsView title="Allocation Details">
        <DetailsView.Field label="Allocation Type" value={<AllocationTypeComponent item={allocationHistory} />} />
        <div className="flex-row">
          <DetailsView.Field label="Generated By" value={allocationHistory?.createdBy?.username ?? 'System'} />
          <DetailsView.Field label="Date Created" value={DateFormat(allocationHistory?.createdAt)} />
        </div>
        <Divider />
        {allocationHistory?.type === 'send' && (
          <>
            <DetailsView.Field
              label="Site (Send)"
              value={
                <>
                  <Text variant="bodyMdB" className="!tw-block">
                    {allocationHistory.fromSite?.displayName}
                  </Text>
                  <Text variant="bodyMdB">
                    {[
                      allocationHistory.fromSite?.addressLine1,
                      allocationHistory.fromSite?.addressLine2,
                      allocationHistory.fromSite?.postalCode,
                    ]
                      .filter((item) => item !== null)
                      .join(', ')}{' '}
                    {getCountryFromCode(allocationHistory.fromSite?.country ?? 'SG')},{' '}
                    {allocationHistory.fromSite?.postalCode}
                  </Text>
                </>
              }
            />
            <div className="flex-row">
              <DetailsView.Field
                label="Site (Receive)"
                value={
                  <>
                    <Text variant="bodyMdB" className="!tw-block">
                      {allocationHistory.site?.displayName}
                    </Text>
                    <Text variant="bodyMdB">
                      {[
                        allocationHistory.site?.addressLine1,
                        allocationHistory.site?.addressLine2,
                        allocationHistory.site?.postalCode,
                      ]
                        .filter((item) => item !== null)
                        .join(', ')}{' '}
                      {getCountryFromCode(allocationHistory.site?.country ?? 'SG')},{' '}
                      {allocationHistory.site?.postalCode}
                    </Text>
                  </>
                }
              />
              <DetailsView.Field
                label="Allocation (Receive) Report "
                value={
                  <Text variant="bodyMdB" className="!tw-block">
                    {allocationHistory?.relation?.uuid}
                  </Text>
                }
              />
            </div>
          </>
        )}
        {allocationHistory?.type === 'receive' && (
          <>
            <DetailsView.Field
              label="Site (Receive)"
              value={
                <>
                  <Text variant="bodyMdB" className="!tw-block">
                    {allocationHistory.site?.displayName}
                  </Text>
                  <Text variant="bodyMdB">
                    {[
                      allocationHistory.site?.addressLine1,
                      allocationHistory.site?.addressLine2,
                      allocationHistory.site?.postalCode,
                    ]
                      .filter((item) => item !== null)
                      .join(', ')}{' '}
                    {getCountryFromCode(allocationHistory.site?.country ?? 'SG')}, {allocationHistory.site?.postalCode}
                  </Text>
                </>
              }
            />
            {!allocationHistory?.relations[0]?.fromSite && allocationHistory.fromSite && (
              <div className="flex-row">
                <DetailsView.Field
                  label="Site (Send)"
                  value={
                    <>
                      <Text variant="bodyMdB" className="!tw-block">
                        {allocationHistory.fromSite?.displayName}
                      </Text>
                      <Text variant="bodyMdB">
                        {[
                          allocationHistory.fromSite?.addressLine1,
                          allocationHistory.fromSite?.addressLine2,
                          allocationHistory.fromSite?.postalCode,
                        ]
                          .filter((item) => item !== null)
                          .join(', ')}{' '}
                        {getCountryFromCode(allocationHistory.fromSite?.country ?? 'SG')},{' '}
                        {allocationHistory.fromSite?.postalCode}
                      </Text>
                    </>
                  }
                />
                {allocationHistory.relations?.length > 0 && (
                  <DetailsView.Field
                    label="Allocation (Send) Report "
                    value={allocationHistory.relations.map((item) => (
                      <Text variant="bodyMdB" className="!tw-block tw-mb-1 last:tw-mb-0" key={item.id}>
                        {item?.uuid}
                      </Text>
                    ))}
                  />
                )}
              </div>
            )}
            {allocationHistory?.relations[0]?.fromSite && (
              <div className="flex-row">
                <DetailsView.Field
                  label="Site (Send)"
                  value={
                    <>
                      <Text variant="bodyMdB" className="!tw-block">
                        {allocationHistory.fromSite?.displayName}
                      </Text>
                      {allocationHistory?.relations.map((relation) => (
                        <Text variant="bodyMdB" key={relation.id}>
                          {[
                            relation.fromSite?.addressLine1,
                            relation.fromSite?.addressLine2,
                            relation.fromSite?.postalCode,
                          ]
                            .filter((item) => item !== null)
                            .join(', ')}{' '}
                          {getCountryFromCode(relation.fromSite?.country ?? 'SG')}, {relation.fromSite?.postalCode}
                        </Text>
                      ))}
                    </>
                  }
                />
                {allocationHistory.relations?.length > 0 && (
                  <DetailsView.Field
                    label="Allocation (Send) Report "
                    value={allocationHistory.relations.map((item) => (
                      <Text variant="bodyMdB" className="!tw-block tw-mb-1 last:tw-mb-0" key={item.id}>
                        {item?.uuid}
                      </Text>
                    ))}
                  />
                )}
              </div>
            )}
          </>
        )}
        <Divider />
        <div className="flex-row">
          <DetailsView.Field label="Number of Hardware" value={allocationHistory?.successHardwareCount} />
          <DetailsView.Field
            label="Hardware Type"
            value={handleGetUniqType(allocationHistory?.hardwareList ?? []).map(
              (item: { type: string; count: number }) => (
                <>
                  {item.type.includes('ecoTOTE') && (
                    <span className="tw-flex tw-gap-2 tw-items-center">
                      <Text variant="bodyLgB" className="tw-min-w-[24px]">
                        {item.count}
                      </Text>
                      <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                        <Icon data-testid="icon-eco-tote" name="icon-eco-tote" size={20} /> <span>{item.type}</span>
                      </Text>
                    </span>
                  )}
                  {item.type.includes('ecoPLANT') && (
                    <span className="tw-flex tw-gap-2 tw-items-center">
                      <Text variant="bodyLgB" className="tw-min-w-[24px]">
                        {item.count}
                      </Text>
                      <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                        <Icon name="icon-eco-plant" size={20} /> <span>{item.type}</span>
                      </Text>
                    </span>
                  )}
                  {item.type.includes('SmartPour') && (
                    <span className="tw-flex tw-gap-2 tw-items-center">
                      <Text variant="bodyLgB" className="tw-min-w-[24px]">
                        {item.count}
                      </Text>
                      <Text variant="labelMd" className=" tw-flex tw-items-center tw-gap-0.5">
                        <Icon name="icon-smart-pour" size={20} /> <span>{item.type}</span>
                      </Text>
                    </span>
                  )}
                </>
              ),
            )}
          />
        </div>
      </DetailsView>
    </div>
  );
};

export default AllocateeInformation;
