import { Spin } from 'antd';
import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import DetailsTopSection from '../../components/shared/DetailTopSection';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import TransferInformation from '../../components/Transfer/Information';
import TransferResources from '../../components/Transfer/Resources';
import { useGetTransfer } from '../../apis/transfers';

const TransferDetailsPage: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const { data: transfer, isFetching } = useGetTransfer(code);
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'transfers');

  return (
    <Spin spinning={isFetching}>
      <div className="tw-p-4">
        <DetailsTopSection
          label={`Transfer Report - ${transfer?.code}`}
          access={access}
          isDisplayManageButton={false}
        />
        <TransferInformation data={transfer} />
        <TransferResources data={transfer} />
      </div>
    </Spin>
  );
};

export default TransferDetailsPage;
