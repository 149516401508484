import React, { useMemo } from 'react';
import './AllocationType.scss';
import Icon from './Icon';
import Text from './Text';
import { IconName } from './Icon/iconName';
import { AllocationHistoryI } from '../interfaces/allocationHistory';

interface Props {
  item?: AllocationHistoryI;
}

export const AllocationTypeComponent: React.FC<Props> = ({ item }) => {
  const icon = item?.type === 'send' ? 'icon-outgoing_mail' : 'icon-incoming_mail';
  const color = item?.type === 'send' ? 'tw-text-success-500' : 'tw-text-info-500';
  const label = useMemo(() => {
    if (!item) return '';
    const isSystem = item.createdById === null;
    const action = item.type === 'send' ? 'Sent' : 'Received';
    return `${action} ${isSystem ? '(System)' : ''}`;
  }, [item]);
  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
      <Icon name={icon as IconName} className={`text-xs ${color}`} size={16} data-testid="icon-outgoing_mail" />
      <Text variant="inputLabelSm" className={`flex-1 ${color}`}>
        {label}
      </Text>
    </div>
  );
};
