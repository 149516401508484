import { Divider, Form, Select } from 'antd';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { FleetReportSearchParamsI } from '../../interfaces/fleetReports';
import { getProductsList } from '../../apis/products';
import Text from '../Text';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { getHardwareSitesList } from '../../apis/hardwares';

type FormParams = {
  products: string[];
  sites: string[];
};

type Props = {
  queryParams: FleetReportSearchParamsI;
  onChange(newParams: Partial<FleetReportSearchParamsI>): void;
  setQueryParams: (params: FleetReportSearchParamsI) => void;
};

const ReportFilterContent: React.FC<Props> = ({ queryParams, onChange, setQueryParams }) => {
  const [form] = Form.useForm();

  const { data: { products = [] } = {}, isLoading: isProductLoading } = useQuery(['products', 'list', {}], () =>
    getProductsList(),
  );

  const { data: { sites = [] } = {}, isLoading: isSiteLoading } = useQuery(
    ['sites', 'list', {}],
    () => getHardwareSitesList(),
    {
      select({ data: { sites } }) {
        return { sites };
      },
    },
  );

  const handleOnFinish = (values: FormParams) => {
    const products = values.products?.length > 0 ? values.products?.join(',') : undefined;
    const sites = values.sites?.length > 0 ? values.sites?.join(',') : undefined;
    onChange({
      filter: { ...queryParams.filter, products, sites },
    });
  };

  const handleResetFilter = () => {
    form.resetFields();
    setQueryParams({ ...queryParams, filter: { ...queryParams.filter, products: undefined, sites: undefined } });
  };

  useEffect(() => {
    form.setFieldsValue({
      products: queryParams.filter?.products,
      sites: Array.isArray(queryParams.filter?.sites)
        ? queryParams.filter?.sites?.map((site: string) => parseInt(site))
        : '',
    });
  }, [queryParams, products, sites]);

  return (
    <div className="tw-min-w-[385px]">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content">
            <Text variant="h4" className="tw-mb-3">
              Product
            </Text>
            <Form.Item name="products">
              <Select
                placeholder="Select products"
                showSearch
                optionFilterProp="label"
                allowClear
                mode="multiple"
                loading={isProductLoading}
              >
                {products
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((product) => (
                    <Select.Option key={product.sku} value={product.sku} label={product.name}>
                      {product.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Divider />
            <Text variant="h4" className="tw-mb-3">
              Site
            </Text>
            <Form.Item name="sites">
              <Select
                placeholder="Select sites"
                showSearch
                optionFilterProp="label"
                allowClear
                mode="multiple"
                loading={isSiteLoading}
              >
                {sites
                  .slice()
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))
                  .map((item) => (
                    <Select.Option key={item.id} value={item.id} label={item.displayName}>
                      {item.displayName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ReportFilterContent;
