import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { CommonStatus } from '../CommonStatus';
import { DateFormat } from '../../utils/global';
import { renderType } from '../../utils/reports';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const InventoryReportTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS: any = [
    {
      title: 'hardware code',
      dataIndex: 'Serial Code',
      width: 180,
      fixed: 'left',
      render: (serialCode: string) => {
        return <span>{serialCode}</span>;
      },
    },
    {
      title: 'Type',
      width: 150,
      ellipsis: true,
      render: (record: any) => {
        return renderType(record, record['Hardware Type']);
      },
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      width: 150,
      ellipsis: true,
      render: (status: string) => {
        return <CommonStatus status={status} />;
      },
    },
    {
      title: 'Condition',
      dataIndex: 'Condition',
      width: 150,
      ellipsis: true,
      render: (condition: string) => {
        return <CommonStatus status={condition} />;
      },
    },
    {
      title: 'Owner',
      dataIndex: 'Hardware Owner',
      width: 180,
      ellipsis: true,
    },
    {
      title: 'Allocatee',
      dataIndex: 'Hardware Allocatee',
      width: 180,
      ellipsis: true,
    },
    {
      title: 'Site',
      dataIndex: 'Site',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Site Type',
      dataIndex: 'Site Type',
      width: 150,
      ellipsis: true,
      render: (siteType: string) => {
        return <span className="text-capitalize">{siteType}</span>;
      },
    },
    {
      title: 'Country',
      dataIndex: 'Country',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Address 1',
      dataIndex: 'Address Line 1',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Address 2',
      dataIndex: 'Address Line 2',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Address 3',
      dataIndex: 'Address Line 3',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Postal',
      dataIndex: 'Postal',
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Brand',
      dataIndex: 'Brand',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Product',
      dataIndex: 'Product',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'SKU',
      dataIndex: 'SKU',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'ABV',
      dataIndex: 'ABV',
      width: 80,
      ellipsis: true,
    },
    {
      title: 'Product Owner',
      dataIndex: 'Product Owner',
      width: 180,
      ellipsis: true,
    },
    {
      title: 'Last Interaction',
      dataIndex: 'Last Interaction',
      width: 180,
      ellipsis: true,
      render: (lastInteraction: string) => {
        return DateFormat(lastInteraction);
      },
    },
    {
      title: 'Days Since Last Interaction',
      dataIndex: 'Days Since Last Interaction',
      width: 150,
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'left' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default InventoryReportTable;
