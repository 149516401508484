import { Menu, MenuProps } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Menu.scss';
import { CurrentUserI } from '../../interfaces/auth';
import { getAccessControl } from '../../utils/role';
import Icon from '../Icon';
import useNavigateAway from '../../hooks/useNavigateAway';

type Props = {
  currentUser: CurrentUserI;
};

const getSelectedKey = (pathname: string, currentUrl: string | undefined) => {
  const path = currentUrl ?? pathname;
  if (path.includes('/platform/partners')) return '/platform/partners';
  if (path.includes('/platform/sites')) return '/platform/sites';
  if (path.includes('/platform/hardware')) return '/platform/hardware';
  if (path.includes('/platform/products')) return '/platform/products';
  if (path.includes('/sites')) return '/sites';
  if (path.includes('/users')) return '/users';
  if (path.includes('/roles')) return '/roles';
  if (path.includes('/performance/site')) return '/performance/site';
  if (path.includes('/products')) return '/products';
  if (path.includes('/products/new')) return '/products/new';
  if (path.includes('/partner-profile')) return '/partner-profile';
  if (path.includes('/tracking')) return '/tracking';
  if (path.includes('/hardware-filling') || path.includes('/fills/report')) return '/hardware-filling';
  if (path.includes('/hardware-registrations') || path.includes('registration-histories'))
    return '/hardware-registrations';
  if (
    path.includes('/hardware-allocations') ||
    path.includes('allocation-histories') ||
    path.includes('deliveries/report')
  )
    return '/hardware-allocations';
  if (path.includes('/hardware-depletions') || path.includes('/depletions/report')) return '/hardware-depletions';
  if (
    path.includes('/hardware-groups') ||
    path.includes('/tracking/groups') ||
    path.includes('/fault-reports') ||
    path.includes('/inventories/report') ||
    path.includes('/dispenses/report')
  )
    return '/tracking';
  if (path.includes('/product-groups/new')) return '/products';
  if (path.includes('/email-lists')) return '/email-lists';
  if (path.includes('/transfers')) return '/transfers';
  if (path.includes('/configurations')) return '/configurations';
  if (path.includes('/performance/product')) return '/performance/site';
  if (path.includes('/performance/hardware')) return '/performance/site';
  if (path.includes('/product-traces')) return '/product-traces';
  if (path.includes('/reports')) return '/reports';
  return '/';
};

const getOpenKey = (key: string) => {
  if (key === '/hardware-registrations' || key === '/registration-histories') return '/hardware-registrations';
  if (key === '/hardware-allocations' || key === '/allocation-histories') return '/hardware-allocations';
  if (
    key === '/hardware-groups' ||
    key === '/tracking/groups' ||
    key === '/tracking/groups' ||
    key === 'fault-reports' ||
    key === 'inventories/report' ||
    key === 'dispenses/report'
  )
    return '/tracking';
  if (key === '/product-groups/new') return '/products';
  if (key === '/performance/site') return '/performance/site';
  if (key === '/performance/product') return '/performance/site';
  if (key === '/performance/hardware') return '/performance/site';
  if (key === '/product-traces') return '/product-traces';
  if (key === '/deliveries/report') return '/allocation-histories';
  if (key === '/fills/report') return '/hardware-filling';
  if (key === '/depletions/report') return '/hardware-depletions';
  if (key === '/reports') return '/reports';
  return key;
};

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label: !children ? <Link to={`${key}`}>{label}</Link> : label,
  } as MenuItem;
}

const MenuLayout: React.FC<Props> = ({ currentUser }) => {
  const { pathname } = useLocation();
  const { currentUrl } = useNavigateAway({});
  const [openKey, setOpenkey] = useState<string>('');
  const selectedKey = useMemo(() => getSelectedKey(pathname, currentUrl), [pathname, currentUrl]);

  const onSelect: MenuProps['onSelect'] = ({ key }) => setOpenkey(getOpenKey(key));

  const items: MenuItem[] = [
    // getAccessControl(currentUser, 'emails')?.includes('R')
    //   ? getItem('Email List', '/', <Icon name="icon-mail_outline" size={20} />)
    //   : null,
    getAccessControl(currentUser, 'platform/partners').length > 0
      ? getItem('PLATFORM MANAGEMENT', 'platformManagement', null, [])
      : null,
    getAccessControl(currentUser, 'platform/partners')?.includes('R')
      ? getItem('All Partners', '/platform/partners', <Icon name="icon-supervised_user_circle" size={20} />)
      : null,
    getAccessControl(currentUser, 'platform/sites')?.includes('R')
      ? getItem('All Sites', '/platform/sites', <Icon name="icon-add_location_alt" size={20} />)
      : null,
    getAccessControl(currentUser, 'platform/hardware')?.includes('R')
      ? getItem('All Hardware', '/platform/hardware', <Icon name="icon-all_inbox" size={20} />)
      : null,
    getAccessControl(currentUser, 'platform/products')?.includes('R')
      ? getItem('All Products', '/platform/products', <Icon name="icon-archive" size={20} />)
      : null,
    getAccessControl(currentUser, 'performance/site')?.includes('R') ||
    getAccessControl(currentUser, 'performance/product')?.includes('R') ||
    getAccessControl(currentUser, 'performance/hardware')?.includes('R')
      ? getItem('DASHBOARD MANAGEMENT', 'dashboardManagement', null, [])
      : null,
    getAccessControl(currentUser, 'performance/site')?.includes('R') ||
    getAccessControl(currentUser, 'performance/product')?.includes('R') ||
    getAccessControl(currentUser, 'performance/hardware')?.includes('R')
      ? getItem('Performance', '/performance/site', <Icon name="icon-dashboard" size={20} />)
      : null,
    getAccessControl(currentUser, 'reports')?.includes('R')
      ? getItem('Reports', '/reports', <Icon name="icon-library_books" size={20} />)
      : null,
    getItem('PARTNER MANAGEMENT', 'partnerManagement', null, []),
    getAccessControl(currentUser, 'partners')?.includes('R')
      ? getItem('Profile', `/partner-profile`, <Icon name="icon-person_pin" size={20} />)
      : null,

    getAccessControl(currentUser, 'users')?.includes('R')
      ? getItem('Users', '/users', <Icon name="icon-person_outline" size={20} />)
      : null,
    getAccessControl(currentUser, 'roles')?.includes('R')
      ? getItem('Roles', '/roles', <Icon name="icon-rule" size={20} />)
      : null,
    getAccessControl(currentUser, 'sites')?.includes('R')
      ? getItem('Sites', '/sites', <Icon name="icon-location_on" size={20} />)
      : null,
    getItem('HARDWARE MANAGEMENT', 'hardwareManagement', null, []),
    getAccessControl(currentUser, 'tracking')?.includes('R')
      ? getItem('Tracking', '/tracking', <Icon name="icon-track_changes" size={20} />)
      : null,
    getAccessControl(currentUser, 'hardware-registrations')?.includes('R')
      ? getItem('Registration', '/hardware-registrations', <Icon name="icon-app_registration" size={20} />)
      : null,
    getAccessControl(currentUser, 'hardware-allocations')?.includes('R')
      ? getItem('Allocation', '/hardware-allocations', <Icon name="icon-switch_left" size={20} />)
      : null,
    getAccessControl(currentUser, 'transfers')?.includes('R')
      ? getItem('Transfer', '/transfers', <Icon name="icon-compare_arrows" size={20} />)
      : null,
    getAccessControl(currentUser, 'configurations')?.includes('R')
      ? getItem('Configuration', '/configurations', <Icon name="icon-build_circle" size={20} />)
      : null,
    // getAccessControl(currentUser, 'hardware-allocations')?.includes('R')
    //   ? getItem('Pairing', '/', <Icon name="icon-device_hub" size={20} />)
    //   : null,
    getAccessControl(currentUser, 'hardware-filling')?.includes('R')
      ? getItem('Fill', '/hardware-filling', <Icon name="icon-local_drink_filled" size={20} />)
      : null,
    getAccessControl(currentUser, 'hardware-depletions')?.includes('R')
      ? getItem('Deplete', '/hardware-depletions', <Icon name="icon-local_drink" size={20} />)
      : null,
    // getAccessControl(currentUser, 'hardware-depletions')?.includes('R')
    //   ? getItem('Identify', '/', <Icon name="icon-local_drink" size={20} />)
    //   : null,
    getAccessControl(currentUser, 'products')?.includes('R')
      ? getItem('PRODUCT MANAGEMENT', 'productManagement', null, [])
      : null,
    getAccessControl(currentUser, 'products')?.includes('R')
      ? getItem('Create', '/products/new', <Icon name="icon-add" size={20} />)
      : null,
    getAccessControl(currentUser, 'products')?.includes('R')
      ? getItem('Manage', '/products', <Icon name="icon-archive" size={20} />)
      : null,
    getAccessControl(currentUser, 'product-traces')?.includes('R')
      ? getItem('Trace', '/product-traces', <Icon name="icon-location_searching" size={20} />)
      : null,
    getAccessControl(currentUser, 'email-lists')?.includes('R')
      ? getItem('NOTIFICATION MANAGEMENT', 'notificationManagement', null, [])
      : null,
    getAccessControl(currentUser, 'email-lists')?.includes('R')
      ? getItem('Email', '/email-lists', <Icon name="icon-mail" size={20} />)
      : null,
  ];

  return (
    <Menu
      className="main-menu tw-mb-6"
      triggerSubMenuAction="hover"
      defaultSelectedKeys={[selectedKey]}
      onSelect={onSelect}
      defaultOpenKeys={[openKey]}
      mode="inline"
      items={items}
      selectedKeys={[selectedKey]}
    />
  );
};

export default MenuLayout;
