import { Form, message, Spin } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';
import { doUpdateProductGroup, useGetProductGroup } from '../../apis/products';
import { ProductGroupI } from '../../interfaces/product';
import ProductGroupForm from '../../components/ProductGroup/GroupForm';

const UpdateProductGroupPage: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [isCodeDuplicate, setIsCodeDuplicate] = useState<boolean>(false);

  const { code } = useParams<{ code: string }>();
  const { data, isFetching } = useGetProductGroup(code);

  const { mutateAsync: updateMutate, isLoading: isPageLoading } = useMutation(doUpdateProductGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['products', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: [`products/groups/${code}`, undefined],
      });
      message.info('Update successfully!');
      navigate(`/products`, { replace: true });
    },
    onError: async (error) => {
      if (error === 'code exists') {
        setIsCodeDuplicate(true);
      } else {
        message.error('Update failed! Please try again');
      }
    },
  });

  const handleSubmit = async (values: ProductGroupI) => {
    updateMutate({ code, data: values });
  };

  return (
    <Spin spinning={isFetching ?? isPageLoading}>
      <div className="tw-p-4">
        <CreateUpdateTopSection label="Edit Grouping" labelButton="Update" form={form} />
        <ProductGroupForm
          form={form}
          handleSubmit={handleSubmit}
          group={data}
          groupId={code}
          isCodeDuplicate={isCodeDuplicate}
        />
      </div>
    </Spin>
  );
};

export default UpdateProductGroupPage;
