import { Button, Dropdown, Form, Input, MenuProps, Modal } from 'antd';
import React, { useState } from 'react';
import Icon from '../Icon';
import IconButton from '../buttons/IconButton';
import FilterIcon from '../../svgs/FilterIcon';
import SearchInputIcon from '../../svgs/SearchInputIcon';
import { ScanCode } from '../shared/Scanner';
import Text from '../Text';
import ProductTraceLotNumbersFilterContent from './FilterContent';
import DropdownApp from '../Dropdown';
import { renderSortList } from '../../utils/sort';
import { ProductTraceScanProductSearchParamsI } from '../../interfaces/productTrace';

type Props = {
  queryParams: ProductTraceScanProductSearchParamsI;
  handleFilter: (values: { lotNumber: string }) => void;
  handleDropdownFilter: (status: string) => void;
  handleFilterChange: (queryParams: ProductTraceScanProductSearchParamsI) => void;
};

const LotNumbersFilterForm: React.FC<Props> = ({
  queryParams,
  handleFilter,
  handleDropdownFilter,
  handleFilterChange,
}) => {
  const [form] = Form.useForm();
  const [isScanVisible, setIsScanVisible] = useState<boolean>(false);
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);

  const handleScanCancel = () => {
    setTimeout(() => {
      setStopDecodingScanner(true);
    }, 700);
    setTimeout(() => {
      setIsScanVisible(false);
    }, 800);
  };

  const handleCameraClick = () => {
    setStopDecodingScanner(false);
    setIsScanVisible(true);
  };

  const handleScanOrManualSubmit = (result: { text: string }) => {
    form.setFieldsValue({ lotNumber: result.text });
    handleFilter({ lotNumber: result.text });
    handleScanCancel();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      handleFilter({ lotNumber: '' });
    }

    handleFilter({ lotNumber: e.target.value });
  };

  const handleDropdownItemClick: MenuProps['onClick'] = (e: any) => {
    const { key } = e;
    const [orderBy, type] = key.split('-');
    handleFilterChange({
      orderBy: orderBy as ProductTraceScanProductSearchParamsI['orderBy'],
      orderType: type as ProductTraceScanProductSearchParamsI['orderType'],
    });
  };

  const items: MenuProps['items'] = renderSortList(queryParams, 'lotNumber');

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  const CameraIcon = () => {
    return (
      <Button className="tw-border-none tw-p-0 tw-gap-0 tw-text-primary-500" onClick={handleCameraClick}>
        <Icon name="icon-camera_alt" size={18} />{' '}
      </Button>
    );
  };

  return (
    <div className="form-wrap tw-w-[500px] tw-my-0 tw-mx-auto tw-mb-6 sm:tw-w-full">
      <Form form={form} requiredMark={false} colon={false} onFinish={handleFilter} scrollToFirstError>
        <div className="form-container">
          <div className="form-content !tw-mt-0">
            <div className="tw-flex tw-items-center tw-justify-center tw-w-[500px] sm:tw-w-full tw-gap-1">
              <Form.Item name="lotNumber" className="search-box tw-w-full !tw-mb-0">
                <Input
                  placeholder="Search for Lot Number"
                  suffix={<CameraIcon />}
                  prefix={<SearchInputIcon />}
                  allowClear
                  onChange={onSearchChange}
                />
              </Form.Item>
              <DropdownApp
                icon={<Icon name="icon-sort" size={22} />}
                menu={menuDropdownProps}
                size="small"
                placement="bottomRight"
                type="secondary"
              />
              <Dropdown
                className="filter-dropdown-button"
                dropdownRender={() => <ProductTraceLotNumbersFilterContent handleFilter={handleDropdownFilter} />}
                trigger={['click']}
                overlayClassName="filter-drowdown"
                placement="bottomLeft"
              >
                <IconButton icon={<FilterIcon height={20} />} />
              </Dropdown>
            </div>
          </div>
        </div>
      </Form>
      <Modal open={isScanVisible} onOk={handleScanCancel} onCancel={handleScanCancel} footer={null} width={744}>
        <div className="tw-py-5 tw-px-2">
          <Text variant="h3" component="h3" className="tw-mb-6 tw-w-full tw-text-center">
            {' '}
            Scan Lot Number{' '}
          </Text>
          <Text variant="bodyLg" component="p" className="tw-mb-6 tw-w-full tw-text-center">
            {' '}
            Lot Number can be found on top or on the side of the Barrel. Refer to Barrel/IBC.{' '}
          </Text>
          <ScanCode
            stopDecodingScanner={stopDecodingScanner}
            setStopDecodingScanner={setStopDecodingScanner}
            handleScanOrManualSubmit={handleScanOrManualSubmit}
          />
        </div>
      </Modal>
    </div>
  );
};

export default LotNumbersFilterForm;
