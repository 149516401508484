import { BaseResponse } from '../interfaces';
import { PartnerCollectionI, PartnerI, PartnerSearchParams } from '../interfaces/partner';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetPartner = (id: number) => {
  const { data: response, error, isFetching } = useFetch<any>(`platform/partners/${id}`);
  const data = response?.data;
  return { data, error, isFetching };
};

export const getPartnersList = async (params?: PartnerSearchParams) => {
  const { data: response } = await api.get<BaseResponse<PartnerCollectionI>>(
    `platform/partners?${handleFilterParams(params)}`,
  );
  return response;
};

export const getPartnerDetails = async (id?: number) => {
  const { data: response } = await api.get<BaseResponse<any>>(`platform/partners/${id}`);
  return response?.data;
};

export const createPartner = async (values: PartnerI) => {
  const { data: response }: any = await api.post('platform/partners', values);
  return response.data;
};

export const updatePartner = async ({ id, ...values }: { id: string; values: PartnerI }) => {
  const { data: response }: any = await api.patch(`platform/partners/${id}`, values);
  return response.data;
};

export const deletePartner = async (id: number) => {
  const { data: response }: any = await api.delete(`platform/partners/${id}`);
  return response.data;
};

export const updatePartnerStatus = async (params: { id: number; status: string }) => {
  const { data: response }: any = await api.patch(`platform/partners/${params.id}/status`, { status: params.status });
  return response.data;
};

export const impersonatePartner = async (emailUsername: string) => {
  const { data: response } = await api.post<
    BaseResponse<{
      authToken: string;
      renewToken: string;
      expireTime: number;
      renewTokenExpireTime: number;
    }>
  >(`platform/impersonation`, { emailUsername });
  return response.data;
};

export const impersonationSignOut = async () => {
  const { data: response } = await api.delete<
    BaseResponse<{
      authToken: string;
      renewToken: string;
      expireTime: number;
      renewTokenExpireTime: number;
    }>
  >(`sign-out`);
  return response.data;
};

export const doEmailRetrigger = async (email: string) => {
  const { data: response }: any = await api.post('resend-activation', { emailUsername: email });
  return response.data;
};

export const requestPartnerCreation = async (values: PartnerI) => {
  const { data: response }: any = await api.post('partner', values);
  return response.data;
};

export const doPartnerCreationReject = async (params: { id: number; body: { reason: string } }) => {
  const { data: response }: any = await api.post(`platform/partners/${params.id}/reject`, params.body);
  return response.data;
};

export const doPartnerCreationApprove = async (id: number) => {
  const { data: response }: any = await api.post(`platform/partners/${id}/approve`, {});
  return response.data;
};

export const getPartnerAvatar = async (id: number) => {
  const { data }: any = await api.getImage<any>(`partners/${id}/logo`);
  return { data };
};
