import React, { useEffect, useState } from 'react';
import './ProgressBar.scss';
import { HardwareProgressStepListI } from '../../../interfaces';
import StickIcon from '../../../svgs/StickIcon';
import BackLink from '../../buttons/BackLink';
import Text from '../../Text';
import { Modal } from 'antd';
import IconButton from '../../buttons/IconButton';
import Icon from '../../Icon';
import { CheckboxProps } from 'antd/lib';
import SendWorkflowRequirements from '../../HardwareAllocation/SendWorkflowRequirements';
import ReceiveWorkflowRequirements from '../../HardwareAllocation/ReceiveWorkflowRequirements';
import RegistrationWorkflowRequirements from '../../Hardware/RegistrationWorkflowRequirements';
import FillingWorkflowRequirements from '../../HardwareFilling/FillingWorkflowRequirements';
import DepletionWorkflowRequirements from '../../HardwareDepletion/WorkflowRequirements';
import TransferWorkflowRequirements from '../../Transfer/WorkflowRequirements';
import GroupWorkflowRequirements from '../../Hardware/GroupWorkflowRequirements';

type Props = {
  currentStep: number;
  title: string;
  stepList: HardwareProgressStepListI[];
  backTo?: string;
  stepName?: string;
  pageName?: string;
  isShowWorkflowRequirements?: boolean;
  doNotShowKey?: string;
  backFunction?: () => void;
};

const SharedHardwareProgressBar: React.FC<Props> = ({
  currentStep,
  title,
  stepList,
  stepName,
  pageName,
  isShowWorkflowRequirements = false,
  doNotShowKey,
  backFunction,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false);

  useEffect(() => {
    const doNotShow = localStorage.getItem(doNotShowKey ?? '');
    if (!doNotShow && isShowWorkflowRequirements) {
      setIsModalVisible(true);
    }
  }, []);

  const handleOk = () => {
    if (doNotShowAgain) {
      localStorage.setItem(doNotShowKey ?? '', 'true');
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCheckboxChange: CheckboxProps['onChange'] = (e) => {
    setDoNotShowAgain(e.target.checked);
  };

  return (
    <div className="shared-hardware-progress-bar">
      <BackLink backFunction={backFunction} />
      <h2 className="tw-relative">
        {title}
        {isShowWorkflowRequirements && (
          <IconButton
            icon={<Icon name="icon-info" />}
            onClick={() => setIsModalVisible(true)}
            className="tw-absolute -tw-top-6 tw-right-3 !tw-border-none !tw-bg-transparent !tw-p-0 tw-m-0 tw-text-black tw-shadow-none tw-cursor-pointer"
          />
        )}
      </h2>
      <div className="status-bar">
        <ul>
          {stepList.map((item: HardwareProgressStepListI) => (
            <li
              key={item.step}
              className={`step ${item.disabled ? 'disabled' : ''} ${currentStep === item.step ? 'active' : 'inactive'} ${currentStep > item.step ? 'completed' : ''} for${stepList.length}step`}
            >
              <span className="number">{currentStep > item.step ? <StickIcon /> : item.step}</span>
              <span className="text">{item.label}</span>
            </li>
          ))}
        </ul>
      </div>
      {stepName && (
        <div className="tw-text-center tw-mt-3">
          <Text variant="inputLabelLg">{stepName}</Text>
        </div>
      )}
      <Modal open={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={595} footer={null}>
        {pageName === 'allocationSend' && (
          <SendWorkflowRequirements handleOk={handleOk} handleCheckboxChange={handleCheckboxChange} />
        )}
        {pageName === 'allocationReceive' && (
          <ReceiveWorkflowRequirements handleOk={handleOk} handleCheckboxChange={handleCheckboxChange} />
        )}
        {pageName === 'registration' && (
          <RegistrationWorkflowRequirements handleOk={handleOk} handleCheckboxChange={handleCheckboxChange} />
        )}
        {pageName === 'filling' && (
          <FillingWorkflowRequirements handleOk={handleOk} handleCheckboxChange={handleCheckboxChange} />
        )}
        {pageName === 'depletion' && (
          <DepletionWorkflowRequirements handleOk={handleOk} handleCheckboxChange={handleCheckboxChange} />
        )}
        {pageName === 'transfer' && (
          <TransferWorkflowRequirements handleOk={handleOk} handleCheckboxChange={handleCheckboxChange} />
        )}
        {pageName === 'hardwareGroup' && (
          <GroupWorkflowRequirements handleOk={handleOk} handleCheckboxChange={handleCheckboxChange} />
        )}
      </Modal>
    </div>
  );
};

export default SharedHardwareProgressBar;
