import React from 'react';
import { Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import './HardwareCardItem.scss';
import { HardwareFillingHistoryI } from '../../interfaces/hardwareFilling';
import { DateFormat } from '../../utils/global';
import Text from '../Text';
import Icon from '../Icon';
import { IconName } from '../Icon/iconName';
import TooltipIcon from '../buttons/TooltipIcon';

type Props = {
  item: HardwareFillingHistoryI;
};
const FillingCardItem: React.FC<Props> = ({ item }) => {
  const navitate = useNavigate();

  const handleCardClick = (item: HardwareFillingHistoryI) => {
    navitate(`/hardware-filling/${item.uuid}`);
  };

  const renderField = (props: { icon: IconName; value: string }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <div className="hardware-card-item card-item">
      <button className="item" onClick={() => handleCardClick(item)}>
        {item?.remarks && (
          <TooltipIcon
            icon="icon-text_snippet"
            text="Remarks"
            className="btn-more-action"
            iconClassName="tw-text-grey-800"
          />
        )}
        <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
          <Text variant="inputLabelSm" className="tw-text-grey-700">
            Fill Report
          </Text>
          <Text variant="bodyLgB" className="tw-text-primary-800">
            {item.uuid}
          </Text>
          <div className="tw-flex tw-flex-col tw-gap-2">
            {renderField({ icon: 'icon-eco-tote', value: `${item?.hardwareList?.length ?? 0} ecoTOTEs` })}
            {renderField({ icon: 'icon-receipt', value: item?.product?.sku ?? '' })}
            {renderField({ icon: 'icon-location_on', value: item?.site?.displayName })}
          </div>
          <Divider className="tw-m-0" />
          {renderField({ icon: 'icon-calendar_today', value: `${DateFormat(item?.createdAt)}` })}
        </div>
      </button>
    </div>
  );
};

export default FillingCardItem;
