import { Button, Dropdown, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import PlusIcon from '../../svgs/PlusIcon';
import { getUsersList } from '../../apis/users';
import UsersFilterForm from '../../components/forms/user/Filter';
import UsersTable from '../../components/tables/Users';
import { UserSearchParamsI } from '../../interfaces/user';
import { handleFilterParams } from '../../utils/global';
import { CurrentUserI } from '../../interfaces/auth';
import UsersCardList from '../../components/card-list/Users';
import { handleAccessControl } from '../../utils/role';
import Text from '../../components/Text';
import DisplayLastUpdateApp from '../../components/DisplayLastUpdate';
import SizeBox from '../../components/SizeBox';
import EmptyData from '../../components/EmptyData';

const UsersPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'users');
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const searchUsername = urlSearch.get('search[username]');
    const searchEmail = urlSearch.get('search[email]');
    const status = urlSearch.get('filter[status]')?.split(',') ?? [];
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      filter: { status },
      search: { username: searchUsername, email: searchEmail },
    } as UserSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);
  const {
    data: { users = [], total = 0 } = {},
    isFetching,
    refetch: refetchData,
  } = useQuery(['users', 'list', queryParams], () => getUsersList(queryParams), {
    select({ data: { users, total } }) {
      return { users, total };
    },
  });

  const currentPage = queryParams?.page;

  const pagination: any = {
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    total: total,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const setQueryParams = (params: Partial<UserSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: UserSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/users/${record?.id}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
              access.isCanUpdate && currentUser.type !== 'ecoSpiritsAdmin'
                ? {
                    key: '2',
                    label: (
                      <Link to={`/users/${record?.id}/edit`}>
                        <Button className="btn-table-action-primary" type="link">
                          Edit
                        </Button>
                      </Link>
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <Spin spinning={isFetching ?? isRefetchLoading}>
      <div className="users-container tw-flex tw-flex-col tw-gap-4 tw-p-4">
        <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
          <Text component="h3" variant="h3">
            User List
          </Text>
          <div className="pagee-header-actions">
            {access.isCanCreate && currentUser.type !== 'ecoSpiritsAdmin' && (
              <Link to="/users/new">
                <PrimaryButton label="Add User" icon={<PlusIcon />} />
              </Link>
            )}
          </div>
        </div>
        <UsersFilterForm
          queryParams={queryParams}
          onChange={handleFilterChange}
          refetchData={refetchData}
          setIsRefetchLoading={setIsRefetchLoading}
        />
        {!isFetching && users?.length === 0 && (
          <EmptyData title="No User" message="All users within your business will be displayed here." />
        )}
        {!isFetching && users?.length !== 0 && (
          <>
            <DisplayLastUpdateApp />
            <SizeBox height={8} />
            {!queryParams.viewMode ? (
              <UsersCardList
                access={access}
                data={users || []}
                pagination={pagination}
                onPaginationChange={handleFilter}
              />
            ) : (
              <UsersTable
                rowKey="id"
                loading={isFetching}
                pagination={pagination}
                dataSource={users}
                withAction={withAction}
                onChange2={handleFilter}
              />
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default UsersPage;
