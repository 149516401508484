import { Dropdown, MenuProps, Modal, Space, Tabs, TabsProps } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link, useLocation, useOutletContext } from 'react-router-dom';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import PlusIcon from '../../svgs/PlusIcon';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import Icon from '../../components/Icon';
import Text from '../../components/Text';
import { PAGE_SIZE } from '../../constants';
import { useQuery } from 'react-query';
import PresetsTabPage from './PresetsTab';
import { ConfigReportSearchParamsI, ConfigurationSearchParamsI } from '../../interfaces/configuration';
import { getAppliedConfigsList, getConfigurationsList } from '../../apis/configurations';
import AppliedConfigsTab from './AppliedConfigsTab';
import SmartPourImage from '../../images/smartpour.png';
import SmartPOURIcon from '../../svgs/hardwareTypes/SmartPOURIcon';

const createTabItem = (key: string, label: string, children: React.ReactNode) => ({
  key,
  label: (
    <Text variant="labelLg" className="tab-title !tw-font-normal">
      <span>{label}</span>
    </Text>
  ),
  children,
});

const ConfigurationsPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'configurations');
  const [isChooseTypeModal, setIsChooseTypeModal] = useState<boolean>(false);
  const location = useLocation();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const configQueryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const searchValue = urlSearch.get('search');
    const filterHardwareType = urlSearch.get('filter[hardwareType]')?.split(',') ?? [];
    const filterDisplayUnit = urlSearch.get('filter[displayUnit]')?.split(',') ?? [];
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      filter: { hardwareType: filterHardwareType, displayUnit: filterDisplayUnit },
      search: searchValue,
    } as ConfigurationSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const [isPresetRefetchLoading, setIsPresetRefetchLoading] = useState<boolean>(false);
  const [isReportRefetchLoading, setIsReportRefetchLoading] = useState<boolean>(false);

  const {
    data: { hardwareConfigs = [], total: presetTotal = 0 } = {},
    isFetching,
    refetch: configRefetchData,
  } = useQuery(['configurations', 'list', configQueryParams], () => getConfigurationsList(configQueryParams));

  const applyConfigQueryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const searchValue = urlSearch.get('search');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search: searchValue,
    } as ConfigReportSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const {
    data: { reports = [], total: reportTotal = 0 } = {},
    isFetching: isReportLoading,
    refetch: reportRefetchData,
  } = useQuery(['reports', 'list', applyConfigQueryParams], () => getAppliedConfigsList(applyConfigQueryParams));

  const tabItems: TabsProps['items'] = [
    ...(access.isCanRead
      ? [
          createTabItem(
            '1',
            `Configuration Presets (${presetTotal ?? 0})`,
            <PresetsTabPage
              queryParams={configQueryParams}
              hardwareConfigs={hardwareConfigs}
              total={presetTotal}
              isLoading={isFetching}
              refetchData={configRefetchData}
              setIsRefetchLoading={setIsPresetRefetchLoading}
              isRefetchLoading={isPresetRefetchLoading}
            />,
          ),
        ]
      : []),
    ...(access.isCanRead
      ? [
          createTabItem(
            '2',
            `Apply Configuration Reports (${reportTotal ?? 0})`,
            <AppliedConfigsTab
              queryParams={applyConfigQueryParams}
              reports={reports}
              total={reportTotal}
              isLoading={isReportLoading}
              refetchData={reportRefetchData}
              setIsRefetchLoading={setIsReportRefetchLoading}
              isRefetchLoading={isReportRefetchLoading}
            />,
          ),
        ]
      : []),
  ];

  const actionItems: MenuProps['items'] = [
    access.isCanCreate
      ? {
          label: (
            <button
              onClick={() => setIsChooseTypeModal(true)}
              className="tw-bg-white hover:tw-bg-primary-50 tw-border-none tw-flex tw-items-center tw-gap-3 tw-p-0"
            >
              <Icon name="icon-add_box" />{' '}
              <Text variant="bodyMd" className="tw-font-normal">
                Create Configuration
              </Text>
            </button>
          ),
          key: '0',
        }
      : null,
    access.isCanCreate
      ? {
          label: (
            <Link to="/configurations/apply" className="tw-font-normal">
              <Icon name="icon-assignment_turned_in" /> <Text variant="bodyMd">Apply Configuration to Hardware</Text>
            </Link>
          ),
          key: '1',
        }
      : null,
  ];

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
        <Text component="h3" variant="h3">
          Configuration
        </Text>
        <Dropdown menu={{ items: actionItems }} trigger={['click']} overlayClassName="create-allocation-dropdown">
          <Space>
            <PrimaryButton label="Add Action" icon={<PlusIcon />} />
          </Space>
        </Dropdown>
      </div>
      <Tabs defaultActiveKey="1" items={tabItems} rootClassName="app-tabs" className="tw-mt-2" />
      <Modal
        open={isChooseTypeModal}
        onOk={() => setIsChooseTypeModal(false)}
        onCancel={() => setIsChooseTypeModal(false)}
        footer={null}
        width={533}
        title={<Text variant="h3">Choose a Hardware Type for Configuration</Text>}
        className="fault-report-button-container"
        loading={isFetching}
      >
        <div>
          <div className="tw-flex tw-items-center tw-gap-y-3 tw-p-2 tw-shadow-simple tw-rounded-lg hover:tw-bg-primary-50">
            <Link
              to={'/configurations/new?type=smartpour'}
              className="tw-w-full tw-flex tw-items-center hover:tw-opacity-100"
            >
              <div className="image-section tw-bg-grey-100 tw-mr-3">
                <img src={SmartPourImage} alt="SmartPour" className="tw-w-[76px] tw-h-[76px]" />
              </div>
              <SmartPOURIcon color="#191919" />{' '}
              <Text variant="labelMd" className="tw-text-grey-900 tw-ml-1">
                SmartPour
              </Text>
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfigurationsPage;
