import React from 'react';
import LotNumberIcon from '../../svgs/InvertColor';
import Text from '../Text';

type Props = {
  item?: string;
};

const LotNumberCardItem: React.FC<Props> = ({ item }) => {
  return (
    <div className="scan-screen-data filling-product-card-item">
      <p className="label tw-block tw-mb-6 tw-text-center">1 Lot Number Scanned:</p>
      <div className="card-item tw-my-4">
        <Text variant="capt1" className="brand tw-flex tw-flex-row tw-items-center tw-gap-4">
          <LotNumberIcon /> <span>Lot Number</span>
        </Text>
        <Text variant="bodyMd" className="tw-mt-1 tw-w-[210px] tw-overflow-auto tw-text-left">
          {item}
        </Text>
      </div>
    </div>
  );
};

export default LotNumberCardItem;
