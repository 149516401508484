import React, { Fragment, ReactNode, useState } from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import { Modal, Tabs, TabsProps } from 'antd';
import { HardwareScannedStateI } from '../../interfaces/hardware';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import SharedScanQRCode from '../shared/Hardware/ScanQRCodeScreen';
import SharedManualScreen from '../shared/Hardware/ManualScreen';
import TransferScanTabsContent from './ScanTabsContent';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import { StartScanningScreen } from '../shared/Hardware/StartScanningScreen';
import { Link } from 'react-router-dom';
import RightArrowIcon from '../../svgs/RightArrowIcon';
import SecondaryButton from '../buttons/SecondaryButton';

const RenderDescriptionWarningNotice = () => {
  return (
    <Fragment>
      <p>
        Ensure the hardware you’re about to allocate are registered. If not, please go through the registration first.
      </p>
      <Link to={`/hardware-registrations/new`}>
        <span>Register Hardware</span>
        <RightArrowIcon width={16} height={16} />{' '}
      </Link>
    </Fragment>
  );
};

type Props = {
  setCurrentStep: (step: number) => void;
  setScannedData: (_: any) => void;
  handleVerify?: (_: any) => void;
  scannedData: HardwareScannedStateI[];
  stopDecodingScanner: boolean;
  finalScannedData?: HardwareScannedStateI[];
  setFinalScannedData?: (_: any) => void;
  setStopDecodingScanner: (_: any) => void;
  setBackTo?: (_: string) => void;
  pageName?: string;
  gallery?: { image: ReactNode; text: ReactNode; position: 'bottom' | 'center' }[];
};

const TransferScanStep: React.FC<Props> = ({
  scannedData,
  setCurrentStep,
  setScannedData,
  handleVerify,
  stopDecodingScanner,
  setStopDecodingScanner,
  finalScannedData = [],
  setFinalScannedData,
  pageName,
  gallery,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [isStartScanning, setIsStartScanning] = useState<boolean>(false);

  const validateScanResult = ({ text, isManualInput = false }: { text: string; isManualInput: boolean }) => {
    let status = 'success';
    const validateData = [...finalScannedData, ...scannedData];

    if (validateData.length > 0) {
      if (validateData.find((data: HardwareScannedStateI) => data.serialCode === text)) {
        status = 'error';
      }
    }

    setTimeout(() => {
      if (status === 'success') {
        const successModal = modal.success({
          type: 'success',
          title: 'Successfully Scanned!',
          content: 'Code detected and added to scanned list!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <CheckCircleIcon width={16} height={16} color="#5FB670" />,
          width: 345,
        });
        const newData = {
          serialCode: text,
          isManualInput,
        } as HardwareScannedStateI;

        setScannedData([...scannedData, newData]);
        setTimeout(() => {
          successModal.destroy();
        }, 1000);
      }

      if (status === 'error') {
        const errorModal = modal.error({
          type: 'error',
          title: 'Failed Scanning!',
          content: 'We detected a duplicated scan. Please check again!',
          className: 'context-modal-custom-container',
          closable: true,
          centered: true,
          footer: null,
          icon: <ErrorModalIcon color="#B13939" />,
          width: 345,
        });

        setTimeout(() => {
          errorModal.destroy();
        }, 1000);
      }
    }, 300);
  };

  const handleScanOrManualSubmit = (result: { text: string; isManualInput: boolean }) => {
    validateScanResult(result);
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <SharedScanQRCode
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          scanTabsContent={<TransferScanTabsContent scannedData={scannedData} />}
          isDisplayTitle={false}
          pageName={pageName}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <SharedManualScreen
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanTabsContent={<TransferScanTabsContent scannedData={scannedData} />}
          isDisplayTitle={false}
        />
      ),
    },
  ];

  const handleFinishScanning = () => {
    handleVerify?.({});
    setCurrentStep(3);
    setStopDecodingScanner(true);
  };

  const handlePreviousStep = () => {
    setScannedData([]);
    setFinalScannedData?.([]);
    setCurrentStep(1);
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  return (
    <div className="component-hardware-allocation-scan-step">
      <div className="wrapper">
        {isStartScanning ? (
          <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} />
        ) : (
          <StartScanningScreen
            warningDescription={<RenderDescriptionWarningNotice />}
            infoDescription="Before proceed scanning, please be reminded to check for any signs of tampering on: Screws, Seal, Cap"
            setIsStartScanning={setIsStartScanning}
            isDisplayActionButton={false}
            className="tw-w-[450px] tw-mx-auto tw-my-0"
            gallery={gallery ?? []}
          />
        )}
      </div>
      {contextHolder}
      <div className="hardware-footer-section">
        <div>
          {isStartScanning ? (
            <>
              <PrimaryButton label="Finish Scanning" onClick={handleFinishScanning} />
            </>
          ) : (
            <>
              <SecondaryButton label="Previous" onClick={handlePreviousStep} />
              <PrimaryButton label="Start Scanning" onClick={() => setIsStartScanning(true)} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransferScanStep;
