import { Button, Dropdown, Spin } from 'antd';
import React from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import { handleFilterParams } from '../../utils/global';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import DisplayLastUpdateApp from '../../components/DisplayLastUpdate';
import SizeBox from '../../components/SizeBox';
import EmptyData from '../../components/EmptyData';
import { ConfigurationI, ConfigurationSearchParamsI } from '../../interfaces/configuration';
import PresetsFilterForm from '../../components/forms/configuration/PresetFilter';
import PresetsCardList from '../../components/card-list/Presets';
import PresetsTable from '../../components/tables/Presets';

type Props = {
  queryParams: ConfigurationSearchParamsI & { viewMode?: string };
  hardwareConfigs?: ConfigurationI[];
  total?: number;
  isLoading: boolean;
  refetchData: () => void;
  isRefetchLoading: boolean;
  setIsRefetchLoading: (value: boolean) => void;
};

const PresetsTabPage: React.FC<Props> = ({
  queryParams,
  hardwareConfigs,
  total,
  isLoading,
  refetchData,
  setIsRefetchLoading,
  isRefetchLoading,
}) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'configurations');
  const navigate = useNavigate();

  const currentPage = queryParams?.page;
  const pagination: any = {
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    total: total,
    showTotal: (total: string) => `${total} Items`,
  };

  const setQueryParams = (params: Partial<ConfigurationSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const handleFilterChange = (values: ConfigurationSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/configurations/${record?.code}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <Spin spinning={isLoading ?? isRefetchLoading}>
      <div className="tw-flex tw-flex-col tw-gap-4">
        <PresetsFilterForm
          queryParams={queryParams}
          onChange={handleFilterChange}
          refetchData={refetchData}
          setIsRefetchLoading={setIsRefetchLoading}
        />
        {!isLoading && hardwareConfigs?.length === 0 && (
          <EmptyData title="No Data" message="All data will be displayed here." />
        )}
        {!isLoading && hardwareConfigs?.length !== 0 && (
          <>
            <DisplayLastUpdateApp />
            <SizeBox height={8} />
            {!queryParams.viewMode ? (
              <PresetsCardList
                access={access}
                data={hardwareConfigs ?? []}
                pagination={pagination}
                onPaginationChange={handleFilter}
              />
            ) : (
              <PresetsTable
                rowKey="id"
                loading={isLoading}
                pagination={pagination}
                dataSource={hardwareConfigs}
                withAction={withAction}
                onChange2={handleFilter}
              />
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default PresetsTabPage;
