import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { DateTimeFormat } from '../../utils/global';
import { renderType } from '../../utils/reports';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const DeliveryReportTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS: any = [
    {
      title: 'hardware code',
      dataIndex: 'Serial Code',
      width: 180,
      fixed: 'left',
      render: (serialCode: string) => {
        return <span>{serialCode}</span>;
      },
    },
    {
      title: 'Time',
      dataIndex: 'Timestamp',
      width: 180,
      render: (time: string) => {
        return <span>{DateTimeFormat(time)}</span>;
      },
    },
    {
      title: 'Type',
      width: 150,
      ellipsis: true,
      render: (record: any) => {
        return renderType(record, record['Hardware Type']);
      },
    },
    {
      title: 'Owner',
      dataIndex: 'Hardware Owner',
      width: 180,
      ellipsis: true,
    },
    {
      title: 'Allocatee',
      dataIndex: 'Hardware Allocatee',
      width: 180,
      ellipsis: true,
    },
    {
      title: 'Site',
      dataIndex: 'Site',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Site Type',
      dataIndex: 'Site Type',
      width: 150,
      ellipsis: true,
      render: (siteType: string) => {
        return <span className="text-capitalize">{siteType}</span>;
      },
    },
    {
      title: 'Country',
      dataIndex: 'Country',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Address 1',
      dataIndex: 'Address Line 1',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Address 2',
      dataIndex: 'Address Line 2',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Address 3',
      dataIndex: 'Address Line 3',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Postal',
      dataIndex: 'Postal',
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Brand',
      dataIndex: 'Brand',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Product',
      dataIndex: 'Product',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Producer',
      dataIndex: 'Producer',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'SKU',
      dataIndex: 'SKU',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'ABV',
      dataIndex: 'ABV',
      width: 80,
      ellipsis: true,
    },
    {
      title: 'Product Owner',
      dataIndex: 'Product Owner',
      width: 180,
      ellipsis: true,
    },
    {
      title: 'Username',
      dataIndex: 'Username',
      width: 180,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      width: 180,
      ellipsis: true,
    },
    {
      title: 'Remarks',
      dataIndex: 'Remark',
      width: 150,
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'left' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default DeliveryReportTable;
