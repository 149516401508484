import React, { Dispatch, useEffect, useMemo } from 'react';
import BackLink from '../buttons/BackLink';
import DropdownApp from '../Dropdown';
import Text from '../Text';
import { Badge, Button, Dropdown, Form, MenuProps } from 'antd';
import DateRangePicker from '../DateRangePicker';
import { DateRangeT } from '../../interfaces';
import { dateRangeOptions } from '../../constants/dashboard';
import DisplayLastUpdateApp from '../DisplayLastUpdate';
import RefreshButton from '../buttons/RefreshButton';
import Icon from '../Icon';
import IconButton from '../buttons/IconButton';
import FilterIcon from '../../svgs/FilterIcon';
import { FleetReportSearchParamsI, ReportPageNameT } from '../../interfaces/fleetReports';
import './TopSection.scss';
import ReportFilterContent from './FilterContent';
import { convertDayToISOString } from '../../utils/global';
import dayjs from 'dayjs';

type Props = {
  menuDropdownProps: any;
  pageName: ReportPageNameT;
  title: string;
  description: string;
  totalItems: number;
  queryParams: FleetReportSearchParamsI;
  activeDateRange?: DateRangeT;
  isExportLoading?: boolean;
  setActiveDateRange?: Dispatch<React.SetStateAction<DateRangeT>>;
  refetchData: () => void;
  onChange(newParams: FleetReportSearchParamsI): void;
  setQueryParams: (params: FleetReportSearchParamsI) => void;
  backFunction?: () => void;
};

const ReportTopSection: React.FC<Props> = ({
  menuDropdownProps,
  title,
  description,
  pageName,
  totalItems,
  queryParams,
  activeDateRange,
  isExportLoading,
  onChange,
  refetchData,
  setQueryParams,
  setActiveDateRange,
  backFunction,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (pageName !== 'Inventory') {
      setQueryParams({
        ...queryParams,
        filter: {
          ...queryParams.filter,
          fromDate: convertDayToISOString(dayjs(activeDateRange?.period.since).startOf('day')),
          toDate: convertDayToISOString(dayjs(activeDateRange?.period.until).endOf('day')),
        },
      });
    }
  }, [activeDateRange]);

  const handleOnChange = (params: Partial<FleetReportSearchParamsI>) => {
    onChange({ ...queryParams, ...params });
  };

  const handleSortItemClick: MenuProps['onClick'] = (e: any) => {
    const { key } = e;
    const [orderBy, type] = key.split('-');
    handleOnChange({
      orderBy: orderBy as FleetReportSearchParamsI['orderBy'],
      orderType: type as FleetReportSearchParamsI['orderType'],
    });
  };

  const items: MenuProps['items'] = [
    ...(pageName === 'Inventory'
      ? [
          {
            key: 'Serial Code-asc',
            className: `${queryParams?.orderBy === 'Serial Code' && queryParams?.orderType === 'asc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2`}>
                <span>Serial Code (A-Z)</span>
              </Text>
            ),
          },
          {
            key: 'Serial Code-desc',
            className: `${queryParams?.orderBy === 'Serial Code' && queryParams?.orderType === 'desc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2 `}>
                <span>Serial Code (Z-A)</span>
              </Text>
            ),
          },
          {
            key: 'Days Since Last Interaction-asc',
            className: `${queryParams?.orderBy === 'Days Since Last Interaction' && queryParams?.orderType === 'asc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2`}>
                <span>Last Interaction (Oldest)</span>
              </Text>
            ),
          },
          {
            key: 'Days Since Last Interaction-desc',
            className: `${queryParams?.orderBy === 'Days Since Last Interaction' && queryParams?.orderType === 'desc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2 `}>
                <span>Last Interaction (Newest)</span>
              </Text>
            ),
          },
        ]
      : []),
    ...(pageName === 'Dispense' || pageName === 'Depletion'
      ? [
          {
            key: 'Serial Code (Pour)-asc',
            className: `${queryParams?.orderBy === 'Serial Code (Pour)' && queryParams?.orderType === 'asc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2`}>
                <span>Serial Code (A-Z)</span>
              </Text>
            ),
          },
          {
            key: 'Serial Code (Pour)-desc',
            className: `${queryParams?.orderBy === 'Serial Code (Pour)' && queryParams?.orderType === 'desc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2 `}>
                <span>Serial Code (Z-A)</span>
              </Text>
            ),
          },
        ]
      : []),
    ...(pageName === 'Fill' || pageName === 'Delivery'
      ? [
          {
            key: 'Serial Code-asc',
            className: `${queryParams?.orderBy === 'Serial Code' && queryParams?.orderType === 'asc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2`}>
                <span>Serial Code (A-Z)</span>
              </Text>
            ),
          },
          {
            key: 'Serial Code-desc',
            className: `${queryParams?.orderBy === 'Serial Code' && queryParams?.orderType === 'desc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2 `}>
                <span>Serial Code (Z-A)</span>
              </Text>
            ),
          },
        ]
      : []),
    ...(pageName !== 'Inventory'
      ? [
          {
            key: 'Timestamp-asc',
            className: `${queryParams?.orderBy === 'Timestamp' && queryParams?.orderType === 'asc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2`}>
                <span>Timestamp (Oldest)</span>
              </Text>
            ),
          },
          {
            key: 'Timestamp-desc',
            className: `${queryParams?.orderBy === 'Timestamp' && queryParams?.orderType === 'desc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2 `}>
                <span>Timestamp (Newest)</span>
              </Text>
            ),
          },
        ]
      : []),
  ];

  const menuSortProps = {
    items,
    onClick: handleSortItemClick,
  };

  const count = useMemo(() => {
    if (!queryParams.filter) return 0;
    let count = 0;
    Object.values(queryParams.filter).forEach((val) => {
      if (Array.isArray(val)) {
        count += val.length;
      }
    });
    return count;
  }, [queryParams]);

  return (
    <div className="tw-bg-white tw-px-4 tw-w-full tw-py-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <BackLink backFunction={backFunction} />
        <Dropdown
          menu={menuDropdownProps}
          trigger={['click']}
          placement={'bottomLeft'}
          rootClassName="report-top-section-dropdown"
          dropdownRender={(menus) => {
            return (
              <div className="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-3 tw-w-[220px]">
                <Text variant="inputLabelSm" className="tw-text-grey-700">
                  Select a report format
                </Text>
                <div>{menus}</div>
              </div>
            );
          }}
        >
          <Button
            loading={isExportLoading}
            className={`
              report-top-action-button 
              tw-bg-primary-500 
              hover:!tw-bg-primary-500 
              tw-flex 
              tw-items-center 
              tw-gap-2 
              tw-p-2 
              tw-rounded-lg 
              tw-cursor-pointer 
              tw-leading-[1px] 
              tw-text-white 
              hover:!tw-text-white 
              tw-min-w-[117px]
              tw-min-h-10
              tw-border-none
            `}
            icon={<Icon name="icon-file_download" size={20} />}
          >
            {!isExportLoading && (
              <Text variant="buttonMd" className={`button-label xs:tw-text-[14px] xs:tw-leading-5`}>
                Download
              </Text>
            )}
          </Button>
        </Dropdown>
      </div>
      <Text variant="h2" component="p" className="!tw-block">
        {title}
      </Text>
      <Text variant="bodyLg" component="p" className="!tw-block tw-text-grey-700 tw-mt-2">
        {description}
      </Text>
      <div className="tw-flex tw-gap-3 tw-mt-5">
        <Form form={form} requiredMark={false} colon={false} scrollToFirstError className="tw-w-full">
          <div className="form-container">
            <div className="form-content !tw-mt-0">
              <Form.Item name="date" className="tw-flex-1 [&>div>div>div>div>div]:tw-w-full sm:tw-w-full !tw-mb-0">
                <DateRangePicker
                  setActiveDateRange={setActiveDateRange}
                  activeDateRange={activeDateRange}
                  ranges={dateRangeOptions}
                  isDisabled={pageName === 'Inventory' ? true : false}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
        <div className="tw-flex tw-gap-3 xs:tw-gap-1 xs:tw-w-full xs:tw-justify-end">
          <DropdownApp
            icon={<Icon name="icon-sort" size={22} />}
            menu={menuSortProps}
            size="small"
            placement="bottomRight"
            type="secondary"
          />
          <Dropdown
            className="filter-dropdown-button"
            trigger={['click']}
            overlayClassName="filter-drowdown"
            dropdownRender={() => (
              <ReportFilterContent
                setQueryParams={setQueryParams}
                queryParams={queryParams}
                onChange={handleOnChange}
              />
            )}
          >
            <Badge count={count ?? 0}>
              <IconButton icon={<FilterIcon height={20} />} />
            </Badge>
          </Dropdown>
          <RefreshButton doRefetch={refetchData} />
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-justify-between tw-mt-4">
        <Text variant="inputLabelMd">
          <b>{totalItems}</b> entries
        </Text>
        <DisplayLastUpdateApp />
      </div>
    </div>
  );
};

export default ReportTopSection;
