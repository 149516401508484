import { Checkbox, Form, Select } from 'antd';
import React, { useEffect } from 'react';
import PrimaryButton from '../../buttons/PrimaryButton';
import './FilterContent.scss';
import { HardwareSearchParamsI } from '../../../interfaces/hardware';
import Text from '../../Text';
import SecondaryButton from '../../buttons/SecondaryButton';
import { useQuery } from 'react-query';
import { getHardwareSitesList } from '../../../apis/hardwares';
import SelectAllCheckbox from '../../SelectAllCheckbox';

type FormParams = {
  serialCode: string;
  devId: string;
  status: string[];
  condition: string[];
  type: string[];
  siteIds: string[];
};

type Props = {
  queryParams: HardwareSearchParamsI;
  onChange(newParams: Partial<HardwareSearchParamsI>): void;
};

const typeOptions = [
  { label: 'Receive', value: 'receive' },
  { label: 'Sent', value: 'send' },
];

const AllocationFilterContent: React.FC<Props> = ({ queryParams, onChange }) => {
  const [form] = Form.useForm();
  const { data: { sites = [] } = {} } = useQuery(['sites', 'list', queryParams], () => getHardwareSitesList(), {
    select({ data: { sites, total } }) {
      return { sites, total };
    },
  });

  const handleResetFilter = () => {
    form.resetFields();
    onChange({ filter: {} });
  };

  const handleOnFinish = (values: FormParams) => {
    onChange({
      filter: { type: values?.type, siteIds: values?.siteIds },
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      type: queryParams.filter?.type,
      siteIds: Array.isArray(queryParams.filter?.siteIds)
        ? queryParams.filter?.siteIds?.map((site: string) => parseInt(site))
        : '',
    });
  }, [queryParams]);

  return (
    <div className="component-hardware-filter-content-container partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content">
            <Form.Item name="type">
              <SelectAllCheckbox form={form} plainOptions={typeOptions} name="type" sectionTitle="Type">
                {typeOptions.map((item) => (
                  <Checkbox key={item.value} value={item.value}>
                    {item.label}
                  </Checkbox>
                ))}
              </SelectAllCheckbox>
            </Form.Item>
            <Text variant="h4" className="tw-mb-3">
              Site
            </Text>
            <Form.Item name="siteIds">
              <Select placeholder="Select sites" showSearch optionFilterProp="label" allowClear mode="multiple">
                {sites
                  .slice()
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))
                  .map((item) => (
                    <Select.Option key={item.id} value={item.id} label={item.displayName}>
                      {item.displayName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AllocationFilterContent;
