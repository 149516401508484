import React from 'react';
import { MenuProps } from 'antd';
import Text from '../components/Text';

export const renderSortList = (queryParams: any, param?: string, params?: { key: string; label: string }[]) => {
  const items: MenuProps['items'] = [
    {
      key: 'createdAt-desc',
      className: `${queryParams?.orderBy === 'createdAt' && queryParams?.orderType === 'desc' ? 'active tw-bg-primary-50' : 'inactive'}`,
      label: (
        <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2 `}>
          <span>Latest</span>
        </Text>
      ),
    },
    {
      key: 'createdAt-asc',
      className: `${queryParams?.orderBy === 'createdAt' && queryParams?.orderType === 'asc' ? 'active tw-bg-primary-50' : 'inactive'}`,
      label: (
        <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2`}>
          <span>Earliest</span>
        </Text>
      ),
    },
    ...(params
      ? params.flatMap((param) => [
          {
            key: `${param.key}-asc`,
            className: `${queryParams?.orderBy === param.key && queryParams?.orderType === 'asc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2`}>
                <span>{param.label} (A-Z)</span>
              </Text>
            ),
          },
          {
            key: `${param.key}-desc`,
            className: `${queryParams?.orderBy === param.key && queryParams?.orderType === 'desc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2`}>
                <span>{param.label} (Z-A)</span>
              </Text>
            ),
          },
        ])
      : []),
    ...(param
      ? [
          {
            key: `${param}-asc`,
            className: `${queryParams?.orderBy === param && queryParams?.orderType === 'asc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2 `}>
                <span>Ascending</span>
              </Text>
            ),
          },
          {
            key: `${param}-desc`,
            className: `${queryParams?.orderBy === param && queryParams?.orderType === 'desc' ? 'active tw-bg-primary-50' : 'inactive'}`,
            label: (
              <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2`}>
                <span>Descending</span>
              </Text>
            ),
          },
        ]
      : []),
  ];
  return items;
};
