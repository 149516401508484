import { Button, message, Tag } from 'antd';
import React from 'react';
import { SiteI } from '../../interfaces/site';
import { getCountryFromCode } from '../../utils/global';
import DetailsView from '../DetailsView';
import Text from '../Text';
import CopyToClipboard from 'react-copy-to-clipboard';
import Icon from '../Icon';
import DisplayQRCode from '../shared/DisplayQRCode';

type Props = {
  site: SiteI;
};

const SiteInformation: React.FC<Props> = ({ site }) => {
  return (
    <DetailsView title="Site Information">
      <div className="flex-row">
        <DetailsView.Field
          label="Site Reference QR Code"
          value={
            <DisplayQRCode
              data={site?.referenceCode ?? ''}
              downloadName={`${site?.referenceCode?.toUpperCase()}.png`}
            />
          }
        />
        <DetailsView.Field
          label="Site Reference Code"
          value={
            <span className="tw-flex tw-items-center tw-gap-2">
              <Text variant="bodyLgB" className="tw-uppercase">
                {site?.referenceCode}
              </Text>
              <CopyToClipboard text={site?.referenceCode?.toUpperCase() ?? ''} onCopy={() => message.info('Copied')}>
                <Button className="tw-border-none tw-p-0">
                  <Icon name="icon-file_copy" size={20} className="tw-text-primary-500" />
                </Button>
              </CopyToClipboard>
            </span>
          }
        />
      </div>
      <div className="flex-row">
        <DetailsView.Field label="Site Name" value={site?.legalName} />
        <DetailsView.Field label="Display Name" value={site?.displayName} />
      </div>
      <div className="flex-row">
        <DetailsView.Field
          label="Type of Site"
          value={
            <Text variant="bodyLgB" className="text-capitalize">
              {site?.type}
            </Text>
          }
        />
        <DetailsView.Field label="Market" value={<Tag>{site?.market}</Tag>} />
      </div>
      <DetailsView.Field label="Description" value={<b className="tw-whitespace-pre-wrap">{site?.description}</b>} />
      <DetailsView title="Location" className="!tw-p-0 tw-mt-8 tw-shadow-none">
        <div className="flex-row">
          <DetailsView.Field label="Address Line 1" value={site?.addressLine1} />
          <DetailsView.Field label="Address Line 2" value={site?.addressLine2} />
        </div>
        <div className="flex-row">
          <DetailsView.Field label="Address Line 3" value={site?.addressLine3} />
          <DetailsView.Field label="Country" value={getCountryFromCode(site?.country)} />
        </div>
        <div className="flex-row">
          <DetailsView.Field label="Region" value={site?.region} />
          <DetailsView.Field label="State / Province" value={site?.state} />
        </div>
        <DetailsView.Field label="Postal / Zip Code" value={site?.postalCode} />
      </DetailsView>
    </DetailsView>
  );
};

export default SiteInformation;
