import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { HardwareItemI } from '../../interfaces/hardware';
import { DateFormat } from '../../utils/global';
import { HardwareAllocationI } from '../../interfaces/hardwareAllocation';
import { AllocationTypeComponent } from '../AllocationType';
import { Tag } from 'antd';
import { SiteI } from '../../interfaces/site';
import Icon from '../Icon';
import { AllocationHistoryI } from '../../interfaces/allocationHistory';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: HardwareAllocationI[];
};

const HardwareAllocationsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Report',
      width: 230,
      ellipsis: true,
      render: (record: HardwareAllocationI) => {
        return (
          <span className="tw-flex tw-items-center tw-gap-x-2">
            <span>{`${record?.uuid}`}</span>
            {record?.remarks !== null && <Icon name="icon-text_snippet" size={16} />}
          </span>
        );
      },
    },
    {
      title: 'Type',
      width: 150,
      ellipsis: true,
      render: (item: AllocationHistoryI) => {
        return <AllocationTypeComponent item={item} />;
      },
    },
    {
      title: 'Hardware',
      dataIndex: 'hardwareList',
      width: 120,
      ellipsis: true,
      render: (hardwareList: HardwareItemI[]) => {
        return <span>{hardwareList?.length}</span>;
      },
    },
    {
      title: 'Site',
      width: 150,
      dataIndex: 'site',
      ellipsis: true,
      render: (site: SiteI) => {
        return (
          <Tag className="tw-truncate tw-max-w-[150px]">{site !== null ? site?.displayName : 'External Site'}</Tag>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 150,
      ellipsis: true,
      render: (createdAt: string) => {
        return <span>{DateFormat(createdAt)}</span>;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default HardwareAllocationsTable;
