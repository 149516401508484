import { BaseResponse } from '../interfaces';
import {
  DeliveryReportCollectionI,
  DepletionsReportCollectionI,
  DispenseReportCollectionI,
  FillReportCollectionI,
  FleetReportSearchParamsI,
  InventoryReportCollectionI,
  ReportPageNameT,
} from '../interfaces/fleetReports';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import dayjs from 'dayjs';

export const getReportInventories = async (params?: FleetReportSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<BaseResponse<InventoryReportCollectionI>>(
    `hardwares/inventory-report?${newParams}`,
  );
  return response;
};

export const getDashboardReportInventories = async (params?: FleetReportSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<BaseResponse<InventoryReportCollectionI>>(`reports/inventory?${newParams}`);
  return response;
};

export const getReportDispenses = async (params?: FleetReportSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<BaseResponse<DispenseReportCollectionI>>(
    `hardwares/dispense-report?${newParams}`,
  );
  return response;
};

export const getDashboardReportDispenses = async (params?: FleetReportSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<BaseResponse<DispenseReportCollectionI>>(`reports/dispense?${newParams}`);
  return response;
};

export const getReportDeliveries = async (params?: FleetReportSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<BaseResponse<DeliveryReportCollectionI>>(
    `allocation-histories/delivery-report?${newParams}`,
  );
  return response;
};

export const getDashboardReportDeliveries = async (params?: FleetReportSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<BaseResponse<DeliveryReportCollectionI>>(`reports/delivery?${newParams}`);
  return response;
};

export const getReportFills = async (params?: FleetReportSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<BaseResponse<FillReportCollectionI>>(
    `filling-histories/filling-report?${newParams}`,
  );
  return response;
};

export const getDashboardReportFills = async (params?: FleetReportSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<BaseResponse<FillReportCollectionI>>(`reports/filling?${newParams}`);
  return response;
};

export const getReportDepletions = async (params?: FleetReportSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<BaseResponse<DepletionsReportCollectionI>>(
    `depletion-histories/depletion-report?${newParams}`,
  );
  return response;
};

export const getDashboardReportDepletions = async (params?: FleetReportSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<BaseResponse<DepletionsReportCollectionI>>(`reports/depletion?${newParams}`);
  return response;
};

export const doExportReport = async (params: FleetReportSearchParamsI, pageName: ReportPageNameT) => {
  const page = {
    Delivery: {
      endpoint: 'allocation-histories/delivery-report',
      fileName: 'Delivery-report',
    },
    Dispense: {
      endpoint: 'hardwares/dispense-report',
      fileName: 'Dispense-report',
    },
    Inventory: {
      endpoint: 'hardwares/inventory-report',
      fileName: 'Inventory-report',
    },
    Fill: {
      endpoint: 'filling-histories/filling-report',
      fileName: 'Fills-report',
    },
    Depletion: {
      endpoint: 'depletion-histories/depletion-report',
      fileName: 'Depletions-report',
    },
    DashboardDelivery: {
      endpoint: 'reports/delivery',
      fileName: 'Delivery-report',
    },
    DashboardDispense: {
      endpoint: 'reports/dispense',
      fileName: 'Dispense-report',
    },
    DashboardInventory: {
      endpoint: 'reports/inventory',
      fileName: 'Inventory-report',
    },
    DashboardFill: {
      endpoint: 'reports/filling',
      fileName: 'Fills-report',
    },
    DashboardDepletion: {
      endpoint: 'reports/depletion',
      fileName: 'Depletions-report',
    },
  };
  const newParams = handleFilterParams(params);
  const { data: response }: any = await api.getBlob(`${page[pageName].endpoint}?${newParams}`);

  if (response) {
    const mimeType =
      params.view === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const url = window.URL.createObjectURL(new Blob([response], { type: mimeType }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${page[pageName].fileName}-${dayjs().format('DDMMYYYY')}.${params.view}`);
    document.body.appendChild(link);
    link.click();
  }
  return response.data;
};
