import React from 'react';
import { HardwareHistoryI } from '../../interfaces/hardwareHistory';
import Avatar from '../User/Avatar';
import { Link } from 'react-router-dom';
import { DateTimeFormat } from '../../utils/global';
import Divider from '../Divider';
import SizeBox from '../SizeBox';
import Text from '../Text';
import { CommonStatus } from '../CommonStatus';
import { convertVolumeTo2DP, displayAllHardwareHistoryType } from '../../utils/hardware';
import ViewItemOfGroupButton from '../buttons/ViewItemsOfGroup';
import Icon from '../Icon';
import NetworkStatus from '../shared/NetworkStatus';
import ProductTraceStatus from '../ProductTrace/Status';
import { ProductTraceReportTypeT } from '../../interfaces/productTrace';

type Props = {
  item: HardwareHistoryI;
  hardwareType?: string;
  isPlatform?: boolean;
};

const TrackingHistoryItem: React.FC<Props> = ({ item, hardwareType, isPlatform = false }) => {
  const trackingUrl = isPlatform ? '/platform/hardware' : '/tracking';
  const platformUrl = isPlatform ? '/platform' : '';

  return (
    <div className="tw-p-4">
      <div className="tw-flex tw-justify-between tw-flex-wrap tw-mb-3 tw-items-center">
        <div className="tw-flex tw-items-center">
          {item?.relations?.type && (
            <Text
              variant="bodySmB"
              className={`tw-border-solid tw-border tw-border-grey-300 tw-rounded tw-bg-grey-50 tw-py-1 tw-px-2 tw-mr-2 text-capitalize ${item?.relations?.type === 'external' && '!tw-bg-primary-50'}`}
            >
              {item?.relations?.type}
            </Text>
          )}
          <Text variant="labelMd" className="tw-text-grey-700">
            {displayAllHardwareHistoryType[item?.eventName]}
          </Text>
        </div>
        <Text variant="bodyMd" className="tw-text-grey-800">
          {DateTimeFormat(item?.createdAt)}
        </Text>
      </div>
      <div className="tw-flex tw-items-center tw-gap-2 tw-mb-3 tw-flex-wrap">
        {item?.relations?.type === 'internal' && item?.relations?.user && (
          <Avatar
            className={`!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]`}
            id={item?.relations?.user.id}
          />
        )}
        {item?.relations?.type === 'internal' && item?.relations?.user && (
          <Text variant="bodyMdB">{item?.relations?.user?.username}</Text>
        )}
        {item?.relations?.type === 'external' && item.relations?.allocatee && (
          <Avatar
            className={`!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] !tw-rounded`}
            id={item.relations?.allocatee?.id}
          />
        )}
        {item?.relations?.type === 'external' && item.relations?.allocatee && (
          <Text variant="bodyMdB">{item.relations?.allocatee?.displayName}</Text>
        )}
        {item?.eventName === 'addGroup' && (
          <>
            <Text variant="bodyLg">
              Added hardware to{' '}
              <span className="tw-text-primary-800">
                <Link to={`/tracking/groups/${item.relations?.group?.code}`}>
                  {item?.relations?.group?.title} - {item?.relations?.group?.code}
                </Link>
              </span>
            </Text>
          </>
        )}
        {item?.eventName === 'removeGroup' && (
          <>
            <Text variant="bodyLg">
              Removed hardware from{' '}
              <span className="tw-text-primary-800">
                <Link to={`/tracking/groups/${item.relations?.group?.code}`}>
                  {item?.relations?.group?.title} - {item?.relations?.group?.code}
                </Link>
              </span>
            </Text>
          </>
        )}
        {item?.eventName === 'registered' && (
          <>
            <Text variant="bodyLg">
              Registered hardware as part of
              <span className="tw-text-primary-800">
                {' '}
                <Link to={`/hardware-registrations/${item?.relations?.registrationHistory?.uuid}`}>
                  {item?.relations?.registrationHistory?.uuid}
                </Link>
              </span>{' '}
              to
              {item?.relations?.site && (
                <span className="tw-text-primary-800">
                  {' '}
                  <Link to={`${platformUrl}/sites/${item?.relations?.site?.id}/`}>
                    {item?.relations?.site?.legalName}
                  </Link>
                </span>
              )}
            </Text>
          </>
        )}
        {item?.eventName === 'filling' && hardwareType !== 'ecoplant' && (
          <Text variant="bodyLg">
            Filled hardware with product <span className="tw-text-primary-800"></span>
            <Link to={`${platformUrl}/products/${item?.relations?.product?.sku}`}>
              {item?.relations?.product?.name} - {item?.relations?.product?.sku}
            </Link>{' '}
            as part of{' '}
            <Link to={`/hardware-filling/${item.relations.fillingHistory?.uuid}`}>
              {item.relations?.fillingHistory?.uuid}
            </Link>{' '}
            at
            {item?.relations?.site && (
              <span className="tw-text-primary-800">
                {' '}
                <Link to={`${platformUrl}/sites/${item?.relations?.site?.id}`}>{item?.relations?.site?.legalName}</Link>
              </span>
            )}
          </Text>
        )}
        {item?.eventName === 'filling' && hardwareType === 'ecoplant' && (
          <Text variant="bodyLg">
            Used hardware to fill with product <span className="tw-text-primary-800"></span>
            <Link to={`${platformUrl}/products/${item?.relations?.product?.sku}`}>
              {item?.relations?.product?.name} - {item?.relations?.product?.sku}
            </Link>{' '}
            as part of{' '}
            <Link to={`/hardware-filling/${item.relations.fillingHistory?.uuid}`}>
              {item.relations?.fillingHistory?.uuid}
            </Link>{' '}
            at
            {item?.relations?.site && (
              <span className="tw-text-primary-800">
                {' '}
                <Link to={`${platformUrl}/sites/${item?.relations?.site?.id}`}>{item?.relations?.site?.legalName}</Link>
              </span>
            )}
          </Text>
        )}
        {item?.eventName === 'allocationReceive' && (
          <Text variant="bodyLg">
            Received Allocation as part of <span className="tw-text-primary-800"></span>
            <Link to={`/hardware-allocations/${item?.relations?.allocationHistory?.uuid}`}>
              {item?.relations?.allocationHistory?.uuid}
            </Link>
            {item?.relations?.type !== 'external' && item?.relations?.oldSite && (
              <>
                {' '}
                from site
                <span className="tw-text-primary-800">
                  {' '}
                  <Link to={`${platformUrl}/sites/${item?.relations?.oldSite?.id}`}>
                    {item?.relations?.oldSite?.legalName}
                  </Link>
                </span>
              </>
            )}
            {item?.relations?.site && (
              <>
                {' '}
                to site
                <span className="tw-text-primary-800">
                  {' '}
                  <Link to={`${platformUrl}/sites/${item?.relations?.site?.id}`}>
                    {item?.relations?.site?.legalName}
                  </Link>
                </span>
              </>
            )}
          </Text>
        )}
        {item?.eventName === 'allocationSend' && (
          <Text variant="bodyLg">
            Sent Allocation as part of <span className="tw-text-primary-800"></span>
            <Link to={`/hardware-allocations/${item?.relations?.allocationHistory?.uuid}`}>
              {item?.relations?.allocationHistory?.uuid}
            </Link>{' '}
            {item?.relations?.oldData?.site && (
              <>
                {' '}
                from site
                <span className="tw-text-primary-800">
                  {' '}
                  <Link to={`${platformUrl}/sites/${item?.relations?.oldSite?.id}`}>
                    {item?.relations?.oldSite?.legalName}
                  </Link>
                </span>
              </>
            )}
            {item?.relations?.site && (
              <>
                {' '}
                to site
                <span className="tw-text-primary-800">
                  {' '}
                  <Link to={`${platformUrl}/sites/${item?.relations?.site?.id}`}>
                    {item?.relations?.site?.legalName}
                  </Link>
                </span>
              </>
            )}
          </Text>
        )}
        {item?.eventName === 'depletion' && (
          <Text variant="bodyLg">
            Marked hardware as Depleted as part of <span className="tw-text-primary-800"></span>
            <Link to={`/hardware-depletions/${item?.relations?.depletionHistory?.uuid}`}>
              {item?.relations?.depletionHistory?.uuid}
            </Link>
          </Text>
        )}
        {item?.eventName === 'updateStatus' && (
          <Text variant="bodyLg">
            Changed hardware’s status from <CommonStatus status={item?.oldRecord?.status} /> to{' '}
            <CommonStatus status={item?.newRecord?.status} />
          </Text>
        )}
        {item?.eventName === 'mating' && item?.eventState === 'success' && (
          <Text variant="bodyLg" className="tw-flex">
            <Icon name="icon-check" size={24} className="tw-text-success-500 tw-mr-3" />
            Successful mating of hardware to
            <span className="tw-flex tw-items-center tw-gap-0.5">
              {item?.relations?.smartPour && (
                <>
                  <Icon name="icon-smart-pour" size={20} /> SmartPour &nbsp;
                  <Link to={`${trackingUrl}/${item?.relations?.smartPour.serialCode}`}>
                    {' '}
                    {item?.relations?.smartPour.serialCode}
                  </Link>
                </>
              )}
              {item?.relations?.ecoTote && (
                <>
                  <Icon name="icon-smart-pour" size={20} /> ecoTote{' '}
                  {convertVolumeTo2DP(item?.relations?.ecoTote?.volume)} &nbsp;
                  <Link to={`${trackingUrl}/${item?.relations?.ecoTote.serialCode}`}>
                    {' '}
                    {item?.relations?.ecoTote.serialCode}
                  </Link>
                </>
              )}
            </span>
          </Text>
        )}
        {item?.eventName === 'mating' && item?.eventState === 'failed' && (
          <Text variant="bodyLg" className="tw-flex">
            <Icon name="icon-close" size={24} className="tw-text-error-500 tw-mr-3" />
            Failed mating of hardware to
            <span className="tw-flex tw-items-center tw-gap-0.5">
              {item?.relations?.smartPour && (
                <>
                  <Icon name="icon-smart-pour" size={20} /> SmartPour &nbsp;
                  <Link to={`${trackingUrl}/${item?.relations?.smartPour.serialCode}`}>
                    {' '}
                    {item?.relations?.smartPour.serialCode}
                  </Link>
                </>
              )}
              {item?.relations?.ecoTote && (
                <>
                  <Icon name="icon-smart-pour" size={20} /> ecoTote{' '}
                  {convertVolumeTo2DP(item?.relations?.ecoTote?.volume)} &nbsp;
                  <Link to={`${trackingUrl}/${item?.relations?.ecoTote.serialCode}`}>
                    {' '}
                    {item?.relations?.ecoTote.serialCode}
                  </Link>
                </>
              )}
            </span>
          </Text>
        )}
        {item?.eventName === 'pairing' && (
          <Text variant="bodyLg" className="tw-flex">
            Successfully paired hardware to CircularOne mobile app
          </Text>
        )}
        {item?.eventName === 'dispense' && (
          <Text variant="bodyLg">
            <Text variant="bodyLg" className="!tw-block">
              Dispensed {Number(item?.relations?.actualDispenseVolume ?? 0)}mL of{' '}
              {Number(item?.relations?.requestedDispenseVolume ?? 0)}mL from product{' '}
              {
                <Link to={`${platformUrl}/products/${item?.relations?.product?.sku}`}>
                  {' '}
                  {item?.relations?.product?.name} - {item?.relations?.product?.sku}
                </Link>
              }
            </Text>
            <Text variant="bodyLg" className="!tw-block">
              {((item?.relations?.remainingVol ?? 0) / 1000)?.toFixed(2)}L left of{' '}
              {convertVolumeTo2DP(item?.relations?.volFormat)}
            </Text>
          </Text>
        )}
        {item?.eventName === 'priming' && (
          <Text variant="bodyLg">
            <Text variant="bodyLg" className="tw-flex">
              Primed {Number(item?.relations?.actualDispenseVolume ?? 0)}mL from
              <span className="tw-flex tw-items-center tw-gap-0.5">
                {' '}
                &nbsp;
                <Icon name="icon-eco-tote" /> ecoTOTE {convertVolumeTo2DP(item?.relations?.volFormat)} &nbsp;
                <Link to={`${trackingUrl}/${item?.relations?.ecoTote?.serialCode}`}>
                  {' '}
                  {item?.relations?.ecoTote?.serialCode}
                </Link>
              </span>
            </Text>
            <Text variant="bodyLg" className="!tw-block">
              {((item?.relations?.remainingVol ?? 0) / 1000)?.toFixed(2)}L left from -{' '}
              {convertVolumeTo2DP(item?.relations?.volFormat)}
            </Text>
          </Text>
        )}
        {item?.eventName === 'cleaning' && <Text variant="bodyLg">Hardware has been cleaned</Text>}
        {item?.eventName === 'online' && (
          <Text variant="bodyLg" className="tw-flex tw-gap-3">
            Changed network status to {<NetworkStatus status="online" />}
          </Text>
        )}
        {item?.eventName === 'offline' && (
          <Text variant="bodyLg" className="tw-flex tw-gap-3">
            Changed network status to {<NetworkStatus status="offline" />}
          </Text>
        )}
        {item?.eventName === 'vsr' && item?.eventState === 'failed' && (
          <Text variant="bodyLg" className="tw-flex tw-gap-3">
            <Icon name="icon-close" size={24} className="tw-text-error-500" />
            Unsuccessful verification with permissible VSR lists for{' '}
            {
              <Link to={`${platformUrl}/products/${item?.relations?.product?.sku}`}>
                {item?.relations?.product?.name} - {item?.relations?.product?.sku}.
              </Link>
            }
          </Text>
        )}
        {item?.eventName === 'vsr' && item?.eventState === 'success' && (
          <Text variant="bodyLg" className="tw-flex tw-gap-3">
            <Icon name="icon-check" size={24} className="tw-text-success-500" />
            Successful verification with permissible VSR lists for &nbsp;
            {
              <Link to={`${platformUrl}/products/${item?.relations?.product?.sku}`}>
                {item?.relations?.product?.name} - {item?.relations?.product?.sku}.
              </Link>
            }
          </Text>
        )}
        {item?.eventName === 'ownershipTransfer' && (
          <Text variant="bodyLg" className="tw-flex tw-items-center tw-flex-gap">
            Transferred hardware’s ownership to &nbsp;{' '}
            <Avatar
              id={item?.relations?.newOwner.id}
              type="partner"
              className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded"
            />{' '}
            &nbsp;
            <Text variant="bodyMdB">{item?.relations?.newOwner?.displayName}</Text>
            <span></span>
          </Text>
        )}
        {item?.eventName === 'faultReport' && item?.eventState === 'resolve' && (
          <Text variant="bodyLg">
            Resolved fault report &nbsp;
            {
              <Link to={`/fault-reports/${item?.relations?.faultReport?.code}`}>
                {item?.relations?.faultReport?.code}
              </Link>
            }
            &nbsp; for this hardware
          </Text>
        )}
        {item?.eventName === 'faultReport' && item?.eventState === 'report' && (
          <Text variant="bodyLg">
            Created a hardware fault report &nbsp;
            {
              <Link to={`/fault-reports/${item?.relations?.faultReport?.code}`}>
                {item?.relations?.faultReport?.code}
              </Link>
            }
            &nbsp;
            {item?.relations?.product ? (
              <>
                with product &nbsp;
                {
                  <Link to={`${platformUrl}/products/${item?.relations?.product?.sku}`}>
                    {item?.relations?.product?.name} - {item?.relations?.product?.sku}.
                  </Link>
                }
              </>
            ) : (
              <> for this hardware</>
            )}
          </Text>
        )}
        {item?.eventName === 'configuration' && item.relations.configuration && (
          <Text variant="bodyLg">
            applied the configuration preset &nbsp;
            {
              <Link to={`/configurations/${item?.relations?.configuration.hardwareConfigCode}`}>
                {item?.relations?.configuration?.hardwareConfigName} -{' '}
                {item?.relations?.configuration.hardwareConfigCode}
              </Link>
            }{' '}
            to this hardware &nbsp;
            {item?.relations.applyConfigHistory && (
              <>
                as part of &nbsp;
                {
                  <Link to={`/configurations/${item?.relations?.applyConfigHistory.reportCode}`}>
                    {item?.relations?.applyConfigHistory?.reportName}.
                  </Link>
                }
              </>
            )}
          </Text>
        )}
        {item?.eventName === 'configuration' && !item.relations.configuration && (
          <Text variant="bodyLg">updated the configuration of this hardware</Text>
        )}
        {item?.eventName === 'productRecall' && (
          <Text variant="bodyLg" className="tw-inline-flex tw-flex-wrap">
            marked hardware as &nbsp; {<ProductTraceStatus status={item?.eventState as ProductTraceReportTypeT} />}{' '}
            &nbsp; as part of &nbsp;
            {
              <Link to={`/product-traces/${item?.relations?.productTrace.code}`}>
                {item?.relations?.productTrace.code}
              </Link>
            }{' '}
          </Text>
        )}
      </div>
      {item?.eventName === 'addGroup' && (
        <ViewItemOfGroupButton
          label="View All Hardware Within Group"
          data={item?.relations}
          drawerTitle={
            <>
              <Text
                variant="h2"
                component="h2"
                className="tw-flex tw-mb-2"
              >{`${item?.relations?.group?.title ?? ''} - ${item?.relations?.group?.code ?? ''}`}</Text>
              <Text variant="bodyLg" component="p" className="tw-block tw-text-grey-700 tw-font-normal">
                This hardware group information is accurate as of {DateTimeFormat(item?.createdAt)}
              </Text>
            </>
          }
        />
      )}
      <SizeBox height={16} />
      <Divider />
    </div>
  );
};

export default TrackingHistoryItem;
