import React, { useMemo } from 'react';
import './Information.scss';
import Divider from '../Divider';
import { HardwareI } from '../../interfaces/hardware';
import { convertVolumeTo2DP, getTrackingTypeFromApi, handleDisplayIconByTypeFromApi } from '../../utils/hardware';
import { CommonStatus } from '../CommonStatus';
import { Button, message, Progress, Tag } from 'antd';
import { DateFormat, DateTimeFormat } from '../../utils/global';
import DetailsView from '../DetailsView';
import SizeBox from '../SizeBox';
import IconText from '../IconText';
import NetworkStatus from '../shared/NetworkStatus';
import { HardwareModel } from '../HardwareModel';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Text from '../Text';
import Icon from '../Icon';
import DisplayQRCode from '../shared/DisplayQRCode';

type Props = {
  data: HardwareI;
  isPlatForm?: boolean;
  setCurrentTab?: (_: string) => void;
};

const TrackingInformation: React.FC<Props> = ({ data, isPlatForm, setCurrentTab }) => {
  const toteMaxVolumeInMl = Number(data?.ecoTote?.volume.split('L')[0] ?? 0) * 1000;
  const remainingVolumeInMl = Number(data?.ecoTote?.lastKnownVolume ?? 0);
  const dispensedVolumeInMl = toteMaxVolumeInMl - remainingVolumeInMl;
  const remainingPercent = ((remainingVolumeInMl ?? 0) * 100) / (toteMaxVolumeInMl ?? 1);
  const remainingVolumeInL = useMemo(() => {
    return remainingVolumeInMl / 1000;
  }, [remainingVolumeInMl]);
  const dispensedVolumeInL = useMemo(() => {
    return dispensedVolumeInMl / 1000;
  }, [dispensedVolumeInMl]);

  return (
    <div className="tracking-information-container tw-mb-4">
      <DetailsView title="Hardware Information">
        <div className="flex-row">
          <DetailsView.Field
            label="Hardware Type"
            value={
              <IconText
                icon={handleDisplayIconByTypeFromApi(data?.type, { color: '#191919', width: 20, height: 20 })}
                text={getTrackingTypeFromApi(data)}
              />
            }
          />
          <DetailsView.Field
            label="Hardware ID"
            value={
              <span className="tw-flex tw-items-center tw-gap-2">
                <Text variant="bodyLgB">{data?.serialCode}</Text>
                <CopyToClipboard text={data?.serialCode ?? ''} onCopy={() => message.info('Copied')}>
                  <Button className="tw-border-none tw-p-0">
                    <Icon name="icon-file_copy" size={20} className="tw-text-primary-500" />
                  </Button>
                </CopyToClipboard>
              </span>
            }
          />
          <DetailsView.Field
            label="Hardware QR Code"
            value={<DisplayQRCode data={data?.serialCode} downloadName={`${data?.serialCode}.png`} />}
          />
        </div>
        <Divider />
        <DetailsView.Field
          label="Status/Condition"
          value={
            <div className="tw-flex tw-gap-x-0.5 tw-flex-wrap">
              <CommonStatus status={data?.status} />
              <CommonStatus status={data?.condition} />
              {data?.condition === 'faulty' && (
                <a
                  href={isPlatForm ? '#platform-hardware-container' : '#tracking-resources'}
                  onClick={() => setCurrentTab?.('3')}
                  className="tw-px-3 tw-ml-3 tw-border tw-border-solid tw-border-error-600 tw-bg-white tw-text-error-600 hover:tw-text-error-600 tw-flex tw-gap-1 tw-items-center xs:tw-w-full xs:tw-mt-3 xs:tw-ml-0 xs:tw-justify-center"
                >
                  Go to Fault Report
                  <Icon name="icon-chevron_right" size={16} />
                </a>
              )}
            </div>
          }
        />
        <Divider />
        <div className="flex-row">
          <DetailsView.Field label="Hardware Owner" value={data?.owner?.legalName} />
          <DetailsView.Field label="Hardware Allocatee" value={data?.allocatee?.legalName} />
        </div>
        <Divider />
        <DetailsView.Field
          label="Site"
          value={
            data?.site?.legalName ? (
              <Tag>
                <Link to={`/sites/${data?.site?.id}`}>{data?.site?.legalName}</Link>
              </Tag>
            ) : (
              'Not Applicable'
            )
          }
        />
        <Divider />
        <DetailsView.Field
          label="Registered On"
          value={data?.createdAt ? DateTimeFormat(data?.createdAt) : 'Not Applicable'}
        />
        {/* <Divider />
        <DetailsView.Field label="Remarks" value={data?.remarks} /> */}
      </DetailsView>
      <SizeBox height={12} />
      {data?.ecoTote && (
        <DetailsView title="Hardware Specifics">
          <DetailsView.Field
            label="Barrel/Lot Number"
            value={data?.ecoTote?.lastKnownLotNumber ? `${data?.ecoTote?.lastKnownLotNumber}` : 'Not Applicable'}
          />
          <Divider />
          <div className="flex-row">
            <DetailsView.Field label="Volume Format" value={convertVolumeTo2DP(data?.ecoTote?.volume)} />
            <DetailsView.Field
              label="Last Filled Product"
              value={`${data?.ecoTote?.lastFilledProduct?.name ?? ''} ${data?.ecoTote?.lastFilledProduct?.sku ? `(${data?.ecoTote?.lastFilledProduct?.sku})` : 'Not Applicable'}`}
            />
          </div>
          <Divider />
          <Progress percent={Number(remainingPercent.toFixed(2))} type="line" size={['100%', 16]} showInfo={false} />
          <Divider />
          <div className="flex-row">
            <DetailsView.Field
              label="Remaining Volume"
              value={`${remainingVolumeInL <= 0.009 ? Math.floor(remainingVolumeInL).toFixed(2) : remainingVolumeInL.toFixed(2)}L`}
            />
            <DetailsView.Field label="Dispensed Volume" value={`${dispensedVolumeInL.toFixed(2)}L`} />
          </div>
        </DetailsView>
      )}
      {data?.smartPour && (
        <>
          <SizeBox height={12} />
          <DetailsView title="Hardware Specifics">
            <div className="flex-row">
              <DetailsView.Field label="Network Status" value={<NetworkStatus status={data?.smartPour?.network} />} />
              <DetailsView.Field label="Hardware Device ID" value={data?.smartPour?.devId} />
            </div>
            <div className="flex-row">
              <DetailsView.Field
                label="Hardware Model"
                value={<HardwareModel productId={data?.smartPour?.tuyaProductId ?? ''} />}
              />
              <DetailsView.Field label="Hardware Model ID" value={data?.smartPour?.tuyaProductId} />
            </div>
            <div className="flex-row">
              <DetailsView.Field label="Hardware MAC Address" value={data?.smartPour?.macAddress} />
            </div>
          </DetailsView>
        </>
      )}
      {data?.ecoPlant && (
        <DetailsView title="Hardware Specifics">
          <div className="flex-row">
            <DetailsView.Field
              label="Last Filled Date"
              value={data.ecoPlant.lastFilledDate ? DateFormat(data.ecoPlant.lastFilledDate) : ''}
            />
          </div>
        </DetailsView>
      )}
    </div>
  );
};

export default TrackingInformation;
