import { Spin } from 'antd';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetFaultReport } from '../../apis/faultReports';
import FaultReportTopInfo from '../../components/FaultReport/TopInfo';
import DetailsView from '../../components/DetailsView';
import { DateFormat } from '../../utils/global';
import { CommonStatus } from '../../components/CommonStatus';
import Attachments from '../../components/Attachments';
import IconText from '../../components/IconText';
import { getTrackingTypeFromApi, handleDisplayIconByTypeFromApi } from '../../utils/hardware';

const FaultReportDetailsPage: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const { data: faultReport, isFetching } = useGetFaultReport(code);
  return (
    <Spin spinning={isFetching}>
      <div className="tw-p-4">
        <FaultReportTopInfo data={faultReport} />
        <div className="tw-mb-4">
          <DetailsView title="Fault Report Details">
            {faultReport?.hardware && (
              <>
                <div className="flex-row">
                  <DetailsView.Field
                    label="Hardware Serial Code"
                    value={
                      <Link to={`/tracking/${faultReport?.hardware?.serialCode}`}>
                        {faultReport?.hardware?.serialCode}
                      </Link>
                    }
                  />
                  <DetailsView.Field
                    label="Hardware Type"
                    value={
                      <IconText
                        icon={handleDisplayIconByTypeFromApi(faultReport?.hardware?.type, {
                          color: '#191919',
                          width: 20,
                          height: 20,
                        })}
                        text={getTrackingTypeFromApi(faultReport?.hardware)}
                      />
                    }
                  />
                </div>
              </>
            )}
            <div className="flex-row">
              <DetailsView.Field label="Date Reported" value={DateFormat(faultReport?.createdAt)} />
              {faultReport?.dateResolved && (
                <DetailsView.Field label="Date Resolved" value={DateFormat(faultReport?.dateResolved)} />
              )}
            </div>
            <DetailsView.Field label="Status" value={<CommonStatus status={faultReport?.status ?? ''} />} />
            <div className="flex-row">
              <DetailsView.Field
                label="Fault Type"
                value={faultReport?.type === 'hardwareFault' ? 'Hardware Fault' : 'Product Fault'}
              />
              {faultReport?.type === 'productFault' && (
                <DetailsView.Field
                  label="Last Filled Product"
                  value={`${faultReport?.relations?.name} - ${faultReport?.relations?.sku ?? ''}`}
                />
              )}
            </div>
            <DetailsView.Field label="Fault Description" value={faultReport?.description} />
          </DetailsView>
        </div>
        <div className="tw-rounded-xl tw-bg-white tw-p-8 tw-shadow-md tw-flex tw-flex-col tw-flex-1 tw-mb-4">
          <Attachments attachments={faultReport?.attachments} />
        </div>
      </div>
    </Spin>
  );
};

export default FaultReportDetailsPage;
