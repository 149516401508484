import React from 'react';
import Text from '../Text';
import { Checkbox, Divider } from 'antd';
import SecondaryButton from '../buttons/SecondaryButton';

type Props = {
  handleOk: () => void;
  handleCheckboxChange: (_: any) => void;
};

const ReceiveWorkflowRequirements: React.FC<Props> = ({ handleOk, handleCheckboxChange }) => {
  return (
    <>
      <div>
        <Text variant="h3">Workflow Requirements</Text>
        <Text variant="bodyMd" className="tw-mt-3 tw-text-grey-700">
          Before you begin this process, please ensure that you have the following items ready:
        </Text>
        <div className="tw-border-primary tw-p-3 tw-bg-grey-50 tw-rounded-lg tw-mt-3">
          <div className="tw-flex tw-items-center tw-gap-2 tw-mb-2">
            <span className="tw-w-6 tw-h-6 tw-bg-white tw-rounded-full tw-inline-flex tw-items-center tw-justify-center tw-border-primary">
              1
            </span>
            <Text variant="labelMd">Site (Receive to)</Text>
          </div>
          <Text variant="bodyMd">
            Ensure that you have created the site that you will be receiving your hardware to within your entity
          </Text>
        </div>
        <div className="tw-border-primary tw-p-3 tw-bg-grey-50 tw-rounded-lg tw-mt-3">
          <div className="tw-flex tw-items-center tw-gap-2 tw-mb-2">
            <span className="tw-w-6 tw-h-6 tw-bg-white tw-rounded-full tw-inline-flex tw-items-center tw-justify-center tw-border-primary">
              2
            </span>
            <Text variant="labelMd">Hardware Serial Code(s)</Text>
          </div>
          <Text variant="bodyMd">Ensure the hardware you are about to scan has been registered</Text>
        </div>
      </div>
      <div className="tw-mt-4">
        <Divider className="tw-mt-4 tw-mb-4" />
        <Checkbox key="checkbox" onChange={handleCheckboxChange} className="tw-mb-3">
          <b>Do not show this message again</b>
        </Checkbox>
        <SecondaryButton label="Proceed" onClick={handleOk} className="tw-w-full" />
      </div>
    </>
  );
};

export default ReceiveWorkflowRequirements;
