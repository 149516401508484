import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import './HardwareCardItem.scss';
import { getTrackingTypeFromApi, handleDisplayIconByTypeFromApi } from '../../utils/hardware';
import { HardwareItemI } from '../../interfaces/hardware';
import { CommonStatus } from '../CommonStatus';
import Text from '../Text';
import Icon from '../Icon';
import { DateFormat } from '../../utils/global';
import NetworkStatus from '../shared/NetworkStatus';
import Divider from '../Divider';
import { IconName } from '../Icon/iconName';
import FaultyReportButton from '../buttons/FaultyReportButton';
import RedTriangleImg from '../../images/red-triangle.png';
import OrangeTriangleImg from '../../images/orange-triangle.png';
import ProductTraceStatusIcon from '../ProductTrace/StatusIcon';

type Props = {
  item: HardwareItemI;
};
const TrackingCardItem: React.FC<Props> = ({ item }) => {
  const navitate = useNavigate();

  const handleCardClick = (item: HardwareItemI) => {
    navitate(`/tracking/${item.serialCode}`);
  };

  const renderInfoByType = () => {
    return (
      <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-3">
        {item.type === 'ecotote' && (
          <>
            <Icon name="icon-water-drop" size={16} className=" tw-text-grey-700" />
            <span>
              {item?.ecoTote?.lastKnownVolume !== null
                ? (Number(item?.ecoTote?.lastKnownVolume) / 1000).toFixed(2)
                : (0).toFixed(2)}
              L
            </span>
          </>
        )}
        {item.type === 'ecoplant' && item?.ecoPlant?.lastFilledDate && (
          <>
            <Icon name="icon-date_range" size={16} className=" tw-text-grey-700" />
            <span>{item?.ecoPlant?.lastFilledDate ? DateFormat(item?.ecoPlant?.lastFilledDate) : ''}</span>
          </>
        )}
        {item.type === 'smartpour' && <NetworkStatus status={item?.smartPour?.network ?? null} />}
      </Text>
    );
  };

  const renderField = (props: { icon: IconName; value: ReactNode }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-mb-3">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <button className="hardware-card-item card-item" onClick={() => handleCardClick(item)}>
      {item?.productTraceStatus === 'productRecall' && (
        <img src={RedTriangleImg} className="tw-absolute tw-left-[-2px] tw-top-[-2px]" />
      )}
      {item?.productTraceStatus === 'onHold' && (
        <img src={OrangeTriangleImg} className="tw-absolute tw-left-[-2px] tw-top-[-2px]" />
      )}
      {item?.remarks && <Icon name="icon-text_snippet" className="btn-more-action" size={16} />}
      <div className="card-info tw-pb-3">
        <div>
          <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-1 tw-mb-1">
            {handleDisplayIconByTypeFromApi(item.type, { color: '#191919', width: 16, height: 16 })}
            <span>{getTrackingTypeFromApi(item)}</span>
          </Text>
          <Text variant="inputLabelLg" className="tw-mt-1 tw-mb-3 tw-text-primary-900 tw-flex tw-gap-1 tw-items-center">
            {`${item.serialCode}`}
            {(item?.productTraceStatus === 'productRecall' || item?.productTraceStatus === 'onHold') && (
              <ProductTraceStatusIcon status={item?.productTraceStatus} iconSize={12} />
            )}
          </Text>
          {renderField({ icon: 'icon-person', value: item?.owner?.displayName })}
          {!item?.site ? (
            <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-1 tw-mb-3">
              <Icon name="icon-location_on" size={16} className=" tw-text-grey-700" />
              <span>Unassigned Site</span>
            </Text>
          ) : (
            <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-1 tw-mb-3">
              <Icon name="icon-location_on" size={16} className=" tw-text-grey-700 tw-min-w-4" />
              <span>{item?.site?.displayName}</span>
            </Text>
          )}
          {renderInfoByType()}
        </div>
        <p className="status-section">
          {item?.status && <CommonStatus status={item?.status} />}
          {item?.condition && <CommonStatus status={item?.condition} />}
        </p>
      </div>
      <Divider />
      <div className="tw-flex tw-justify-between tw-py-2 tw-px-3 tw-min-h-[26px]">
        {!item.error ? (
          <FaultyReportButton data={item} hardwareType={item.type} />
        ) : (
          <span className="tw-leading-5 tw-mt-3"></span>
        )}
      </div>
    </button>
  );
};

export default TrackingCardItem;
