import { Form, message, Modal, Spin } from 'antd';
import React, { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';
import { useGetHardware } from '../../apis/hardwares';
import { HardwareGroupParamsI } from '../../interfaces/hardware';
import ConfigurationForm from '../../components/forms/configuration/Form';
import { updateHardwareConfiguration } from '../../apis/configurations';
import Text from '../../components/Text';

const ConfirmContent = () => {
  return (
    <>
      <h2>Proceed to update configuration?</h2>
      <Text variant="bodyLg">
        Upon confirmation, the configuration’s settings of this hardware will be updated and any previous applied
        configuration preset will be detached.
      </Text>
    </>
  );
};

const TrackingUpdateConfigurationPage: React.FC = () => {
  const { confirm } = Modal;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { serialCode } = useParams<{ serialCode: string }>();
  const { data: hardware, isFetching } = useGetHardware(serialCode);

  const { mutateAsync: updateMutate, isLoading: isPageLoading } = useMutation(updateHardwareConfiguration, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      message.info('Update successfully!');
      navigate(-1);
    },
    onError: () => {
      message.error('Update failed! Please make sure your hardware is available.');
    },
  });

  const handleSubmit = async (values: HardwareGroupParamsI) => {
    updateMutate({ serialCode: serialCode ?? '', values: values });
  };

  const configuration = useMemo(() => {
    if (hardware?.type === 'smartpour') {
      const data = hardware.smartPour;
      return {
        updatedAt: data.updatedAt,
        displayUnit: data.configDisplayUnit,
        ecoToteMode: data.configEcoToteMode,
        vsrMode: data.configVsrMode,
        gradientKMinus: data.configGradientKMinus,
        gradientKPlus: data.configGradientKPlus,
        productGroupCode: data.configProductGroupCode,
        values: {
          button1: data.configValues?.button1,
          button2: data.configValues?.button2,
          button3: data.configValues?.button3,
          button4: data.configValues?.button4 ?? 10,
          button5: data.configValues?.button5 ?? 10,
        },
        code: hardware.hardwareConfig?.code ?? undefined,
        name: hardware.hardwareConfig?.name ?? undefined,
      };
    }
  }, [hardware]);

  const handleConfirmFunction = () => {
    confirm({
      content: <ConfirmContent />,
      onOk() {
        form.submit();
      },
      okText: 'Confirm',
      cancelText: 'Back',
      wrapClassName: `confirm-content-wrapper`,
      closable: true,
      keyboard: true,
      width: 497,
    });
  };

  return (
    <Spin spinning={isFetching ?? isPageLoading}>
      <div className="tw-px-4">
        <CreateUpdateTopSection
          label="Edit Configuration"
          labelButton="Save"
          form={form}
          confirmFunction={handleConfirmFunction}
        />
        <ConfigurationForm
          form={form}
          handleSubmit={handleSubmit}
          type={hardware?.type}
          configuration={configuration}
          isHardwareUpdate={true}
          hardware={hardware}
        />
      </div>
    </Spin>
  );
};

export default TrackingUpdateConfigurationPage;
