import React from 'react';
import Text from '../components/Text';
import EcoPlantIllustration from '../svgs/illustrations/EcoPlantIllustration';
import EcoToteIllustration from '../svgs/illustrations/EcoToteIllustration';
import SmartPourIllustration from '../svgs/illustrations/SmartPourIllustration';

export const allocationGallery = () => [
  {
    image: <EcoToteIllustration />,
    text: (
      <Text variant="bodyLg" className="tw-text-left tw-mt-3 tw-min-h-24">
        <b>ecoTOTE</b> - QR/Barcode/NFC chip can be found on the outer casing
      </Text>
    ),
    position: 'bottom' as 'bottom' | 'center',
  },
  {
    image: <SmartPourIllustration />,
    text: (
      <Text variant="bodyMd" className="tw-text-left tw-mt-3 tw-min-h-24">
        QR/Barcode/NFC chip can be found:
        <ul className="tw-my-0 tw-pl-5">
          <li>
            <b>SmartPour 1.1S:</b> on the underside
          </li>
          <li>
            <b>SmartPour 2.0S:</b> on the inside of the rear door
          </li>
        </ul>
      </Text>
    ),
    position: 'center' as 'bottom' | 'center',
  },
  {
    image: <EcoPlantIllustration />,
    text: (
      <Text variant="bodyLg" className="tw-text-left tw-mt-3 tw-min-h-24">
        <b>ecoPLANT</b> - QR/Barcode/NFC chip can be found on the Filling Machine
      </Text>
    ),
    position: 'bottom' as 'bottom' | 'center',
  },
];
