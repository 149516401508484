import { BaseResponse } from '../interfaces';
import { EmailListCollectionI, EmailListDetailsData, EmailListDetailsSearchParamsI } from '../interfaces/emailList';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const getEmailLists = async (params?: any) => {
  const { data: response } = await api.get<BaseResponse<EmailListCollectionI>>(
    `email-list?${handleFilterParams(params)}`,
  );
  return response;
};

export const useGetEmailList = (id: number, params: EmailListDetailsSearchParamsI) => {
  const {
    data: response,
    error,
    isFetching,
  } = useFetch<{ data: EmailListDetailsData; status: number }>(`email-list/${id}?${handleFilterParams(params)}`);
  const data = response?.data;
  return { data, error, isFetching };
};

export const updateEmailListStatus = async (params: { id: number; status: string }) => {
  const { data: response }: any = await api.patch(`email-list/${params.id}/status`, { status: params.status });
  return response.data;
};

export const addEmailListEmail = async (id: number, formData: { username: string; email: string }) => {
  const { data: response }: any = await api.patch(`email-list/${id}/add-user`, formData);
  return response.data;
};

export const addEmailListExistingUsers = async (id: number, formData: { userIds: number[] }) => {
  const { data: response }: any = await api.patch(`email-list/${id}/add-exist-users`, formData);
  return response.data;
};

export const removeEmailListUser = async (id: number, formData: { email: string }) => {
  const { data: response }: any = await api.patch(`email-list/${id}/remove-user`, formData);
  return response.data;
};
