import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import Text from '../../components/Text';
import { getEmailLists } from '../../apis/emailLists';
import EmailListsTable from '../../components/tables/EmailLists';
import { handleFilterParams } from '../../utils/global';
import EmailListsCardList from '../../components/card-list/EmailLists';
import EmailListsFilterForm from '../../components/forms/email-list/Filter';
import { EmailList, EmailListSearchParamsI } from '../../interfaces/emailList';
import { Button, Dropdown, Spin } from 'antd';
import DisplayLastUpdateApp from '../../components/DisplayLastUpdate';
import SizeBox from '../../components/SizeBox';
import EmptyData from '../../components/EmptyData';

const EmailLists = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'email-lists');
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const status = urlSearch.get('filter[status]')?.split(',') ?? [];
    const name = urlSearch.get('search[name]');
    const viewMode = urlSearch.get('viewMode');
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    return {
      page,
      limit,
      viewMode,
      orderType,
      orderBy,
      filter: { status },
      search: name,
    } as EmailListSearchParamsI & { viewMode?: string };
  }, [urlSearch]);
  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);

  const {
    data,
    isFetching,
    refetch: refetchData,
  } = useQuery(['users', 'list', queryParams], () => getEmailLists(queryParams));
  const currentPage = queryParams?.page;

  const pagination: any = {
    total: data?.data?.total,
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    showTotal: (total: string) => `${total} Items`,
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const setQueryParams = (params: Partial<EmailListSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: EmailListSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanRead
                ? {
                    key: '1',
                    label: (
                      <Link to={`/email-lists/${record?.id}`}>
                        <Button className="btn-table-action-primary" type="link">
                          View
                        </Button>
                      </Link>
                    ),
                  }
                : null,
              access.isCanUpdate && currentUser.type !== 'ecoSpiritsAdmin'
                ? {
                    key: '2',
                    label: (
                      <Link to={`/email-lists/${record?.id}/edit`}>
                        <Button className="btn-table-action-primary" type="link">
                          Edit
                        </Button>
                      </Link>
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <Spin spinning={isFetching ?? isRefetchLoading}>
      <div className="users-container tw-flex tw-flex-col tw-gap-4 tw-p-4">
        <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
          <Text component="h3" variant="h3">
            Email List
          </Text>
        </div>
        <EmailListsFilterForm
          queryParams={queryParams}
          onChange={handleFilterChange}
          showView
          showSort
          refetchData={refetchData}
          setIsRefetchLoading={setIsRefetchLoading}
        />
        {!isFetching && data?.data?.emailLists?.length === 0 && (
          <EmptyData title="No Email" message="All emails within your business will be displayed here." />
        )}
        {!isFetching && data?.data?.emailLists?.length !== 0 && (
          <>
            <DisplayLastUpdateApp />
            <SizeBox height={8} />
            {!queryParams.viewMode ? (
              <EmailListsCardList
                access={access}
                data={data?.data?.emailLists ?? []}
                pagination={pagination}
                onPaginationChange={handleFilter}
              />
            ) : (
              <EmailListsTable
                rowKey="id"
                loading={isFetching}
                pagination={pagination}
                dataSource={data?.data?.emailLists.filter((item: EmailList) => item.name === 'Fault Notification')}
                withAction={withAction}
                onChange2={handleFilter}
              />
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default EmailLists;
