import { BaseResponse } from '../interfaces';
import { AttachmentI } from '../interfaces/attachments';
import { RegistrationHistorCollectionI, RegistrationHistoryI } from '../interfaces/registrationHistory';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetRigistrationHistory = (uuid?: string) => {
  const {
    data: response,
    error,
    isFetching,
  } = useFetch<{ data: RegistrationHistoryI }>(`registration-histories/${uuid}`);
  const data = response?.data;
  return { data, error, isFetching };
};

export const getRegistrationHistoriesList = async (params?: any) => {
  const { data: response } = await api.get<BaseResponse<RegistrationHistorCollectionI>>(
    `hardwares?${handleFilterParams(params)}`,
  );
  return response;
};

export const updateRegistrationHistories = async ({
  id,
  values,
}: {
  id: string;
  values: Partial<{ attachments: AttachmentI }>;
}) => {
  const { data: response }: any = await api.patch(`registration-histories/${id}`, values);
  return response.data;
};
