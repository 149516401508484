import { AttachmentI } from '../interfaces/attachments';
import { HardwareFaultReportI } from '../interfaces/hardwareFaultReports';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetFaultReport = (code?: string) => {
  const { data: response, error, isFetching } = useFetch<{ data: HardwareFaultReportI }>(`fault-reports/${code}`);
  const data = response?.data;
  return { data, error, isFetching };
};

export const createFaultReport = async (values: any) => {
  const { data: response }: any = await api.post('fault-reports', values);
  return response.data;
};

export const resolveFaultReport = async (params: {
  code: string;
  values: { resolutionComment: string; attachments: AttachmentI[] };
}) => {
  const { data: response }: any = await api.post(`fault-reports/${params.code}/resolve`, params.values);
  return response.data;
};
