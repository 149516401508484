import { BaseResponse } from '../interfaces';
import { AttachmentI } from '../interfaces/attachments';
import {
  FillingParamsI,
  HardwareFillingHistoryCollectionI,
  HardwareFillingHistoryI,
} from '../interfaces/hardwareFilling';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetFillingHistory = (uuid?: string) => {
  const {
    data: response,
    error,
    isFetching,
  } = useFetch<{ data: HardwareFillingHistoryI }>(`filling-histories/${uuid}`);
  const data = response?.data;
  return { data, error, isFetching };
};

export const getFillingHistoriesList = async (params?: any) => {
  const { data: response } = await api.get<BaseResponse<HardwareFillingHistoryCollectionI>>(
    `filling-histories?${handleFilterParams(params)}`,
  );
  return response;
};

export const doFillingHardware = async (values: FillingParamsI) => {
  const { data: response }: any = await api.post('hardwares/filling', values);
  return response.data;
};

export const updateFillingHistories = async ({
  id,
  values,
}: {
  id: string;
  values: Partial<{ attachments: AttachmentI }>;
}) => {
  const { data: response }: any = await api.patch(`filling-histories/${id}`, values);
  return response.data;
};
