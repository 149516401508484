import { BaseResponse } from '../interfaces';
import { AllocationHistoryI } from '../interfaces/allocationHistory';
import { useFetch } from './reactQuery';

export const useGetAllocationHistory = (uuid?: string) => {
  const {
    data: response,
    error,
    isFetching,
  } = useFetch<BaseResponse<AllocationHistoryI>>(`allocation-histories/${uuid}`);
  const data = response?.data;
  return { data, error, isFetching };
};
