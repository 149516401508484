import { Spin } from 'antd';
import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetEmailList } from '../../apis/emailLists';
import DetailsView from '../../components/DetailsView';
import { CommonStatus } from '../../components/CommonStatus';
import EmailListTopInfo from '../../components/EmailList/TopInfo';
import EmailListEmailsTable from '../../components/tables/EmailListEmails';
import EmailListsFilterForm from '../../components/forms/email-list/Filter';
import { PAGE_SIZE } from '../../constants';
import { EmailListDetailsSearchParamsI } from '../../interfaces/emailList';
import { handleFilterParams } from '../../utils/global';
const EmailListDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const name = urlSearch.get('search[name]');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      viewMode,
      search: name,
    } as EmailListDetailsSearchParamsI & { viewMode?: string };
  }, [urlSearch]);
  const { data, isFetching } = useGetEmailList(Number(id), queryParams);
  if (!data?.emailList) return null;

  const setQueryParams = (params: Partial<EmailListDetailsSearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`, { replace: true });
  };
  const handleFilterChange = (values: EmailListDetailsSearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  return (
    <Spin spinning={isFetching}>
      <div className="tw-p-4">
        <EmailListTopInfo data={data?.emailList} />
        <div id="page-role-details-container">
          <DetailsView title="Email List Details">
            <DetailsView.Field
              label="List Name"
              value={data?.emailList?.name === 'Product Recall' ? 'Product Trace' : data?.emailList?.name}
            />
            <DetailsView.Field label="Status" value={<CommonStatus status={data?.emailList.status || ''} />} />
            <DetailsView.Field label="Description" value={data?.emailList?.description} />
          </DetailsView>
          <div className="content-wrap" style={{ marginTop: 16 }}>
            <div className="details-container">
              <h2 className={`details-title tw-flex tw-justify-between tw-items-center`}>Emails</h2>
              <div className="tw-flex tw-flex-row tw-gap-2 tw-mb-4 ">
                <EmailListsFilterForm queryParams={queryParams} onChange={handleFilterChange} />
              </div>
              <EmailListEmailsTable dataSource={data.emailListUsers} />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default EmailListDetailsPage;
