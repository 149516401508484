import React from 'react';
import DetailsView from '../DetailsView';
import ProductTraceCurrentVolumeChart from './CurrentVolumeChart';
import { ProductTraceI, ProductTraceReportTypeT } from '../../interfaces/productTrace';
import ProductTraceVolumeHeldChart from './VolumeHeldChart';
import ProductTraceEmailsSentChart from './EmailsSentChart';
import DisplayLastUpdateApp from '../DisplayLastUpdate';
import RefreshButton from '../buttons/RefreshButton';
import { Notice } from '../Notice';
import Text from '../Text';
import { DateTimeFormat } from '../../utils/global';

type Props = {
  data?: ProductTraceI['situationData'];
  emailsSent?: ProductTraceI['emailInfo'];
  reportType?: ProductTraceReportTypeT;
  refetch: () => void;
  setIsRefetchLoading?: (value: boolean) => void;
  resolvedUpdatedAt?: string;
};

const ProductTraceSituationReport: React.FC<Props> = ({
  data,
  emailsSent,
  reportType = 'trace',
  resolvedUpdatedAt,
  refetch,
  setIsRefetchLoading,
}) => {
  return (
    <DetailsView title="Situation Report (Live)">
      {reportType === 'resolved' && resolvedUpdatedAt && (
        <Notice
          message={
            <Text variant="bodyMdB">
              The report has been updated to "Resolved" as of: {DateTimeFormat(resolvedUpdatedAt)}{' '}
            </Text>
          }
          description="Please note that any actions or interactions occuring after this timestamp will not be considered in the generation of this situation report"
          type="info"
          closable={false}
          iconSize={16}
        />
      )}
      <div className="tw-flex tw-justify-between">
        <DisplayLastUpdateApp />
        <RefreshButton doRefetch={refetch} setIsLoading={setIsRefetchLoading} />
      </div>
      <div className="tw-gap-6 tw-grid tw-grid-cols-2 sm:tw-grid-cols-1">
        <ProductTraceCurrentVolumeChart data={data?.currentVolume} />
        <ProductTraceVolumeHeldChart data={data?.volumeHeldSiteTypes} />
      </div>
      {reportType !== 'trace' && <ProductTraceEmailsSentChart data={emailsSent} />}
    </DetailsView>
  );
};

export default ProductTraceSituationReport;
