import React from 'react';
import { Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DateFormat } from '../../utils/global';
import Text from '../Text';
import Icon from '../Icon';
import { IconName } from '../Icon/iconName';
import { TransferI } from '../../interfaces/transfer';
import TooltipIcon from '../buttons/TooltipIcon';

type Props = {
  item: TransferI;
};
const TransferCardItem: React.FC<Props> = ({ item }) => {
  const navitate = useNavigate();

  const handleCardClick = (item: TransferI) => {
    navitate(`/transfers/${item.code}`);
  };

  const renderField = (props: { icon: IconName; value: string }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };

  return (
    <button className="hardware-card-item card-item" onClick={() => handleCardClick(item)}>
      {item?.remarks && (
        <TooltipIcon
          icon="icon-text_snippet"
          text="Remarks"
          className="btn-more-action"
          iconClassName="tw-text-grey-800"
        />
      )}
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
        <Text variant="inputLabelSm" className="tw-text-grey-700">
          Transfer Report
        </Text>
        <Text variant="bodyLgB" className="tw-text-primary-800">
          {item?.code}
        </Text>
        <div className="tw-flex tw-flex-col tw-gap-2">
          {renderField({ icon: 'icon-person_outline', value: item?.newOwner?.displayName })}
          {renderField({ icon: 'icon-all_inbox', value: `${item?.hardwareList?.length ?? 0} Hardware` })}
        </div>
        <Divider className="tw-m-0" />
        {renderField({ icon: 'icon-calendar_today', value: `${DateFormat(item?.createdAt)}` })}
      </div>
    </button>
  );
};

export default TransferCardItem;
