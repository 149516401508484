import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useBrowserBackward = (path: string) => {
  const navigate = useNavigate();
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      navigate(path, { replace: true });
    };
    window.addEventListener('popstate', handleBeforeUnload);
    return () => {
      window.removeEventListener('popstate', handleBeforeUnload);
    };
  }, []);
};

export default useBrowserBackward;
