import React, { useMemo } from 'react';
import { Checkbox } from 'antd';
import ActionSection from './ActionSection';
import { ModuleI } from '../../interfaces/module';
import { RoleI } from '../../interfaces/role';
import { CheckboxOptionI } from '../../interfaces';
import { getModulePermissions } from '../../utils/global';

type Props = {
  modules?: ModuleI[];
  role?: RoleI;
};

const RoleDashboardCollapseDetail: React.FC<Props> = ({ modules, role }) => {
  const productCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'ProductPerformance')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  const hardwareCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'HardwarePerformance')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  const siteCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'SitePerformance')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  const reportsCheckboxOptions: CheckboxOptionI[] = useMemo(() => {
    const permissions = modules?.find((module: ModuleI) => module.name === 'Report')?.permissions ?? [];
    return getModulePermissions(permissions);
  }, [modules]);

  return (
    <>
      <ActionSection
        label="Product Performance"
        content={<Checkbox.Group options={productCheckboxOptions} value={role?.colProductPerformance} />}
      />
      <ActionSection
        label="Hardware Performance"
        content={<Checkbox.Group options={hardwareCheckboxOptions} value={role?.colHardwarePerformance} />}
      />
      <ActionSection
        label="Site Performance"
        content={<Checkbox.Group options={siteCheckboxOptions} value={role?.colSitePerformance} />}
      />
      <ActionSection
        label="Reports"
        content={<Checkbox.Group options={reportsCheckboxOptions} value={role?.colReport} />}
      />
    </>
  );
};

export default RoleDashboardCollapseDetail;
