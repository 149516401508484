import { Carousel } from 'antd';
import React, { ReactNode } from 'react';
import './ScanIllutrationGallery.scss';

type Props = {
  gallery: { image: ReactNode; text: ReactNode; position: 'bottom' | 'center' }[];
};

const ScanIllutrationGallery: React.FC<Props> = ({ gallery }) => {
  return (
    <div className="scan-illutration-gallery-container tw-mb-6">
      <Carousel autoplay={true} arrows infinite={true}>
        {gallery &&
          gallery.map((item, index) => {
            return (
              <div className="" key={index}>
                <div
                  className={`tw-w-[450px] tw-h-[310px] tw-border-primary tw-bg-white tw-rounded-2xl tw-flex ${item.position === 'center' ? 'tw-items-center' : 'tw-items-end'} tw-justify-center`}
                >
                  {item.image}
                </div>
                {item.text}
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};

export default ScanIllutrationGallery;
