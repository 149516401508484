import React, { useState } from 'react';
import Text from '../Text';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import ProductTrackAffectedPartnersTable from '../tables/ProductTraceAffectedPartners';
import { ProductTraceI, ProductTracePartnerT, ProductTraceReportTypeT } from '../../interfaces/productTrace';

type Props = {
  verifiedData?: ProductTraceI;
  reportType?: ProductTraceReportTypeT;
  selectedPartners: { partners: number[]; ecoToteNo: number };
  setCurrentStep: (_: number) => void;
  setSelectedPartners: (_: { partners: number[]; ecoToteNo: number; recipients: number }) => void;
};

const ProductTraceAffectedPartners: React.FC<Props> = ({
  verifiedData,
  reportType,
  selectedPartners,
  setCurrentStep,
  setSelectedPartners,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onContinue = () => {
    setCurrentStep(5);
  };

  const onPreviousStepClick = () => {
    setCurrentStep(3);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const selectedData: ProductTracePartnerT[] =
      verifiedData?.partners.filter((partner) => newSelectedRowKeys.includes(partner.id)) ?? [];
    const partners = selectedData.map((partner) => partner.id);
    const ecoToteNo = selectedData?.reduce((acc, partner) => acc + partner.ecoToteIds.length, 0);
    const recipients = selectedData?.reduce((acc, partner) => acc + partner.recipients, 0);
    setSelectedPartners({ partners, ecoToteNo, recipients });
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="tw-mt-4">
      {reportType !== 'trace' && (
        <div className="tw-max-w-[618px] tw-my-0 tw-mx-auto">
          <Text variant="h4" component="h4" className="tw-w-full tw-text-center">
            Select Partners to Notify for Product Recall or Product Holding Instructions{' '}
          </Text>
          <Text variant="bodyLg" className="tw-w-full tw-text-center tw-mt-2 tw-text-grey-700">
            Please choose the partners from the list below to notify regarding the request to conduct a product recall
            or product holding instructions.
          </Text>
          <Text variant="bodyLg" className="tw-w-full tw-text-center tw-my-6">
            <span className="tw-font-semibold">{selectedPartners.partners.length}</span> partner(s) -{' '}
            <span className="tw-font-semibold">{selectedPartners.ecoToteNo}</span> ecoTOTE(s) selected
          </Text>
        </div>
      )}
      <ProductTrackAffectedPartnersTable
        rowKey="id"
        dataSource={verifiedData?.partners ?? []}
        rowSelection={reportType !== 'trace' ? rowSelection : undefined}
        reportType={reportType}
        isCreateReport={true}
      />
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label="Previous Step" onClick={onPreviousStepClick} />
          <PrimaryButton label={'Continue'} onClick={onContinue} />
        </div>
      </div>
    </div>
  );
};

export default ProductTraceAffectedPartners;
