import { Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetConfiguration } from '../../apis/configurations';
import ConfigurationTopSection from '../../components/Configuration/TopSection';
import ConfigurationInformation from '../../components/Configuration/Information';
import ConfigurationResources from '../../components/Configuration/Resources';

const ConfigurationDetailsPage: React.FC = () => {
  const { code } = useParams<{ code?: string }>();
  const { data: config, isFetching } = useGetConfiguration(code);

  return (
    <Spin spinning={isFetching}>
      <div className="tw-p-4">
        <ConfigurationTopSection data={config} />
        <ConfigurationInformation data={config} />
        <ConfigurationResources data={config} />
      </div>
    </Spin>
  );
};

export default ConfigurationDetailsPage;
