import { Modal, Tabs, TabsProps } from 'antd';
import React, { Fragment, useState } from 'react';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import { doScanSite } from '../../apis/hardwareAllocations';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import ConfirmButton from '../buttons/ConfirmButton';
import PrimaryButton from '../buttons/PrimaryButton';
import SiteIllustration from '../../svgs/illustrations/SiteIllustration';
import Text from '../Text';
import FillingScanQRCode from '../HardwareFilling/ScanQRCode';
import SiteInputManual from './Manual';
import Icon from '../Icon';
import { getCountryFromCode } from '../../utils/global';
import Divider from '../Divider';
import { PartnerStatus } from '../../pages/Partners/PartnerStatus';
import { SiteReceiveScannedT } from '../../interfaces/hardwareAllocation';

const DiscardConfirmContent = () => {
  return (
    <div>
      <h2>Are you sure you want to close this Scanning session?</h2>
      <p>All your currently scanned Site Reference Code will be lost.</p>
    </div>
  );
};

const ScanNoticeMessage = () => {
  return (
    <span>
      <b>Site Reference Code</b> can be found under Site Details of any site within your entity or your partner’s entity
    </span>
  );
};

type Props = {
  siteReceiveData?: SiteReceiveScannedT;
  setSiteReceiveData: (_: SiteReceiveScannedT | undefined) => void;
  setCurrentStep: (_: number) => void;
};

const AllocationScanSiteStep: React.FC<Props> = ({ siteReceiveData, setSiteReceiveData, setCurrentStep }) => {
  const [modal, contextHolder] = Modal.useModal();
  const [stopDecodingScanner, setStopDecodingScanner] = useState<boolean>(false);
  const [screen, setScreen] = useState<'start' | 'scan' | 'scanned'>('start');

  const validateScanResult = async ({ text }: { text: string }) => {
    try {
      const result = await doScanSite(text);
      setSiteReceiveData(result);
      setScreen('scanned');
    } catch (error: any) {
      const errorModal = modal.error({
        type: 'error',
        title: 'Failed Scanning!',
        content:
          'We are unable to recognise this Site Reference Code. Please ensure that the receiving site is active and the Site Reference Code is correct.',
        className: 'context-modal-custom-container',
        closable: true,
        centered: true,
        footer: null,
        icon: <ErrorModalIcon color="#B13939" />,
        width: 345,
      });

      setTimeout(() => {
        errorModal.destroy();
      }, 1000);
    }
  };

  const handleScanOrManualSubmit = (result: { text: string }) => {
    validateScanResult(result);
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <FillingScanQRCode
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          title="Site Reference Code"
          scanNoticeMessage={<ScanNoticeMessage />}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <SiteInputManual
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanNoticeMessage={<ScanNoticeMessage />}
        />
      ),
    },
  ];

  const handleFinishScanning = () => {
    setCurrentStep(5);
    setStopDecodingScanner(true);
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  const handleDiscard = () => {
    setScreen('start');
    setSiteReceiveData(undefined);
  };

  const handleRescan = () => {
    setScreen('scan');
    setSiteReceiveData(undefined);
    setStopDecodingScanner(false);
  };

  return (
    <div className="component-hardware-allocation-scan-step tw-px-4 tw-py-6">
      <div className="tw-w-[680px] tw-mx-auto tw-my-0">
        {screen === 'scan' && <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} />}
        {screen === 'start' && (
          <div className="tw-w-[450px] tw-mx-auto tw-my-0">
            <div>
              <SiteIllustration />
            </div>
            <Text variant="bodyLg" className="tw-mt-3">
              <b>Site Reference Code of your receiving site</b> can be found under Site Details of any site within your
              entity or your partner’s entity.
            </Text>
          </div>
        )}
        {screen === 'scanned' && (
          <div className="tw-w-[340px] tw-mx-auto tw-my-0">
            <Text variant="bodyLg" className="tw-flex tw-justify-center tw-mb-3 tw-text-grey-700">
              1 Site (Receive) Scanned:
            </Text>
            <div className="tw-bg-white tw-rounded-lg tw-p-3 tw-flex tw-justify-between tw-flex-col">
              <Text variant="inputLabelSm" className="tw-text-success-500">
                {siteReceiveData?.partner?.displayName ?? 'Not Assigned'}
              </Text>
              <div className="tw-flex tw-flex-col tw-gap-3 tw-mb-3">
                <Text variant="bodyLgB">{siteReceiveData?.displayName}</Text>
                <div className="tw-flex tw-gap-1 tw-items-start">
                  <Icon name="icon-receipt" size={14} className="tw-text-grey-600 tw-flex-none tw-min-w-4" />
                  <Text variant="inputLabelSm" className="truncate-3-lines">
                    {siteReceiveData?.referenceCode}
                  </Text>
                </div>
                <div className="tw-flex tw-gap-1 tw-items-start">
                  <Icon name="icon-location_on" size={14} className="tw-text-grey-600 tw-flex-none tw-min-w-4" />
                  <Text variant="inputLabelSm" className="truncate-3-lines">
                    {' '}
                    {[siteReceiveData?.addressLine1, siteReceiveData?.addressLine2, siteReceiveData?.postalCode]
                      .filter((item) => item !== null)
                      .join(', ')}{' '}
                    {getCountryFromCode(siteReceiveData?.country ?? 'SG')}, {siteReceiveData?.postalCode}
                  </Text>
                </div>
              </div>
              <div>
                <Divider />
                <div className="tw-pt-3">
                  <PartnerStatus status={siteReceiveData?.status ?? ''} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {contextHolder}
      <div className="hardware-footer-section">
        <div>
          {screen === 'scanned' && (
            <>
              <ConfirmButton label="Rescan" onOk={handleRescan} content={<DiscardConfirmContent />} type="error" />
              <PrimaryButton label="Finish Scanning" onClick={handleFinishScanning} />
            </>
          )}
          {screen === 'scan' && (
            <>
              <ConfirmButton label="Discard" onOk={handleDiscard} content={<DiscardConfirmContent />} type="error" />
              <PrimaryButton label="Continue" onClick={handleFinishScanning} />
            </>
          )}
          {screen === 'start' && <PrimaryButton label="Start Scanning" onClick={() => setScreen('scan')} />}
        </div>
      </div>
    </div>
  );
};

export default AllocationScanSiteStep;
