import { Box, DatePicker, OptionList, Popover, Scrollable, useBreakpoints, Select } from '@shopify/polaris';
import React, { Dispatch, useEffect, useState } from 'react';
import Icon from './Icon';
import { Button, Input } from 'antd';
import { DateRangeT } from '../interfaces';
import Text from './Text';
import './DateRangePicker.scss';
import PrimaryButton from './buttons/PrimaryButton';
import SecondaryButton from './buttons/SecondaryButton';
import { DateFormat } from '../utils/global';

type Props = {
  ranges: DateRangeT[];
  activeDateRange?: DateRangeT;
  isDisabled?: boolean;
  setActiveDateRange?: Dispatch<React.SetStateAction<DateRangeT>>;
  handleDateTimeChange?: (params: any) => void;
};

const DateRangePicker: React.FC<Props> = ({
  ranges,
  activeDateRange,
  isDisabled = false,
  setActiveDateRange,
  handleDateTimeChange,
}) => {
  const { mdDown, lgUp } = useBreakpoints();
  const shouldShowMultiMonth = lgUp;
  const [isError, setIsError] = useState<boolean>(false);

  const [popoverActive, setPopoverActive] = useState<boolean>(false);
  const [inputValues, setInputValues] = useState<{ since?: string; until?: string }>({});
  const [{ month, year }, setDate] = useState<{ month: number; year: number }>({
    month: activeDateRange?.period.since.getMonth() ?? 0,
    year: activeDateRange?.period.since.getFullYear() ?? 0,
  });
  const VALID_YYYY_MM_DD_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}/;

  function isDate(date: string): boolean {
    return !isNaN(new Date(date).getDate());
  }

  function isValidYearMonthDayDateString(date: string): boolean {
    return VALID_YYYY_MM_DD_DATE_REGEX.test(date) && isDate(date);
  }

  function isValidDate(date: string): boolean {
    return date.length === 10 && isValidYearMonthDayDateString(date);
  }

  function parseYearMonthDayDateString(input: string): Date {
    const [year, month, day] = input.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  function formatDateToYearMonthDayDateString(date: Date): string {
    const year = String(date.getFullYear());
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    if (month.length < 2) {
      month = String(month).padStart(2, '0');
    }
    if (day.length < 2) {
      day = String(day).padStart(2, '0');
    }
    return [year, month, day].join('-');
  }

  function formatDate(date: Date): string {
    return formatDateToYearMonthDayDateString(date);
  }

  function handleStartInputValueChange(value: string): void {
    setInputValues((prevState) => {
      return { ...prevState, since: value };
    });
    if (isValidDate(value)) {
      const newSince = parseYearMonthDayDateString(value);
      setActiveDateRange?.((prevState: DateRangeT) => {
        const newPeriod =
          prevState.period && newSince <= prevState.period.until
            ? { since: newSince, until: prevState.period.until }
            : { since: newSince, until: newSince };

        return {
          ...prevState,
          period: newPeriod,
        } as DateRangeT;
      });
    }
  }

  function handleEndInputValueChange(value: string): void {
    setInputValues((prevState) => ({ ...prevState, until: value }));
    if (isValidDate(value)) {
      const newUntil = parseYearMonthDayDateString(value);
      setActiveDateRange?.((prevState) => {
        const newPeriod =
          prevState.period && newUntil >= prevState.period.since
            ? { since: prevState.period.since, until: newUntil }
            : { since: newUntil, until: newUntil };
        return {
          ...prevState,
          period: newPeriod,
        };
      });
    }
  }

  function handleMonthChange(month: number, year: number): void {
    setDate({ month, year });
  }

  function handleCalendarChange({ start, end }: { start: Date; end: Date }): void {
    const newDateRange = ranges.find((range) => {
      return range.period.since.valueOf() === start.valueOf() && range.period.until.valueOf() === end.valueOf();
    }) || {
      alias: 'custom',
      title: 'Custom Range',
      period: {
        since: start,
        until: end,
      },
    };
    setActiveDateRange?.(newDateRange);
    setIsError(false);
  }

  function apply(): void {
    setPopoverActive(false);
    handleDateTimeChange?.(activeDateRange);
  }

  function cancel(): void {
    setPopoverActive(false);
  }

  useEffect(() => {
    if (activeDateRange) {
      setInputValues({
        since: formatDate(activeDateRange.period.since),
        until: formatDate(activeDateRange.period.until),
      });
      const monthDifference = monthDiff(
        { year, month },
        {
          year: activeDateRange.period.until.getFullYear(),
          month: activeDateRange.period.until.getMonth(),
        },
      );
      if (monthDifference > 1 || monthDifference < 0) {
        setDate({
          month: activeDateRange.period.until.getMonth(),
          year: activeDateRange.period.until.getFullYear(),
        });
      }
    }
  }, [activeDateRange]);

  function monthDiff(referenceDate: { year: number; month: number }, newDate: { year: number; month: number }): number {
    return newDate.month - referenceDate.month + 12 * (referenceDate.year - newDate.year);
  }

  const buttonValue = React.useMemo(() => {
    if (activeDateRange) {
      const { since, until } = activeDateRange.period;
      if (activeDateRange?.alias === 'custom') {
        if (since.toDateString() === until.toDateString()) {
          return `Today (${DateFormat(since)})`;
        }
        return `${DateFormat(activeDateRange.period.since)} - ${DateFormat(activeDateRange.period.until)}`;
      }
      if (activeDateRange) {
        return `${activeDateRange.title} (${DateFormat(since)} - ${DateFormat(until)})`;
      }
    }
  }, [activeDateRange]);

  return (
    <Popover
      active={popoverActive}
      autofocusTarget="none"
      preferredAlignment="left"
      preferredPosition="below"
      fluidContent
      sectioned={false}
      fullHeight
      activator={
        <Button
          onClick={() => setPopoverActive(!popoverActive)}
          className="tw-w-full !tw-px-3 !tw-py-2 hover:tw-text-grey-800"
          disabled={isDisabled}
        >
          <div className="tw-flex tw-w-full tw-justify-between tw-items-center">
            <span className="tw-flex tw-items-center tw-gap-2">
              <Icon name="icon-date_range" size={16} className="tw-text-grey-700" />
              <Text variant="bodyMd" className="tw-text-grey-900">
                {buttonValue}
              </Text>
            </span>
            <Icon name="icon-expand_more" size={16} />
          </div>
        </Button>
      }
      onClose={() => setPopoverActive(false)}
    >
      <Popover.Pane fixed>
        <div className="tw-flex sm:tw-flex-col">
          <Box
            maxWidth={mdDown ? '100%' : '212px'}
            width={mdDown ? '100%' : '212px'}
            padding={{ xs: '500', md: '0' }}
            paddingBlockEnd={{ xs: '100', md: '0' }}
          >
            {mdDown ? (
              <div className="tw-p-3">
                <Select
                  label="dateRangeLabel"
                  labelHidden
                  onChange={(value) => {
                    const result = ranges.find(({ title, alias }) => title === value || alias === value);
                    setActiveDateRange?.(result!);
                  }}
                  value={activeDateRange?.title || activeDateRange?.alias || ''}
                  options={ranges.map(({ alias, title }) => title || alias)}
                />
              </div>
            ) : (
              <Scrollable style={{ height: '300px' }}>
                <OptionList
                  options={ranges.map((range) => ({
                    value: range.alias,
                    label:
                      range.alias === 'custom' ? (
                        <div>
                          <Text variant="bodyMd">{`Custom Range`}</Text>
                          <Text variant="bodyMd" className="tw-text-grey-600">
                            {DateFormat(activeDateRange?.period.since) +
                              ' - ' +
                              DateFormat(activeDateRange?.period.until)}
                          </Text>
                        </div>
                      ) : (
                        <Text variant="bodyMd">{range.title}</Text>
                      ),
                  }))}
                  selected={[activeDateRange?.alias ?? '']}
                  onChange={(value) => {
                    setActiveDateRange?.(ranges.find((range) => range.alias === value[0])!);
                  }}
                />
              </Scrollable>
            )}
          </Box>
          <Box padding={{ xs: '100' }} maxWidth={mdDown ? '100%' : '516px'}>
            <div className="tw-p-3">
              <DatePicker
                month={month}
                year={year}
                selected={{
                  start: activeDateRange?.period.since ?? new Date(),
                  end: activeDateRange?.period.until ?? new Date(),
                }}
                onMonthChange={handleMonthChange}
                onChange={handleCalendarChange}
                multiMonth={shouldShowMultiMonth}
                allowRange
              />
            </div>
          </Box>
        </div>
      </Popover.Pane>
      <div className="tw-flex footer tw-w-full tw-justify-between tw-bg-grey-200 tw-p-4 sm:tw-flex-col">
        <div className="tw-flex tw-items-center tw-gap-2">
          <Input
            placeholder="Since"
            value={inputValues.since}
            onChange={(value) => handleStartInputValueChange(value.target.value)}
            // onBlur={handleInputBlur}
            autoComplete="off"
            className="tw-flex-grow tw-max-w-[130px] disabled:tw-bg-white disabled:tw-text-grey-900"
            disabled={activeDateRange?.alias !== 'custom'}
          />
          -
          <Input
            placeholder="Until"
            value={inputValues.until}
            onChange={(value) => handleEndInputValueChange(value.target.value)}
            // onBlur={handleInputBlur}
            autoComplete="off"
            className="tw-flex-grow tw-max-w-[130px] disabled:tw-bg-white disabled:tw-text-grey-900"
            disabled={activeDateRange?.alias !== 'custom'}
          />
        </div>
        <div className="tw-flex tw-gap-2 sm:tw-w-full sm:tw-my-3">
          <SecondaryButton label="Cancel" onClick={cancel} className="sm:tw-flex-1" />
          <PrimaryButton label="Confirm" onClick={apply} disabled={isError} className="sm:tw-flex-1" />
        </div>
      </div>
    </Popover>
  );
};

export default DateRangePicker;
