import React, { Fragment, useState } from 'react';
import Text from '../Text';
import { Tabs, TabsProps } from 'antd';
import PrimaryButton from '../buttons/PrimaryButton';
import { doVerifyOwnerTransfer } from '../../apis/transfers';
import { useAppNotification } from '../../hooks/useAppNotification';
import { PartnerI } from '../../interfaces/partner';
import Avatar from '../User/Avatar';
import { CommonStatus } from '../CommonStatus';
import SecondaryButton from '../buttons/SecondaryButton';
import Icon from '../Icon';
import TransferIllustration from '../../svgs/illustrations/TransferIllustration';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import SharedScanQRCode from '../shared/Hardware/ScanQRCodeScreen';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import SharedManualScreen from '../shared/Hardware/ManualScreen';
import NoticeIcon from '../../svgs/NoticeIcon';

const ScanTabsContent = () => {
  return (
    <div className="tw-w-[450px] tw-flex tw-gap-3 tw-mb-6 tw-mx-auto tw-mt-0">
      <div className="tw-max-w-4">
        <NoticeIcon width={16} height={16} />
      </div>
      <Text variant="inputLabelSm">
        Partner Reference Code can be retrieved from the Partner Information of your receiving partner
      </Text>
    </div>
  );
};

type Props = {
  partner?: PartnerI;
  stopDecodingScanner: boolean;
  setCurrentStep: (_: number) => void;
  setPartner: (_: PartnerI | undefined) => void;
  setStopDecodingScanner: (_: any) => void;
};

const TransferToScreen: React.FC<Props> = ({
  partner,
  stopDecodingScanner,
  setPartner,
  setCurrentStep,
  setStopDecodingScanner,
}) => {
  const { openNotification } = useAppNotification();
  const [screen, setScreen] = useState<'start' | 'scan' | 'scanned'>('start');
  const [errorMessage, setErrorMessage] = useState<string>();

  const onFinish = async (values: { text: string }) => {
    try {
      const data = await doVerifyOwnerTransfer(values.text);
      setPartner(data);
      setScreen('scanned');
      setErrorMessage('');
    } catch (error) {
      openNotification({
        type: 'error',
        title: 'Partner does not exist!',
        content:
          'We cannot recognise this Reference Code. Please check with your Partner again for correct Reference Code and make sure their Partner’s status is Active.',
      });
      setErrorMessage('Reference Code doesn’t exist. Please check and type again');
      return Promise.resolve();
    }
  };

  const handleScanOrManualSubmit = (result: { text: string; isManualInput: boolean }) => {
    onFinish(result);
  };

  const doReEnter = () => {
    setPartner(undefined);
    setScreen('start');
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <SharedScanQRCode
          title={'Partner Reference Code'}
          handleScanOrManualSubmit={onFinish}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          scanTabsContent={<ScanTabsContent />}
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <SharedManualScreen
          title={'Partner Reference Code'}
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanTabsContent={<ScanTabsContent />}
          validationMessage={errorMessage}
        />
      ),
    },
  ];

  return (
    <div className="component-hardware-register-scan-step">
      <div className="wrapper">
        {screen === 'start' && (
          <div className="tw-w-[450px] tw-h-[310px] tw-mx-auto tw-my-0">
            <div
              className={`tw-w-[450px] tw-h-[310px] tw-border-primary tw-bg-white tw-rounded-2xl tw-flex tw-items-end tw-justify-center`}
            >
              <TransferIllustration />
            </div>
            <Text variant="bodyLg" className="tw-text-left tw-mt-3">
              Scan the Partner Reference Code of the partner that you will be transferring the hardware to.
            </Text>
            <Text variant="bodyLg" className="tw-text-left tw-mt-3">
              <b>Partner Reference Code</b> can be retrieved from the Partner Information of your receiving partner.
            </Text>
          </div>
        )}
        {screen === 'scanned' && partner && (
          <div className="tw-text-center">
            <Text variant="bodyLg" className="tw-text-grey-700 tw-mb-6">
              1 Partner Scanned:
            </Text>
            <div className="tw-max-w-[230px] tw-rounded-lg tw-bg-white tw-p-3 tw-flex tw-flex-col tw-my-0 tw-mx-auto">
              <div className="tw-w-full tw-aspect-square tw-rounded-lg tw-grid tw-place-items-center tw-relative tw-bg-grey-500">
                <Avatar type="partner" id={partner.id} className="tw-bg-grey-400 tw-w-full" />
              </div>
              <Text variant="h4" className="tw-text-primary-800 tw-my-3 tw-flex tw-justify-start">
                {partner.displayName}
              </Text>
              <CommonStatus status="active" />
            </div>
          </div>
        )}
        {screen === 'scan' && <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} />}
      </div>
      <div className="hardware-footer-section">
        <div>
          {screen === 'start' && <PrimaryButton label="Start Scanning" onClick={() => setScreen('scan')} />}
          {screen === 'scan' && (
            <>
              <PrimaryButton label={'Continue'} onClick={() => setCurrentStep(2)} disabled={!partner} />
            </>
          )}
          {screen === 'scanned' && (
            <>
              <SecondaryButton
                label={'Rescan'}
                onClick={doReEnter}
                disabled={!partner}
                icon={<Icon name="icon-refresh" size={24} />}
              />
              <PrimaryButton label={'Continue'} onClick={() => setCurrentStep(2)} disabled={!partner} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransferToScreen;
